import {MessageBoxPosition, MessageBoxStatus, UserMessageTypes} from "./types";

export function createMessageBoxFromMessageTypeUserMessage(message, type) {
  return {
    position: MessageBoxPosition.Left,
    type: type,
    text: message.text,
    image_data: message.image_data,
    title: message.sender_name,
    status: MessageBoxStatus.Waiting,
    date: new Date(),
    data: {
      dialog_id: message.sender,
      message_id: String(message.random_id),
      out: false,
    },
    reply_to: message.reply_to
  }
}

export function createMessageBoxFromMessageTypeTextMessage(message) {
  return createMessageBoxFromMessageTypeUserMessage(message, UserMessageTypes.Text)
}

export function createMessageBoxFromMessageTypeCallMessage(message) {
  return createMessageBoxFromMessageTypeUserMessage(message, UserMessageTypes.Call)
}

export function createMessageBoxFromMessageTypeReplyMessage(message) {
  return createMessageBoxFromMessageTypeUserMessage(message, UserMessageTypes.Reply)
}

export function createMessageBoxFromOutgoingMessage(message, user_pk, self_pk, self_name, random_id) {
  const {type, text, reply_to} = message;
  return {
    position: MessageBoxPosition.Right,
    type,
    text,
    title: self_name,
    status: MessageBoxStatus.Waiting,
    date: new Date(),
    data: {
      dialog_id: user_pk,
      message_id: random_id,
      out: true,
    },
    reply_to
  }
}

export function createNewDialogByDialogIdAndTitle(dialogId, dialogTitle, dialogImageData) {
  return {
    id: dialogId,
    alt: dialogTitle,
    title: dialogTitle,
    image_data: dialogImageData,
    date: new Date(),
    lastMessage: null,
    subtitle: "",
    unread: 0,
  }
}

export function createNewDialogModelFromIncomingMessageBox(message) {
  return {
    id: message.data.dialog_id,
    alt: message.title,
    title: message.title,
    image_data: message.image_data,
    date: message.date,
    lastMessage: message,
    subtitle: message.text,
    unread: 1,
  }
}
