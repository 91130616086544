import * as React from 'react';
import Menu from '@mui/material/Menu';
import {Box} from "@mui/material";
import {useState} from "react";

export default function BasicMenu(
  {
    title,
    id = "my-basic-menu",
    maxHeight,
    width,
    onScrollToBottom = null,
    children
  }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id={`${id}-button`}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div style={{width: 'auto', height: 'auto'}}>
          {title}
        </div>
      </div>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
        }}
        PaperProps={{
          sx: {
            width,
            overflowY: 'auto',
          },
          onScroll: e => {
            const {target} = e;
            const {scrollTop, scrollHeight, clientHeight} = target;
            if (scrollTop + clientHeight >= scrollHeight) {
              onScrollToBottom && onScrollToBottom(e)
            }
          }
        }}
        sx={{maxHeight}}
      >{children.map((child, index) => {
        return <Box key={`basic-menu-item-${index}`} onClick={handleClose}>
          {child}
        </Box>
      })}</Menu>
    </div>
  );
}