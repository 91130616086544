import React from 'react';
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

export default function AsyncAutocomplete(
  {
    onSearch,
    onChange,
    value,
    label,
    style = {},
    className = '',
    errorMessage = '',
    selectedOption = null,
    size = 'medium',
    sx = {},
    fireFullOption = false,
  }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState(selectedOption || null);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions(selectedValue ? [{...selectedValue}] : []);
    } else {
      if (!options.length) {
        handleSearch('')
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (!!selectedOption) {
      const {label, value} = selectedOption
      if (!selectedValue || selectedValue.value !== value) {
        setSelectedValue({label, value})
      }
    } else {
      setSelectedValue(null)
    }
  }, [selectedOption])

  const handleSearch = async (value) => {
    setLoading(true)
    const newOptions = await onSearch(value);
    setOptions([...newOptions]);
    setLoading(false)
  }

  let delayedSearch = null;
  const handleChange = (event) => {
    if (delayedSearch) {
      clearTimeout(delayedSearch)
      delayedSearch = null;
    }
    delayedSearch = setTimeout(() => handleSearch(event.target.value), 400)
  }

  return (
    <Autocomplete
      className={className}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_, newValue) => {
        if (fireFullOption) {
          if (newValue) {
            onChange({label: newValue.label, value: newValue.code})
          } else {
            onChange(null)
          }
        } else {
          onChange(newValue ? newValue.code : null)
        }
        setSelectedValue(newValue)
      }}
      value={selectedValue}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          size={size}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.code}>
            {option.label}
          </li>
        );
      }}
      style={style}
      sx={{width: '100%', ...sx}}
    />
  );
}
