import React from "react";
import {Container, Grid} from "@mui/material";

/* REACT BOOTSTRAP */
function FormContainer({ children, ...rest }) {
  return (
    <Container {...rest}>
      <Grid container justifyContent={{md: 'center'}}>
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
}

export default FormContainer;
