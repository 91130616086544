import {Box, Divider} from "@mui/material";
import UserInfo from "components/user-info";
import i18n from "@core/configs/i18n";
import {useAuth} from "../../../hooks/useAuth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {valueOrNA} from "../../../@core/utils";


export default function AccountInformation() {
  const auth = useAuth();
  const navigate = useNavigate();
  return <Box className='account-information-page'>
    <UserInfo
      avatar={null}
      title={i18n.t("Change email")}
      subtitle={auth.user.email}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('email')}
      disableComponentClick={false}
    />
    <UserInfo
      avatar={null}
      title={i18n.t("Change phone number")}
      subtitle={auth.user.phone_number}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('phone')}
      disableComponentClick={false}
    />

    <Divider />
    <UserInfo
      avatar={null}
      title={i18n.t("Account creation")}
      subtitle={moment(auth.user.date_joined).format('LLL')}
      subtitleColor='rgb(83, 100, 113)'
      disableComponentClick={true}
      disableHover={true}
      disableClick={true}
    />
    <Divider />

    <UserInfo
      avatar={null}
      title={i18n.t("Country")}
      subtitle={valueOrNA(auth.user.country_data?.name)}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      disableComponentClick={false}
      onClick={() => navigate('country')}
    />

    <UserInfo
      avatar={null}
      title={i18n.t("Language")}
      subtitle={valueOrNA(auth.user.language)}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      disableComponentClick={false}
      onClick={() => navigate('language')}
    />

  </Box>
}