import {Box} from "@mui/material";
import {memo, useEffect} from "react";
import ChatBox from "../chat-box";
import {useChat} from "../../../hooks/useChat";
import eventBus from "@core/event-bus";
import {chatHeaderIsSame, dialogMessagesAreSame, groupMessages} from "../utils";
import {MessageTypes} from "../../../screens/chat/types";

const SingleBox = memo(({chat, dialog}) => {
  console.log("refreshing dialog: ", dialog.title)
  // let messages = chat.getDialogMessages(dialog)
  return <Box className='chat-box-wrapper chat-box-width' key={dialog.id}>
    <Box className='chat-box-parent'>
      <Box className='chat-box chat-box-width' key={dialog.id}>
        <ChatBox
          dialog={dialog}
          title={dialog.title}
          messages={groupMessages(dialog.messages)}
          ungroupedMessages={dialog.messages}
          onClose={() => {
            eventBus.dispatch('close-dialog', dialog)
          }}
          onScrollToTop={() => chat.getDialogMessages(dialog)}
        />
      </Box>
    </Box>
  </Box>
}, (prevProps, nextProps) => {
  const oldDialog = prevProps.dialog;
  const newDialog = nextProps.dialog;
  return chatHeaderIsSame(oldDialog, newDialog) && dialogMessagesAreSame(oldDialog, newDialog)
});

export default function Boxes({onCall}) {
  const chat = useChat()

  useEffect(() => {
    eventBus.on('open-user', (user) => {
      chat.openDialog(user.id, user.name, user.image_data)
    })
    eventBus.on('open-dialog', (dialog) => {
        chat.openDialog(dialog.id, dialog.title, dialog.image_data)
      }
    )
    eventBus.on('close-dialog', (dialog) => chat.closeDialog(dialog.id))
    eventBus.on('send-message', ({dialog, message, reply_to, type}) => {
      chat.sendMessage(dialog, message, reply_to, type)
      if (type === MessageTypes.CallMessage) {
        onCall(dialog)
      }
    })
    eventBus.on('start-typing', ({dialog}) => chat.startTyping(dialog))
    eventBus.on('stop-typing', ({dialog}) => chat.stopTyping(dialog))
    eventBus.on('delete-message', ({dialog_id, message_id}) => chat.deleteMessage(message_id, dialog_id))
    eventBus.on('message-reaction', ({dialog_id, message_id, emoji_id}) => chat.messageReaction(message_id, dialog_id, emoji_id))
    eventBus.on('message-reaction-remove', ({dialog_id, message_id}) => chat.messageReactionRemove(message_id, dialog_id))

    return () => {
      eventBus.removeAll('open-user')
      eventBus.removeAll('open-dialog')
      eventBus.removeAll('close-dialog')
      eventBus.removeAll('send-message')
      eventBus.removeAll('start-typing')
      eventBus.removeAll('stop-typing')
      eventBus.removeAll('delete-message')
      eventBus.removeAll('message-reaction')
      eventBus.removeAll('message-reaction-remove')
    }
  }, [chat])

  // noinspection JSValidateTypes
  return <>
    <Box className='chat-boxes'>
      <Box sx={{display: 'flex'}}>
        {chat.openedDialogs.map(dialog => <SingleBox key={dialog.id} dialog={dialog} chat={chat}/>)}
      </Box>
    </Box>
  </>
}