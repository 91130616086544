import Carousel from "react-material-ui-carousel";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {useImages} from "../../hooks/useImages";


// noinspection HtmlRequiredAltAttribute
export default function ImagesCarousel(
  {
    images,
    activeIndex = 0,
    getImageFn = (item) => item,
    getOriginalImageFn = null,
    height = '100%',
    width = '100%',
    maxHeight = null,
    maxWidth = null,
    showIndicators = images.length > 1,
    onImageClick = null,
    openImageOnClick = true,
    heightClass = '',
  }
) {
  const [currentIndex, setCurrentIndex] = React.useState(activeIndex)
  const imagesHook = useImages();

  useEffect(() => {
    if (activeIndex !== currentIndex) {
      setCurrentIndex(activeIndex)
    }
  }, [activeIndex])

  const imageClickHandler = (index, e) => {
    if (onImageClick) {
      onImageClick(index, e)
    }
    if (openImageOnClick) {
      imagesHook.openImages(images, index, getOriginalImageFn || getImageFn)
    }
  }

  return <>
    <Carousel
      onChange={(index) => setCurrentIndex(index)}
      changeOnFirstRender={false}
      navButtonsAlwaysInvisible={images.length < 2}
      indicators={showIndicators}
      index={activeIndex}
      fullHeightHover={true}
      autoPlay={false}
      animation='slide'
      className='image-carousel-component'
      swipe={true}
    >
      {images.map((item, i) => {
        return <Box
          key={`images-carousel-image-${i}`}
          className={heightClass}
          sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          {currentIndex === i && (
            <img
              src={getImageFn(item)}
              className={heightClass}
              style={{width, height, maxHeight, maxWidth, cursor: 'pointer', zIndex: '100'}}
              alt='carousel-image'
              onClick={e => {
                imageClickHandler(i, e)
              }}
            />
          )}
        </Box>
      })}
    </Carousel>
  </>
}