import {Box, CircularProgress} from "@mui/material";
import React from "react";


export default function ComponentOverlappingLoader() {
  return <>
    <Box sx={{position: 'absolute', width: '100%', height: '100%', background: 'rgba(255, 255, 255, .7)', zIndex: 1}}>
      <CircularProgress
        color="inherit"
        size='5rem'
        sx={{position: 'absolute', top: 'calc(50% - 2.5rem)', left: 'calc(50% - 2.5rem)'}}
      />
    </Box>
  </>
}