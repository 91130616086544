import {Box, IconButton} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../Loader";


export default function VideoUI({gotSecondUser, video1Ref, video2Ref, toggleMic, toggleCamera, endCall, audioEnabled, videoEnabled}) {
    return <>
        <Box className='video-p2p'>
            <video className={`video-p2p-video-player ${gotSecondUser ? 'video-p2p-small-frame' : ''}`}
                   id="video-p2p-user-1"
                   autoPlay
                   playsInline
                   muted='muted'
                   ref={video1Ref}
            ></video>
            <video className="video-p2p-video-player"
                   id="video-p2p-user-2"
                   autoPlay
                   playsInline
                   ref={video2Ref}
                   style={{display: gotSecondUser ? 'block' : 'none'}}
            ></video>

            <Box className='video-p2p-controls'>
                <Box className="video-p2p-controls-container" onClick={toggleMic}>
                    <IconButton>
                        {audioEnabled ? <MicIcon fontSize='large'/> : <MicOffIcon fontSize='large'/>}
                    </IconButton>
                </Box>
                <Box className="video-p2p-controls-container" onClick={toggleCamera}>
                    <IconButton>
                        {videoEnabled ? <LocalSeeIcon fontSize='large'/> : <NoPhotographyIcon fontSize='large'/>}
                    </IconButton>
                </Box>
                <Box className="video-p2p-controls-container" onClick={() => endCall()}>
                    <IconButton>
                        <ClearIcon fontSize='large'/>
                    </IconButton>
                </Box>
            </Box>

            {!gotSecondUser && (
                <Box className='video-p2p-loading'>
                    <Loader/>
                </Box>
            )}
        </Box>
    </>
}