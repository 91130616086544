// ** React Imports
import {createContext, useEffect, useState} from 'react'
import userService from "../screens/user/User.service";


function getDefaultUser() {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const userData = localStorage.getItem('USER_DATA')
  if (accessToken && userData) {
    return {
      access_token: accessToken,
      ...JSON.parse(userData),
    }
  }
  return null
}

// ** Defaults
const defaultProvider = {
  user: getDefaultUser(),
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUserInfo: () => Promise.resolve(),
  setUserInfo: (userData) => null,
  setLoggedInUserInfo: (data) => null,
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({children}) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)

  useEffect(() => {
    const initAuth = async () => {
      const storedToken = localStorage.getItem('ACCESS_TOKEN')
      if (storedToken) {
        setLoading(true)
        const {ok} = await updateUserInfo()
        setLoading(false)
        if (!ok) {
          removeUserData()
        }
      } else {
        removeUserData()
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    initAuth()
  }, [])

  const removeUserData = () => {
    localStorage.removeItem('USER_DATA')
    localStorage.removeItem('ACCESS_TOKEN')
  }

  const setUserInfo = (userData) => {
    if (userData.access_token && !localStorage.getItem('ACCESS_TOKEN')) {
      localStorage.setItem('ACCESS_TOKEN', userData.access_token)
    }

    if (!userData.access_token) {
      const access_token = localStorage.getItem('ACCESS_TOKEN')
      userData = {...userData, access_token}
    }

    setUser({...userData, initialized: true})
    localStorage.setItem('USER_DATA', JSON.stringify(userData))
  }

  const updateUserInfo = async () => {
    const {ok, result, errors} = await userService.getMe()
    if (ok) {
      setUserInfo(result.user_data)
    }
    return {ok, result, errors}
  }

  const handleSetLoggedInUserData = ({user_data, access_token}) => {
    setUserInfo({...user_data, access_token: access_token})
    localStorage.setItem('ACCESS_TOKEN', access_token)
  }

  // noinspection ES6MissingAwait
  const handleLogin = async (email, password) => {
    const {ok, result, errors} = await userService.login(email, password)
    if (ok) {
      handleSetLoggedInUserData(result)
    }
    return {ok, result, errors}
  }

  const handleLogout = () => {
    removeUserData()
    setUser(null)
  }

  const handleRegister = async (params) => {
    const {ok, result, errors} = await userService.register(params)
    if (ok) {
      const {access_token, user_data} = result
      return {
        ok,
        result: {
          ...user_data,
          access_token,
        }
      }
    }
    return {ok, result, errors}
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    updateUserInfo,
    setUserInfo,
    setLoggedInUserInfo: handleSetLoggedInUserData,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export {AuthContext, AuthProvider}
