import {request} from "../../axios";
import {DEFAULT_PAGE_SIZE} from "../../constants";

const service = {
  async getMe() {
    return await request.get('user/me/');
  },
  async login(email, password) {
    return await request.post('user/login/', {email, password});
  },
  async register(payload) {
    return await request.post(`user/register/`, payload)
  },
  async createProduct(product) {
    return await request.post(`user/products/`, product)
  },
  async updateProduct(product) {
    const id = product instanceof FormData ? product.get('id') : product.id;
    return await request.put(`user/products/${id}/`, product)
  },
  async getProduct(id) {
    return await request.get(`user/products/${id}/`)
  },
  async deleteProduct(id) {
    return await request.delete(`user/products/${id}/`)
  },
  async getProducts(page, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get('user/products/', {params: {page, page_size}});
  },
  async getUserInfo(userId) {
    return await request.get(`user/${userId}/`);
  },
  async createPost(content, uploadedImageIds = []) {
    return await request.post('user/posts/', {content, image_ids: uploadedImageIds});
  },
  async getFeed(id, cursor = null, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(
      `user/${id}/feed/`,
      {
        params: {
          cursor,
          page_size,
        }
      }
    );
  },
  async getAuthorFeed(id, cursor = null, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(
      `user/${id}/author-feed/`,
      {
        params: {
          cursor,
          page_size,
        }
      }
    );
  },
  async updatePost(id, content, uploadedImageIds) {
    return await request.put(`user/posts/${id}/`, {content, image_ids: uploadedImageIds});
  },
  async deletePost(id) {
    return await request.delete(`user/posts/${id}/`);
  },
  async followUser(id) {
    return await request.post(`user/follow/${id}/`);
  },
  async unfollowUser(id) {
    return await request.post(`user/unfollow/${id}/`);
  },
  async updateProfile(data) {
    return await request.put(`user/profile/`, data);
  },
  async updateCredentials(data) {
    return await request.put(`user/credentials/`, data);
  },
  async getFollowing(id, cursor = null, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`user/${id}/following/`, {params: {cursor, page_size}});
  },
  async getFollowers(id, cursor = null, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`user/${id}/followers/`, {params: {cursor, page_size}});
  },
  async getRecommendedPeople(id, page_size = 5) {
    return await request.get(`user/${id}/recommended/`, {params: {page_size}});
  },
  async searchUsers(query, page_size = 5) {
    return await request.get(`user/`, {params: {query, page_size}});
  },
  async uploadDocuments(data) {
    return await request.post(`user/documents/`, data);
  },
  async deactivateAccount() {
    return await request.post(`user/deactivate/`);
  },
  async activateAccount(email, password) {
    return await request.post(`user/activate/`, {email, password});
  },
  async getLoginHistory(page, perPage = DEFAULT_PAGE_SIZE) {
    return await request.get(`user/login-history/`, {params: {page, page_size: perPage}});
  },
  async logoutOfOtherSessions() {
    return await request.post(`user/logout-other-sessions/`);
  },
  async getRegistrationCertificates() {
    return await request.get(`user/documents/`);
  },
  async downloadDocument(id) {
    return await request.get(`user/documents/${id}/download/`, {responseType: 'blob'});
  },
  async getDocumentPublicUrl(id) {
    return await request.get(`user/documents/${id}/get-public-url/`);
  },
  async sendPasswordResetLink(email) {
    return await request.post(`user/password-reset-link/`, {email});
  },
  async checkPasswordResetLinkExpiration(token) {
    return await request.post(`user/password-reset-link-check/`, {token});
  },
  async resetPassword(token, password) {
    return await request.post(`user/password-reset/`, {token, password});
  },
  async getLatestFeedItem(userId) {
    return await request.get(`user/${userId}/feed/latest/`);
  }
}

export default service