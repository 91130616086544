import {useState} from "react";
import {IconButton, MenuItem, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";

export default function OptionsMenu({uniqueId, options, sx = {}}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return <>
    <IconButton
      aria-controls={menuOpen ? uniqueId : undefined}
      aria-expanded={menuOpen ? 'true' : undefined}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <MoreVertIcon/>
    </IconButton>
    <Menu
      id={uniqueId}
      MenuListProps={{'aria-labelledby': 'long-button',}}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={sx}
    >
      {options.map((option, index) => {
        return <MenuItem
          key={`menu-${uniqueId}-option-${index}`}
          onClick={e => {
            option.onClick && option.onClick(e);
            handleClose();
          }}
        >
          {option.icon && option.icon}
          <Typography variant='subtitle2' sx={option.sx || {}}>
            {option.label}
          </Typography>
        </MenuItem>
      })}
    </Menu>
  </>
}
