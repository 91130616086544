import AuthLayout from "layouts/AuthLayout";
import i18n from "@core/configs/i18n";
import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, IconButton, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {getFirstErrorString, getInputChangeHandler, setFormErrors, showMessage} from "@core/utils";
import userService from "../../user/User.service";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function PasswordReset() {
  const params = useParams()
  const [loading, setLoading] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [error, setError] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [formInput, setFormInput] = useState({
    password: {value: "", error: ""},
    confirm_password: {value: "", error: ""},
  });

  const navigate = useNavigate();

  useEffect(() => {
    const {token} = params
    if (token) {
      setLoading(true)
      userService.checkPasswordResetLinkExpiration(token).then(({ok, result, errors}) => {
        if (ok) {
          setShowResetPasswordForm(true)
        } else {
          setError(getFirstErrorString(errors))
        }
        setLoading(false)
      })
    } else {
      showMessage(false, i18n.t("Invalid link"))
    }
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();

    if (formInput.password.value !== formInput.confirm_password.value) {
      setFormErrors({confirm_password: i18n.t("Passwords do not match")}, setFormInput)
      return;
    }

    setResettingPassword(true)
    const {ok, result, errors} = await userService.resetPassword(params.token, formInput.password.value)
    if (ok) {
      showMessage(true, i18n.t("Password reset successfully"))
      navigate("/login")
    } else {
      setFormErrors(errors, setFormInput)
    }
    setResettingPassword(false)
  }

  return <>
    <AuthLayout>
      <Paper sx={{maxWidth: {xs: '100%', md: '400px'}, my: {xs: 0, md: '25px'}}}>
        <Box sx={{px: 4, py: 3}}>
          <Typography variant='h5' sx={{mb: 2}}>
            {i18n.t("Password reset form")}
          </Typography>
          {loading && (
            <Box sx={{mt: 3, textAlign: 'center'}}>
              <CircularProgress color="primary" size='3.5rem'/>
            </Box>
          )}
          {error && (
            <Typography color='error' sx={{mt: 3}}>
              {error}
            </Typography>
          )}
          {showResetPasswordForm && (
            <form onSubmit={submitHandler}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                error={Boolean(formInput.password.error)}
                label={i18n.t("New Password")}
                value={formInput.password.value}
                helperText={formInput.password.error}
                onChange={getInputChangeHandler("password", setFormInput)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {!showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                tabIndex={1}
                fullWidth
                sx={{mb: 2, bg: 'white'}}
              />

              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                error={!!formInput.confirm_password.error}
                label={i18n.t("Confirm Password")}
                value={formInput.confirm_password.value}
                helperText={formInput.confirm_password.error}
                onChange={getInputChangeHandler("confirm_password", setFormInput)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {!showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                tabIndex={2}
                fullWidth
                sx={{mb: 2, bg: 'white'}}
              />

              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                disabled={resettingPassword}
                sx={{textTransform: 'none'}}
              >
                {resettingPassword && <CircularProgress color="secondary" size='1.5rem' sx={{mr: 2}}/>}
                {i18n.t("Reset Password")}
              </Button>
            </form>
          )}
        </Box>
      </Paper>
    </AuthLayout>
  </>
}