import {Typography} from "@mui/material";

const CURRENCY_SYMBOL_BY_CODE = {
  USD: '$',
  EUR: '€',
  GEL: '₾',
}

function getCurrencyCode(currencyCode) {
  return CURRENCY_SYMBOL_BY_CODE[currencyCode] || currencyCode
}

export default function Index({code, fontSize = '1rem', sx}) {
  return <Typography
    variant='span'
    sx={{
      textAlign: 'center',
      fontSize,
      fontWeight: 'bolder',
      color: 'rgba(0, 0, 0, .7)',
      ml: 1,
      ...sx,
    }}
  >
    {getCurrencyCode(code)}
  </Typography>
}