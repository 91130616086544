import React, {useEffect, useState} from "react";

import "./Product.css"

import {Box, Container, Divider, Grid, Paper, Typography, useTheme} from "@mui/material";
import i18n from "@core/configs/i18n";
import Loader from "components/Loader";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  getDateDiffString,
  getFirstErrorString,
  getImagesOrDefaultImages,
  getThumbnailOrOriginalImage,
  getUserThumbnailOriginalOrDefaultImage,
  showMessage,
  valueOrNA
} from "@core/utils";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import productService from "./Product.service";
import eventBus from "@core/event-bus";
import ImagesCarousel from "components/images-carousel";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Badge from "components/badge";
import Currency from "components/currency";

export default function ProductScreen() {
  const navigate = useNavigate()
  const {id} = useParams();
  const auth = useAuth();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [productImages, setProductImages] = useState([]);

  useEffect(() => {
    getProductDetails(id);
  }, [id]);

  useEffect(() => {
    if (product) {
      setProductImages(getImagesOrDefaultImages(product.images))
    }
  }, [product])

  async function getProductDetails(id) {
    setLoading(true);
    const {ok, result, errors} = await productService.getProduct(id);
    if (ok) {
      setProduct(result)
    } else {
      showMessage(false, getFirstErrorString(errors))
    }
    setLoading(false);
  }

  const showUserPage = () => {
    // TODO improve logic here
    if (auth.user) {
      navigate(`/user/${product.user_data.id}`)
    }
  }

  const showOwnerProducts = () => {
    navigate(`/user/${product.user_data.id}/products`)
  }

  return <>
    {([null, undefined, true].includes(loading)) ? (
      <Loader mt='5rem'/>
    ) : (
      <Container sx={{mt: 4}}>
        {!product ? (<Typography variant='h6' sx={{textAlign: 'center'}}>
          {i18n.t("No product data to show")}
        </Typography>) : (<Grid container justifyContent='center'>
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <Box>
              <Box className='product-title' display={'flex'}>
                <Typography variant="h5" sx={{fontWeight: 'bolder'}}>{product.name}</Typography>
                <Typography
                  variant="h5"
                  color='secondary'
                  sx={{mt: 0, mx: 1, fontWeight: 'bolder'}}
                >
                  {product.year}
                </Typography>
              </Box>
              <Box className='under-product-title'>
                <Box display={'flex'} mt={.5} sx={{color: '#585757', fontSize: '.9rem'}}>
                  <Typography
                    textTransform='capitalize'
                    fontSize='.9rem'
                    sx={{mt: .5, color: theme.palette.error.main}}
                  >
                    {valueOrNA(product.location_data?.name)}
                  </Typography>
                  <Box sx={{mx: 3, mt: .5, display: 'flex'}}>
                    <VisibilityIcon fontSize='small'/>
                    <Typography
                      variant="inherit"
                      sx={{mx: 1}}
                    >
                      {product.seen_count}
                    </Typography>
                    <Typography
                      variant="inherit"
                      sx={{mx: 1}}
                    >
                      {getDateDiffString(product.created_at)}
                    </Typography>
                  </Box>
                  <Box sx={{mt: .5, mx: 0}}>
                    <Typography variant='caption' className='site-main-color' sx={{fontWeight: 'bolder'}}>
                      {i18n.t('ID')}: {product.announcement_code}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className='product-image' sx={{my: 2}}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={10} sx={{maxHeight: '500px'}}>
                        <ImagesCarousel
                          images={productImages}
                          activeIndex={activeImageIndex}
                          getImageFn={(item) => item.data.original}
                          showIndicators={false}
                          height={'500px'}
                          width='auto'
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} sx={{maxHeight: '500px', overflowY: 'scroll'}}>
                        <Box>
                          {product.images.map((item, index) => {
                            return <Box key={'image-' + index} sx={{pb: .5}}>
                              <img
                                className='product-secondary-image'
                                src={getThumbnailOrOriginalImage(item)}
                                alt={'product-image'}
                                width={'100%'}
                                onClick={() => setActiveImageIndex(index)}
                                style={{borderRadius: ".5rem"}}
                              />
                            </Box>
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className='description' sx={{my: 3}}>
                      <Paper sx={{borderRadius: ".8rem"}}>
                        <Box sx={{p: 2, px: 3}}>
                          <Typography variant='body1' sx={{fontWeight: 500}}>
                            {i18n.t("Detailed description")}
                          </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{p: 2, px: 3}}>
                          <Typography variant='subtitle2' sx={{color: '#625f5f', fontFamily: 'sans-serif'}}>
                            {product.description || i18n.t("No description")}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                    <Box className='product-codes' sx={{my: 3}}>
                      <Paper sx={{borderRadius: ".8rem"}}>
                        <Box sx={{p: 2, px: 3}}>
                          <Typography variant='body1' sx={{fontWeight: 500}}>
                            {i18n.t("Product codes")}
                          </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{p: 2, px: 3}}>
                          {product.product_codes_data.map((item, index) => (
                            <Box key={item.code} sx={{mb: 1, display: 'flex'}}>
                              <Typography variant='caption' color='secondary' sx={{fontSize: '.8rem'}}>
                                <b> {item.code} </b> {" - " + i18n.t(item.name)}
                              </Typography>
                            </Box>
                          ))}
                          {product.product_codes_data.length === 0 && (
                            <Typography variant='subtitle2' sx={{color: '#625f5f', fontFamily: 'sans-serif'}}>
                              {i18n.t("No product code")}
                            </Typography>
                          )}
                        </Box>
                      </Paper>
                    </Box>

                    <Box className='detailed-description' sx={{my: 3}}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={9}>
                          <Paper sx={{borderRadius: '.8rem'}}>
                            <Box sx={{p: 2, px: 3}}>
                              <Typography variant='body1' sx={{fontWeight: 500}}>
                                {i18n.t("Main Specifications")}
                              </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={{py: 2, px: 0}}>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Name")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {product.name}
                                </Typography>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Buyer")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.buyer_data?.name)}
                                </Typography>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Provider")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.provider_data?.name)}
                                </Typography>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Carrier")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.transiter_data?.name)}
                                </Typography>
                              </Box>

                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Category")}
                                </Typography>
                                <Box className='details-value'>
                                  {product.category ? (
                                    <Badge> {product.category} </Badge>
                                  ) : (
                                    valueOrNA(null)
                                  )}
                                </Box>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Year")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.year)}
                                </Typography>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Location")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.location_data?.name)}
                                </Typography>
                              </Box>
                              <Box className='details-pair'>
                                <Typography variant='body2' className='details-key'>
                                  {i18n.t("Address")}
                                </Typography>
                                <Typography variant='body2' className='details-value'>
                                  {valueOrNA(product.address)}
                                </Typography>
                              </Box>
                              <Grid container>
                                <Grid item sm={5}
                                      sx={{
                                        color: "#6f7383!important", "& > *": {
                                          my: 2, px: 3, borderLeft: "2px solid transparent"
                                        }, "& > *:hover": {
                                          cursor: "pointer", borderLeft: "2px solid red", color: "red",
                                        }
                                      }}>
                                </Grid>
                                <Grid item sm={7} sx={{color: "black", "& > *": {my: 2}}}>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box>
                      <Paper sx={{borderRadius: '.8rem'}}>
                        <Box sx={{p: 2, px: 3}}>
                          {product.customs_clearance !== null && (
                            product.customs_clearance ? (<Typography variant={'caption'} color='green'>
                              {i18n.t("Customs cleared")}
                            </Typography>) : (<Typography variant={'caption'} color='red'>
                              {i18n.t("Customs not cleared")}
                            </Typography>)
                          )}
                          <Typography variant='h5' sx={{my: 2, fontWeight: 'bolder'}}>
                            {product.price}
                            <Currency code={product.currency_code} fontSize='1.5rem'/>
                          </Typography>
                        </Box>
                        <Divider variant='fullWidth' sx={{background: 'grey'}}/>
                        <Box p={3}>
                          <Box display={'flex'}
                               sx={{cursor: 'pointer'}}>
                            <Box mt={1} onClick={showUserPage}>
                              <img src={getUserThumbnailOriginalOrDefaultImage(product.user_data, 'image_data')} width='30px'
                                   alt='user-image'/>
                            </Box>
                            <Box mx={2} sx={{lineHeight: 0}}>
                              <Typography
                                variant='body2'
                                onClick={showUserPage}
                                sx={{":hover": {textDecoration: "underline"}}}
                              >
                                {product.user_data.name}
                              </Typography>
                              <Typography
                                variant={'caption'}
                                className='text-muted'
                                onClick={showOwnerProducts}
                                sx={{":hover": {textDecoration: "underline"}}}>
                                {i18n.t("advertisements")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            onClick={e => {
                              eventBus.dispatch("open-user", product.user_data)
                            }}
                            sx={{
                              my: '1rem',
                              mt: '1.5rem',
                              p: '.75rem',
                              border: '1px solid',
                              borderRadius: '.5rem',
                              borderColor: theme.palette.primary.main,
                              color: theme.palette.primary.main,
                              cursor: 'pointer',
                              textAlign: {md: 'left', xs: 'center'}
                            }}>
                            <ChatBubbleOutlineIcon fontSize='small' sx={{mx: 1}}/>
                            <Typography variant='caption' sx={{textTransform: 'uppercase'}}>
                              {i18n.t("Write a message")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              p: '.75rem',
                              border: '1px solid',
                              borderRadius: '.5rem',
                              borderColor: theme.palette.info.main,
                              color: theme.palette.info.main,
                              cursor: 'pointer',
                              textAlign: {md: 'left', xs: 'center'}
                            }}>
                            <PhoneIphoneIcon fontSize='small' sx={{mx: 1}}/>
                            <Typography variant='caption' fontSize='.85rem' textTransform='lowercase'>
                              {product.user_data.phone_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>)}
      </Container>)}
  </>
}