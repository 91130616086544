import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import i18n from "@core/configs/i18n";
import React, {useEffect} from "react";


export default function Modal(
  {
    open,
    title,
    onSubmit,
    onClose,
    loading,
    children,
    noPadding = false,
    showDialogActions = true,
    removeBackgroundAndShadow = false,
  }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const listener = e => (e.key === 'Escape') && onClose()
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener);
    }
  }, [])

  return <>
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      PaperComponent={removeBackgroundAndShadow ? Box : Paper}
      PaperProps={{sx: {width: "100%"}}}
    >
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{padding: noPadding ? 0 : null}}>
        {children}
      </DialogContent>
      {showDialogActions && (
        <DialogActions sx={{mx: 2}}>
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={loading}
            autoFocus>
            {loading && <CircularProgress color="inherit" size={20} sx={{mr: 2}}/>}
            {i18n.t("Submit")}
          </Button>
          <Button onClick={onClose}
                  variant='contained'
                  color='error'
                  disabled={loading}
                  autoFocus>
            {i18n.t("Cancel")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  </>
}