import {Box, Grid, Typography} from "@mui/material";
import {SITE_TITLE} from "../config/main";
import React from "react";


export default function AuthLayout({children}) {
  return <>
    <Box sx={{mx: 5, my: {xs: 5, md: '150px'}}}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{width: '100%'}}>
          <Box sx={{textAlign: 'center', mt: {xs: 0, md: 5}, my: 2}}>
            <Typography variant='h3' className='site-main-color'>
              {SITE_TITLE}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  </>
}