import {Box, ClickAwayListener, IconButton, Tooltip, Typography} from "@mui/material";
import {DEFAULT_AVATAR_URL} from "constants.js";
import {
  isTextMessage,
  isCallMessage,
  isReplyMessage,
  groupMessages,
  groupedMessagesAreSame,
  chatMessagesAreSame, chatMessageIsSame
} from "../utils";
import "./index.css"
import CallIcon from "@mui/icons-material/Call";
import {MessageBoxStatus, UserMessageTypes} from "screens/chat/types";
import i18n from "@core/configs/i18n";
import {Link} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import eventBus from "@core/event-bus";
import {getMessageSubstring} from "@core/utils";
import {memo, useState} from "react";

const defaultOptions = {
  position: 'left',
  avatarUrl: DEFAULT_AVATAR_URL,
  messageBorderClasses: {
    left: {
      start: "chat-message-body-left-start",
      middle: "chat-message-body-left-middle",
      end: "chat-message-body-left-end",
    },
    right: {
      start: "chat-message-body-right-start",
      middle: "chat-message-body-right-middle",
      end: "chat-message-body-right-end",
    }
  },
  messageBackground: {
    left: "chat-message-content-left-background",
    right: "chat-message-content-right-background",
  }
}

function getBorderClasses(position, isFirstMessage, isLastMessage) {
  const classes = []
  if (isFirstMessage) {
    classes.push(defaultOptions.messageBorderClasses[position].start)
  } else if (isLastMessage) {
    classes.push(defaultOptions.messageBorderClasses[position].end)
  }
  if (!isFirstMessage && !isLastMessage) {
    classes.push(defaultOptions.messageBorderClasses[position].middle)
  }
  return classes.join(' ')
}

function getChatMessageTypeClass(type) {
  const classesByType = {
    [UserMessageTypes.Text]: "chat-message-text",
    [UserMessageTypes.Call]: "chat-message-call",
  }
  if (classesByType[type]) {
    return classesByType[type]
  }
  return "";
}

function renderTextMessage(text) {
  const words = text.split(' ');
  return <>
    {words.map((word, index) => {
      if (word.startsWith('http://') || word.startsWith('https://')) {
        return (
          <Link
            to={word}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            style={{color: 'inherit'}}
          >
            {word}
          </Link>
        );
      }
      return word + ' ';
    })}
  </>
}

function renderMessageStatus(message) {
  let text = '', color = 'secondary';
  if (message.status === MessageBoxStatus.Waiting) {
    text = i18n.t("Sending");
    color = "#717272"
  } else if (message.status === MessageBoxStatus.Sent) {
    text = i18n.t("Sent");
    color = "#717272"
  } else if (message.status === MessageBoxStatus.Read) {
    text = i18n.t("Seen");
    color = "#464748"
  }
  return <Typography
    variant='body2'
    color={color}
    sx={{display: 'block', textAlign: 'left', fontSize: '.75rem', ml: 3, mr: 1}}
  >
    {message.status === MessageBoxStatus.Read && (
      <VisibilityIcon sx={{fontSize: '.7rem', mr: .5, transform: 'translateY(-1px)'}}/>
    )}
    {text}
  </Typography>
}

function renderOptionsDeleteIcon(onClick) {
  return <Box>
    <IconButton size='small' onClick={onClick}>
      <DeleteIcon size='small' sx={{fontSize: '.95rem'}}/>
    </IconButton>
  </Box>
}

function renderOptionsReplyIcon(onClick) {
  return <Box>
    <IconButton size='small' onClick={onClick}>
      <ReplyIcon size='small' sx={{fontSize: '.95rem'}}/>
    </IconButton>
  </Box>
}

const MESSAGE_REACTION_EMOJIS = [
  "+1",
  "joy",
  "open_mouth",
  "worried",
  "rage",
  "heart",
]

function renderOptionsReactionIcon(
  open,
  onOpen,
  onClose,
  onSelect,
  selectedEmojiId = null,
) {
  return <>
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        PopperProps={{disablePortal: true,}}
        onClose={onClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='top'
        title={
          <Box sx={{display: 'flex'}}>
            {MESSAGE_REACTION_EMOJIS.map(emojiId => (
              <IconButton
                key={emojiId}
                size='small'
                onClick={e => onSelect(emojiId)}
                sx={{
                  color: 'black',
                  py: .25,
                  ...(selectedEmojiId && selectedEmojiId === emojiId ? {backgroundColor: 'rgba(255, 255, 255, .2)'} : null)
                }}
              >
                <em-emoji id={emojiId}/>
              </IconButton>
            ))}
          </Box>
        }
      >
        <IconButton size='small' onClick={onOpen}>
          <SentimentSatisfiedAltIcon size='small' sx={{fontSize: '.95rem'}}/>
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  </>
}

function Options(
  {
    message,
    position,
    getHigherTrackingStack = () => null,
    removeHigherTrackingStack = () => null,
  }) {
  const [showReactions, setShowReactions] = useState(false);

  const openReactions = () => {
    getHigherTrackingStack()
    setShowReactions(true)
  }

  const hideReactions = () => {
    removeHigherTrackingStack()
    setShowReactions(false)
  }

  const onReactionHandler = emojiId => {
    if (message.emoji_id === emojiId) {
      eventBus.dispatch('message-reaction-remove', {
        message_id: message.data.message_id,
        dialog_id: message.data.dialog_id,
      })
    } else {
      eventBus.dispatch('message-reaction', {
        dialog_id: message.data.dialog_id,
        message_id: message.data.message_id,
        emoji_id: emojiId,
      })
    }
  }

  const deleteMessageHandler = () => {
    if (window.confirm(i18n.t("Are you sure you want to delete this message?"))) {
      eventBus.dispatch('delete-message', {dialog_id: message.data.dialog_id, message_id: message.data.message_id})
    }
  }

  const replyToHandler = () => {
    eventBus.dispatch('reply-to', {message})
  }

  return <Box className={'chat-message-options' + ' ' + (showReactions ? 'items-visible' : '')}>
    {message.data.message_id > 0 && (
      position === "left" ? (
        <>
          {renderOptionsReactionIcon(showReactions, openReactions, hideReactions, onReactionHandler, message.emoji_id)}
          {renderOptionsReplyIcon(replyToHandler)}
        </>
      ) : (
        <>
          {!message.is_deleted && (
            renderOptionsDeleteIcon(deleteMessageHandler)
          )}
          {renderOptionsReplyIcon(replyToHandler)}
        </>
      ))}
  </Box>
}

function renderReplyToHeader(message, messageClasses) {
  return <Box sx={{mt: .5, marginBottom: '-.5rem'}}>
    <Box sx={{display: 'inline-block'}}>
      <Typography variant='caption' className='reply-to-text'>
        {i18n.t("Replied to")}
      </Typography>
      <Box className={messageClasses + ' chat-message-reply'}>
        <Typography variant='body1' className='message-text' sx={{textAlign: 'left'}}>
          {message.reply_to.is_deleted ? <i style={{fontSize: '.75rem'}}>{i18n.t("Message was deleted")}</i> : (
            renderTextMessage(getMessageSubstring(message.reply_to.text, 100))
          )}
        </Typography>
      </Box>
    </Box>
  </Box>
}

function renderMessageText(message) {
  return <Typography variant='body1' className='message-text' sx={{textAlign: 'left'}}>
    {renderTextMessage(message.text)}
  </Typography>
}

function renderCallMessage(message) {
  return <Box className='chat-message-call' sx={{display: 'flex'}}>
    <IconButton color='secondary'
                sx={{mx: .2, background: 'lightgrey', ":hover": {background: 'lightgrey'}}}>
      <CallIcon fontSize='small'/>
    </IconButton>
    <Typography variant='body2' sx={{my: 1, ml: 1, fontWeight: 'bolder'}}>
      {i18n.t("Call")}
    </Typography>
  </Box>
}

function renderMessageReaction(message, position) {
  return <Box
    className='message-reaction'
    sx={{
      textAlign: position === "right" ? "right" : "right",
      marginTop: '-.75rem',
      marginRight: '.25rem',
      position: 'relative',
      zIndex: '200'
    }}
  >
    <em-emoji id={message.emoji_id} size='1rem'/>
  </Box>
}

const UserMessage = memo(({position, message, isFirstMessage, isLastMessage, showMessageStatus}) => {
  const [hasHigherTrackingStack, setHasHigherTrackingStack] = useState(false);

  const classes = [
    "chat-message-content",
    getChatMessageTypeClass(message.type),
    getBorderClasses(position, isFirstMessage, isLastMessage),
    defaultOptions.messageBackground[position],
  ]
  if (message.is_deleted) {
    classes.push('deleted-message')
  }

  let shouldShowMessageStatus = false;
  if ((isTextMessage(message) || isReplyMessage(message)) && showMessageStatus) {
    if (message.data.out && isLastMessage && !message.is_deleted) {
      shouldShowMessageStatus = true;
    }
  }

  return <Box
    className='chat-message-content-wrapper'
    sx={{
      position: 'relative',
      zIndex: hasHigherTrackingStack ? 101 : 100,
      justifyContent: position === "left" ? "start" : "end",
      textAlign: position === "left" ? "left" : "right",
      mb: (isReplyMessage(message) && !isLastMessage ? '1rem' : null)
    }}
  >
    <Box sx={{maxWidth: '75%'}}>
      {isReplyMessage(message) && (
        renderReplyToHeader(message, classes.join(' '))
      )}
      <Box sx={{display: 'inline-block'}}>
        <Box
          className={classes.join(' ')}
          sx={{display: 'inline-block', marginBottom: '1px'}}>
          {!message.is_deleted ? (
            <>
              {(isTextMessage(message) || isReplyMessage(message)) && (
                renderMessageText(message)
              )}
              {isCallMessage(message) && (
                renderCallMessage(message)
              )}
              <Box className='chat-message-options-wrapper'
                   sx={{...(position === "left" ? {right: '-65px'} : {left: '-65px'})}}>
                <Options
                  message={message}
                  position={position}
                  getHigherTrackingStack={() => setHasHigherTrackingStack(true)}
                  removeHigherTrackingStack={() => setHasHigherTrackingStack(false)}
                />
              </Box>
            </>
          ) : (
            <Typography variant='body2' sx={{textAlign: 'left'}}>
              {i18n.t("This message was deleted")}
            </Typography>
          )}
        </Box>
        {message.emoji_id && !message.is_deleted && (renderMessageReaction(message, position))}
        {shouldShowMessageStatus && renderMessageStatus(message)}
      </Box>
    </Box>
  </Box>
}, (oldProps, newProps) => {
  return oldProps.position === newProps.position
    && oldProps.isFirstMessage === newProps.isFirstMessage
    && oldProps.isLastMessage === newProps.isLastMessage
    && oldProps.showMessageStatus === newProps.showMessageStatus
    && chatMessageIsSame(oldProps.message, newProps.message)
})

const UserMessages = memo(({position, messages, showMessageStatus}) => {
  return <Box sx={{width: '100%'}}>
    {messages.map((message, index) => (
      <UserMessage
        key={`message-${message.data.message_id}`}
        position={position}
        message={message}
        isFirstMessage={index === 0}
        isLastMessage={index === messages.length - 1}
        showMessageStatus={showMessageStatus}
      />
    ))}
  </Box>
}, (oldProps, newProps) => {
  return oldProps.position === newProps.position
    && oldProps.showMessageStatus === newProps.showMessageStatus
    && chatMessagesAreSame(oldProps.messages, newProps.messages)
})

export default UserMessages;