import AuthLayout from "layouts/AuthLayout";
import {Box, Button, CircularProgress, Paper, TextField, Typography} from "@mui/material";
import {getInputChangeHandler, setFormErrors, showMessage} from "@core/utils";
import React, {useEffect, useState} from "react";
import i18n from "@core/configs/i18n";
import userService from "../../user/User.service";


export default function ForgotPassword() {
  const [formInput, setFormInput] = useState({
    email: {value: '', error: ''},
  });
  const [loading, setLoading] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const {ok, result, errors} = await userService.sendPasswordResetLink(formInput.email.value)
    setLoading(false);
    if (ok) {
      showMessage(true, i18n.t("Password reset link sent successfully"))
      setFormInput({email: {value: '', error: ''}})
      setLinkSent(true)
    } else {
      setFormErrors(errors, setFormInput)
    }
  }

  return <>
    <AuthLayout>
      <Paper sx={{maxWidth: {xs: '100%', md: '400px'}, mt: {xs: 0, md: '20px'}}}>
        <Box sx={{px: 4, py: 3}}>
          <Typography variant='h5' sx={{mb: 2}}>
            {i18n.t("Forgot Password")}
          </Typography>
          {!linkSent ? (
            <form onSubmit={handleSubmit}>
              <TextField
                label={i18n.t("Email Address")}
                type="email"
                value={formInput.email.value}
                error={Boolean(formInput.email.error)}
                helperText={formInput.email.error}
                onChange={getInputChangeHandler('email', setFormInput)}
                fullWidth
                required
                sx={{mb: 2, bg: 'white'}}
              />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                disabled={loading}
              >
                {loading && <CircularProgress color="secondary" size='1.5rem' sx={{mr: 1}}/>}
                {i18n.t("Reset")}
              </Button>
            </form>
          ) : (
            <Typography color='green' variant='body1' sx={{mb: 2}}>
              {i18n.t("Link sent successfully")}
            </Typography>
          )}
        </Box>
      </Paper>
    </AuthLayout>
  </>
}