import {Fragment, memo, useCallback, useEffect, useRef, useState} from "react";
import {useAuth} from "hooks/useAuth";
import {useNavigate} from "react-router-dom";
import userService from "../../../User.service";
import {getDateDiffString, getFirstErrorString, getQueryParamFromUrl, showMessage} from "@core/utils";
import i18n from "@core/configs/i18n";
import {Box, IconButton, styled, Typography} from "@mui/material";
import GlobalInfiniteScroll from "components/global-infinite-scroll";
import UserInfo from "components/user-info";
import TextsmsIcon from "@mui/icons-material/Textsms";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import OptionsMenu from "components/options-menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "components/Loader";
import Modal from "components/modal";
import Editor from "components/editor";
import ReplyIcon from '@mui/icons-material/Reply';

import "./index.css";
import feedService from "../../../../feed/Feed.service";
import {
  renderFeedMainContent,
  renderFeedTitle,
} from "components/feed-share/helpers";
import eventBus from "@core/event-bus";

const FEED_PER_PAGE = 30;

const Dot = styled(Box)(({theme}) => ({
  width: '.2rem',
  height: '.2rem',
  borderRadius: '50%',
  backgroundColor: theme.palette.text.secondary,
  margin: 'auto',
}))


function UpdatePostComponent({post, onUpdate}) {
  const [editPostModalLoading, setEditPostModalLoading] = useState(false);
  const [editorState, setEditorState] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    setEditorState(post.content);
  }, [])

  const editPost = async () => {
    setEditPostModalLoading(true);
    const {ok, result, errors} = await userService.updatePost(
      post.id,
      editorState,
      uploadedImages.map(image => image.id)
    )
    setEditPostModalLoading(false);
    if (ok) {
      showMessage(true, i18n.t("Post updated successfully"))
      onUpdate(result);
    } else {
      showMessage(false, getFirstErrorString(errors))
    }
  }

  return <Modal
    open={true}
    onSubmit={editPost}
    onClose={e => onUpdate(null)}
    loading={editPostModalLoading}
    title={i18n.t("Edit post")}>
    <Editor
      editorState={editorState}
      setEditorState={setEditorState}
      imageUploadUrl='/posts/images/'
      onImageUpload={image => setUploadedImages(prev => [...prev, image])}
    />
  </Modal>
}


function renderFeedNextToTitleContent(created_at) {
  return <>
    <Typography className='light-content-color' sx={{mt: 2.25, mr: 2, ml: 1}}>
      {/*<Dot/>*/}
    </Typography>
    <Typography className='light-content-color' sx={{mt: 1}}>
      {getDateDiffString(created_at)}
    </Typography>
  </>
}

function renderOptions(currentUser, feed, onShare, onEdit, onDelete) {
  const {user_data, type} = feed;
  const iamThisFeedItemOwner = currentUser?.id === user_data.id;
  const options = [
    {
      label: i18n.t("Share"),
      icon: <ReplyIcon fontSize='small'/>,
      onClick: () => onShare(),
      sx: {px: 1},
    },
  ];
  if (iamThisFeedItemOwner) {
    if (type === "post") {
      options.push({
        label: i18n.t("Edit"),
        icon: <EditIcon fontSize='small'/>,
        onClick: () => onEdit(),
        sx: {px: 1},
      })
    }
    options.push({
      label: i18n.t("Delete"),
      icon: <DeleteIcon fontSize='small'/>,
      onClick: () => onDelete(),
      sx: {px: 1},
    })
  }
  return (
    <Box sx={{mt: -1}} onClick={e => e.stopPropagation()}>
      <OptionsMenu
        uniqueId={feed.id}
        options={options}
      />
    </Box>
  )
}

function renderFeedFooter(feed, onLike) {
  const likeHandler = async (e) => {
    e.stopPropagation()
    if (feed.is_liked) {
      await feedService.unlike(feed.id);
    } else {
      await feedService.like(feed.id);
    }
    onLike(feed);
  }

  return <Box sx={{display: 'flex'}}>
    <Box sx={{display: 'flex', ":hover p": {color: 'blue'}}}>
      <TextsmsIcon
        className='blue-icon'
        fontSize='small'
      />
      <Typography
        variant='body2'
        color='secondary'
        sx={{mx: 1}}>
        {feed.comments_count}
      </Typography>
    </Box>
    <Box sx={{mx: 1, display: 'flex', ":hover p": {color: 'red'}}}>
      <IconButton size='small' sx={{mt: -.5}} onClick={likeHandler}>
        <FavoriteBorderIcon
          className={'red-icon ' + (feed.is_liked ? 'active' : '')}
          fontSize='small'
        />
      </IconButton>
      <Typography
        variant='body2'
        color={feed.is_liked ? 'error' : 'secondary'}
        sx={{mx: .5}}>
        {feed.likes_count}
      </Typography>
    </Box>
  </Box>
}

const FEED_ITEM_DELETE_HANDLER = {
  post: (feed) => userService.deletePost(feed.post_data.id),
  product: (feed) => feedService.deleteFeed(feed.id),
  share: (feed) => feedService.deleteFeed(feed.id),
}

function isFeedItemSame(oldFeed, newFeed) {
  if (oldFeed.id !== newFeed.id) {
    return false;
  } else if (oldFeed.is_component_deleted !== newFeed.is_component_deleted) {
    return false;
  } else if (oldFeed.is_liked !== newFeed.is_liked || oldFeed.likes_count !== newFeed.likes_count) {
    return false;
  }

  const {type} = newFeed;
  if (type === "post") {
    return oldFeed._update_key === newFeed._update_key;
  } else {
    return true;
  }
}

const FeedItem = memo((
  {
    feed,
    navigate,
    user,
    onUpdate,
    onDelete,
    onShare,
    onLike,
  }) => {
  console.log('rendering feed item', feed.id)
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSharing, setIsSharing] = useState(null);
  const [shareModalLoading, setShareModalLoading] = useState(false);

  const handleEditClick = () => {
    if (feed.type === "post") {
      setIsUpdating(true);
    }
  }

  const handlePostUpdate = (post) => {
    setIsUpdating(false);
    if (!post) {
      return;
    }
    onUpdate({...feed, post_data: post})
  }

  const handleDeleteClick = async () => {
    const {type} = feed;
    const feedItemDeleteHandler = FEED_ITEM_DELETE_HANDLER[type];
    if (!feedItemDeleteHandler) {
      throw new DOMException('unknown type - ' + type);
    }
    // TODO handle the case when shared item is deleted
    if (window.confirm(i18n.t("Are you sure you want to delete this feed item ?"))) {
      const {ok, errors} = await feedItemDeleteHandler(feed);
      if (ok) {
        showMessage(true, i18n.t("Feed item deleted successfully"))
        onDelete(feed)
      } else {
        showMessage(false, getFirstErrorString(errors))
      }
    }
  }

  const handleShareClick = () => {
    setIsSharing(true)
  }

  const handleShare = async () => {
    setShareModalLoading(true)
    const {ok, errors} = await feedService.shareFeed(feed.id);
    if (ok) {
      showMessage(true, i18n.t("Feed item shared successfully"))
      onShare()
    } else {
      showMessage(false, getFirstErrorString(errors))
    }
    setShareModalLoading(false)
    setIsSharing(false)
  }

  return <>
    {!feed.is_component_deleted && (
      <UserInfo
        data={feed.user_data}
        title={renderFeedTitle(feed)}
        nextToTitleContent={renderFeedNextToTitleContent(feed.created_at)}
        mainContent={<>
          <Box sx={{px: 7}}>
            {renderFeedMainContent(feed, navigate)}
            {renderFeedFooter(feed, () => onLike(feed))}
          </Box>
        </>}
        rightContent={<Box sx={{ml: 1}}>
          {renderOptions(
            user,
            feed,
            () => handleShareClick(),
            () => handleEditClick(),
            () => handleDeleteClick(),
          )}
        </Box>}
        alignNextToTitleContent='right'
        onClick={() => navigate(`/feed/${feed.id}`)}
        disableComponentClick={false}
        hoverColor='rgb(248, 249, 250)'
        padding='.5rem 1rem'
        sx={{borderTop: '1px solid #e9ecef', borderLeft: 'none', borderRight: 'none'}}
      />
    )}

    {isUpdating && (
      <UpdatePostComponent post={feed.post_data} onUpdate={handlePostUpdate}/>
    )}

    {isSharing && (
      <Modal
        open={true}
        onSubmit={e => handleShare()}
        onClose={e => setIsSharing(false)}
        loading={shareModalLoading}
        title={
          <Typography>
            {i18n.t("Are you sure you want to share this feed item ?")}
          </Typography>
        }
      />
    )}
  </>
}, (oldFeedItem, newFeedItem) => {
  return isFeedItemSame(oldFeedItem.feed, newFeedItem.feed)
    && oldFeedItem.onUpdate === newFeedItem.onUpdate
    && oldFeedItem.onDelete === newFeedItem.onDelete
    && oldFeedItem.onShare === newFeedItem.onShare
    && oldFeedItem.onLike === newFeedItem.onLike
})

const MemoizedFeedItems = memo((
  {
    feed,
    onUpdate,
    onDelete,
    onShare,
    onLike,
    user,
    navigate
  }) => {
  return <>
    {feed.map(feed => (
      <FeedItem
        key={feed.id}
        feed={feed}
        navigate={navigate}
        user={user}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onShare={onShare}
        onLike={onLike}
      />
    ))}
  </>
}, (oldProps, newProps) => {
  const oldFeed = oldProps.feed;
  const newFeed = newProps.feed;
  if (oldFeed.length !== newFeed.length) {
    return false;
  }
  for (let i = 0; i < oldFeed.length; i++) {
    if (!isFeedItemSame(oldFeed[i], newFeed[i])) {
      return false;
    }
  }
  return oldProps.onUpdate === newProps.onUpdate
    && oldProps.onDelete === newProps.onDelete
    && oldProps.onShare === newProps.onShare
    && oldProps.onLike === newProps.onLike
})

export default function Feed({user, includeFriends, is_hidden}) {
  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const cursorRef = useRef(null);
  const requestInProgressRef = useRef(false); // `loading` is very slow
  const isComponentHiddenRef = useRef(is_hidden);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeed(cursorRef.current)
  }, [])

  useEffect(() => {
    const listener = eventBus.on('post-created', async () => {
      if (String(user.id) === String(auth.user.id)) {
        // user created a post
        const {ok, result, errors} = await userService.getLatestFeedItem(user.id)
        if (ok) {
          setFeed(prev => [result, ...prev])
        }
      }
    })

    return () => {
      eventBus.remove('post-created', listener)
    }
  }, [auth.user, user])

  useEffect(() => {
    isComponentHiddenRef.current = is_hidden;
  }, [is_hidden])

  const fetchFeed = (cursor) => {
    if (loading || requestInProgressRef.current) return;
    setLoading(true);
    requestInProgressRef.current = true;

    let promise = null;
    if (includeFriends) {
      promise = userService.getFeed(user.id, cursor, FEED_PER_PAGE)
    } else {
      promise = userService.getAuthorFeed(user.id, cursor, FEED_PER_PAGE)
    }

    promise.then(({ok, result, errors}) => {
      if (ok) {
        const {results, next} = result;
        setFeed(prev => [...prev, ...results]);
        if (next) {
          cursorRef.current = getQueryParamFromUrl(next, 'cursor');
        } else {
          setHasMore(false);
        }
      } else {
        showMessage(false, i18n.t("Something went wrong. Please, refresh the page"))
      }
      setLoading(false)
      requestInProgressRef.current = false;
    })
  }

  const scrollToBottomHandler = e => {
    if (!isComponentHiddenRef.current) {
      fetchFeed(cursorRef.current)
    }
  }

  const onFeedItemDelete = useCallback((feedItem) => {
    setFeed(prev => prev.map(f => f.id === feedItem.id
      ? {
        ...feedItem,
        is_component_deleted: true, // just for performance purposes to avoid re-rendering of other components
      }
      : f
    ))
  }, [])

  const onFeedItemUpdate = useCallback((feedItem) => {
    setFeed(prev => prev.map(f => f.id === feedItem.id
      ? {
        ...feedItem,
        _update_key: (feedItem._update_key || 0) + 1
      }
      : f
    ))
  }, [])

  const onFeedItemShare = useCallback(() => {
    if (String(user.id) === String(auth.user.id)) {
      // user shared a post
      userService.getLatestFeedItem(user.id).then(({ok, result, errors}) => {
        if (ok) {
          setFeed(prev => [result, ...prev])
        }
      })
    }
  }, [])

  const onFeedItemLike = useCallback((feedItem) => {
    setFeed(prev => {
      return prev.map(f => f.id === feedItem.id
        ? {
          ...feedItem,
          likes_count: feedItem.is_liked ? feedItem.likes_count - 1 : feedItem.likes_count + 1,
          is_liked: !feedItem.is_liked,
        }
        : f
      )
    })
  }, [])

  return (
    <Box>
      {(!loading && (feed.length === 0 || (feed.length < 100 && !feed.some(el => el.is_component_deleted !== true)))) ? (
        <Typography className='no-feed' sx={{m: 3, mt: 2, textAlign: 'center', color: 'grey'}}>
          {i18n.t("Feed is empty")}
        </Typography>
      ) : (
        <Fragment>
          <GlobalInfiniteScroll
            onScrollToBottom={(e) => scrollToBottomHandler(e)}
            hasMore={hasMore}
          >
            <MemoizedFeedItems
              feed={feed}
              onUpdate={onFeedItemUpdate}
              onDelete={onFeedItemDelete}
              onShare={onFeedItemShare}
              onLike={onFeedItemLike}
              navigate={navigate}
              user={auth.user}
            />
          </GlobalInfiniteScroll>
          {loading && (
            <Loader size='3rem' mt='1rem'/>
          )}
        </Fragment>
      )}
    </Box>
  )
}