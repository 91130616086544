import {Box, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import SearchUsers from "../../components/search-users";
import Sidebar from "./sidebar";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import i18n from "../../@core/configs/i18n";

export default function ProfilePageLayout() {
  const {user_id} = useParams();
  const navigate = useNavigate();

  return <>
    <Paper id='profile-page-layout-content-wrapper' className='use-max-height'>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container className='use-max-height'>
            <Grid item xl={3} lg={3} md={1} sm={0}
                  sx={{pt: 1.5}}>
              {/*<Sidebar user_id={user_id}/>*/}
            </Grid>
            <Grid item lg={6} md={7} sm={12} xs={12}>
              <Box sx={{height: '100%', borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey'}}>
                <Box sx={{pt: 2, mb: 2, mx: 1, display: 'flex'}}>
                  <IconButton onClick={e =>  navigate(-1)}>
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography
                    variant='body'
                    onClick={e => navigate(-1)}
                    sx={{mx: '.2rem', mt: '.5rem', fontWeight: 'bolder', cursor: 'pointer'}}>
                    {i18n.t("Go back")}
                  </Typography>
                </Box>
                <Outlet/>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={0} xs={0} sx={{width: '100%', display: {md: 'flex', sm: 'none', xs: 'none'}}}>
              <Box sx={{px: 2, py: 1, width: '100%'}}>
                <SearchUsers showFollowButton={true}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </>
}