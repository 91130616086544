import {useNavigate, useParams} from "react-router-dom";
import {memo, useEffect, useMemo, useState} from "react";
import {Box, Divider, IconButton, Typography} from "@mui/material";
import Loader from "components/Loader";
import {
  getDateDiffString,
  getFirstErrorString,
  getQueryParamFromUrl,
  showMessage
} from "@core/utils";
import i18n from "@core/configs/i18n";
import UserInfo from "components/user-info";
import moment from "moment";
import TextsmsIcon from "@mui/icons-material/Textsms";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import WriteComment from "components/comment";

import "./index.css"
import GlobalInfiniteScroll from "components/global-infinite-scroll";
import feedService from "./Feed.service";
import {renderFeedMainContent, renderFeedTitle} from "components/feed-share/helpers";
import {useImages} from "hooks/useImages";
import OptionsMenu from "components/options-menu";
import DeleteIcon from "@mui/icons-material/Delete";
import {useAuth} from "../../hooks/useAuth";


const COMMENTS_PER_FEED = 10;

function Comment({comment, currentUser, onDelete}) {
  return <Box
    className='feed-page-comment'
    key={comment.id}>
    <UserInfo
      data={comment.user_data}
      mainContent={comment.content}
      hoverColor='rgb(248, 249, 250)'
      rightContent={
        <Box sx={{display: 'flex'}}>
          <Typography
            variant='body2'
            className='light-content-color'>
            {getDateDiffString(comment.created_at)}
          </Typography>
          {currentUser.id === comment.user_data.id && (
            <Box sx={{ml: 2, mt: -1}}>
              <OptionsMenu
                uniqueId={comment.id}
                options={[{
                  label: i18n.t("Delete"),
                  icon: <DeleteIcon fontSize='small'/>,
                  onClick: () => onDelete(),
                }]}
              />
            </Box>
          )}
        </Box>
      }
    />
  </Box>
}

const Comments = memo(({comments, setComments}) => {
  const auth = useAuth();

  const deleteCommentHandler = (comment) => {
    if (window.confirm(i18n.t("Are you sure you want to delete this comment?"))) {
      feedService.deleteComment(comment.id).then(({ok, result, errors}) => {
        if (ok) {
          setComments(prev => prev.filter(c => c.id !== comment.id))
        } else {
          showMessage(false, getFirstErrorString(errors))
        }
      })
    }
  }

  return <>
    {
      comments.map((comment, index) => <Comment
        comment={comment}
        key={comment.id}
        currentUser={auth.user}
        onDelete={() => deleteCommentHandler(comment)}
      />)
    }
  </>
}, (oldProps, newProps) => {
  return oldProps.comments.length === newProps.comments.length
})

export default function Feed() {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingLike, setLoadingLike] = useState(false);

  const navigate = useNavigate();
  const images = useImages();

  useEffect(() => {
    setComments([]);
    setCursor(null);
    setHasMore(true);
    fetchFeedInfo()
    fetchComments(cursor)
  }, [id])

  const fetchFeedInfo = async () => {
    setLoading(true);
    feedService.getFeed(id).then(({ok, result, errors}) => {
      if (ok) {
        setFeed(result)
      }
      setLoading(false);
    })
  }

  const fetchComments = cursor => {
    if (loadingComments) return;
    setLoadingComments(true);
    feedService.getFeedComments(id, cursor, COMMENTS_PER_FEED).then(({ok, result, errors}) => {
      if (ok) {
        const {results, next} = result;
        setComments(prev => [...prev, ...results]);
        if (next) {
          const cursor = getQueryParamFromUrl(next, 'cursor');
          setCursor(cursor);
        } else {
          setHasMore(false);
        }
      } else {
        showMessage(false, getFirstErrorString(errors))
      }
      setLoadingComments(false);
    })
  }

  const writeCommentHandler = async (content) => {
    const {ok, result, errors} = await feedService.writeComment(id, content)
    if (!ok) {
      showMessage(false, getFirstErrorString(errors))
    } else {
      setComments(prev => [result, ...prev])
    }
    return ok;
  }

  const likeHandler = async (e) => {
    if (loadingLike) return;
    setLoadingLike(true);
    if (feed.is_liked) {
      await feedService.unlike(feed.id);
    } else {
      await feedService.like(feed.id);
    }
    setLoadingLike(false);
    setFeed(prev => ({
      ...prev,
      likes_count: prev.is_liked ? prev.likes_count - 1 : prev.likes_count + 1,
      is_liked: !prev.is_liked,
    }))
  }

  if (loading) {
    return <>
      <Box sx={{m: 4}}>
        <Loader/>
      </Box>
    </>
  }

  if (!feed) {
    return <>
      <Box sx={{textAlign: 'center'}}>
        <Typography>
          {i18n.t("Feed item not found")}
        </Typography>
      </Box>
    </>
  }

  return <Box>
    <Box className='feed-page'>
      <Box className='feed-item-content' sx={{px: 2.5}}>
        <Box className=''>
          <UserInfo
            data={feed.user_data}
            title={renderFeedTitle(feed)}
            disableHover={true}
            padding='.5rem 0'
          />
        </Box>
        {renderFeedMainContent(feed, navigate)}
        <Typography className='light-content-color'>
          {moment(feed.created_at).format('lll')}
        </Typography>
        <Divider sx={{my: 2}}/>
      </Box>
      <Box sx={{px: 2.5}}>
        <Box className='feed-body-footer' sx={{mb: 2}}>
          <Box sx={{display: 'flex'}}>
            <Box sx={{display: 'flex'}}>
              <IconButton className='blue-icon'>
                <TextsmsIcon fontSize='small'/>
              </IconButton>
              <Typography variant='body2' color='secondary' sx={{mx: .2, mt: .85}}>
                {feed.comments_count}
              </Typography>
            </Box>
            <Box sx={{mx: 1, display: 'flex'}}>
              <IconButton className={'red-icon ' + (feed.is_liked ? 'active' : null)} onClick={likeHandler}>
                <FavoriteBorderIcon fontSize='small'/>
              </IconButton>
              <Typography variant='body2' color={feed.is_liked ? 'error' : 'secondary'} sx={{mx: .2, mt: .85}}>
                {feed.likes_count}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{my: 2}}/>
      </Box>
      <Box sx={{borderBottom: '1px solid #f5f5f5', px: 2.5, pb: 1.5}}>
        <WriteComment submitHandler={writeCommentHandler}/>
      </Box>
      <Box className="feed-page-comments">
        <GlobalInfiniteScroll
          hasMore={hasMore}
          onScrollToBottom={() => fetchComments(cursor)}>
          <Comments comments={comments} setComments={setComments}/>
        </GlobalInfiniteScroll>
        {loadingComments && <Loader size='3rem' mt='1rem'/>}
      </Box>
    </Box>
  </Box>
}