import {Editor as BaseEditor} from '@tinymce/tinymce-react';
import React, {useEffect, useRef, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {request} from "../../axios";
import {getFirstErrorString} from "../../@core/utils";

export default function Editor(
  {
    editorState,
    setEditorState,
    imageUploadUrl = null,
    onImageUpload = () => null,
  }) {
  const [loading, setLoading] = useState(true);
  const imageUploadFunctionRef = useRef(null);

  // We are doing this manually as `Editor` doesn't update `images_upload_handler` automatically
  useEffect(() => {
    imageUploadFunctionRef.current = onImageUpload;
  }, [onImageUpload])

  useEffect(() => {
    const focusInListener = function (e) {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    }
    document.addEventListener('focusin', focusInListener);
    return () => {
      document.removeEventListener('focusin', focusInListener);
    }
  }, [])

  const imageUploadHandler = async (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());
    const {ok, result, errors} = await request.post(imageUploadUrl, formData);
    if (ok) {
      imageUploadFunctionRef.current(result)
      const {image} = result;
      return image;
    } else {
      throw new DOMException(getFirstErrorString(errors))
    }
  }

  return <>
    {loading && (
      <Box sx={{textAlign: 'center', my: 2}}>
        <CircularProgress color="primary" size={'3rem'}/>
      </Box>
    )}
    <Box sx={{display: loading ? 'none' : ''}}>
      <BaseEditor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/libs/tinymce/tinymce.min.js'}
        value={editorState}
        onInit={(evt, editor) => {
          setLoading(false);
        }}
        onEditorChange={(newValue, editor) => {
          setEditorState(newValue);
        }}
        init={{
          plugins:
            "media textcolor code image file link fullscreen lists wordcount hr preview",
          toolbar:
            "undo redo | bold strikethrough forecolor blockquote | image media link | formatselect alignleft aligncenter alignright | numlist bullist hr custom fullscreen code | preview",
          image_advtab: true,
          images_upload_handler: imageUploadHandler,
        }}
      />
    </Box>
  </>
}