import {request} from "../../axios";
import {DEFAULT_PAGE_SIZE} from "../../constants";

const service = {
  async getProduct(id) {
    return await request.get(`products/${id}/`)
  },
  async getProductSettings() {
    return await request.get(`products/settings/`)
  },
  async getProducts(filters, page = 1, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`products/`, {
      params: {
        ...filters,
        page,
        page_size,
      }
    })
  },
  async getBuyers(search, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`general/buyers/`, {
      params: {
        search,
        page_size
      }
    })
  },
  async getProviders(search, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`general/providers/`, {
      params: {
        search,
        page_size
      }
    })
  },
  async getTransiters(search, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`general/transiters/`, {
      params: {
        search,
        page_size
      }
    })
  },
}

export default service