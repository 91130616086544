import Box from "@mui/material/Box";
import {CircularProgress, Grid, Paper, TextField} from "@mui/material";
import i18n from "../../@core/configs/i18n";
import eventBus from "../../@core/event-bus";
import UserImage from "../../components/user-image";
import Typography from "@mui/material/Typography";
import {getDateDiffString, getMessageSubstring, getUserThumbnailOriginalOrDefaultImage} from "../../@core/utils";
import {isCallMessage, isReplyMessage, isTextMessage} from "../../components/chat/utils";
import CallIcon from "@mui/icons-material/Call";
import * as React from "react";
import {useChat} from "../../hooks/useChat";
import {useRef} from "react";


export function ChatList() {
  const chat = useChat();
  const [dialogQuery, setDialogQuery] = React.useState('')
  const searchDialogsRef = useRef()

  const searchDialogsHandler = e => {
    setDialogQuery(e.target.value);
    if (searchDialogsRef.current) {
      clearTimeout(searchDialogsRef.current)
    }
    searchDialogsRef.current = setTimeout(() => {
      chat.searchDialogs(e.target.value)
    }, 500)
  }

  return <>
    <Box sx={{width: '100%', px: 1.5}} onClick={e => e.stopPropagation()}>
      <TextField
        size='small'
        value={dialogQuery}
        placeholder={i18n.t('Search')}
        autoComplete='off'
        fullWidth
        onChange={e => searchDialogsHandler(e)}
        sx={{my: 1}}
      />
    </Box>
    {chat.searchingDialogs ? (
      <Box sx={{m: 2, textAlign: 'center'}}>
        <CircularProgress color="primary" size={'2.5rem'}/>
      </Box>
    ) : (
      <>
        {
          chat.dialogs.map(dialog => <Box key={dialog.id} onClick={e => {
            eventBus.dispatch('open-dialog', dialog)
          }}>
            <Box className='user-dialog'>
              <Box className='dialog-header'>
                <Box className='dialog-header-image'>
                  <UserImage src={getUserThumbnailOriginalOrDefaultImage(dialog, 'image_data')}/>
                </Box>
                <Box className='dialog-header-info' sx={{mx: 1, width: '100%'}}>
                  <Box sx={{textAlign: 'left'}}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        mt: !dialog.lastMessage ? 1 : 0,
                        justifyContent: 'space-between'
                      }}>
                      <Box>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {dialog.title}
                        </Typography>
                      </Box>
                      <Box sx={{display: 'flex'}}>
                        {dialog.unread > 0 && (
                          <Box className='unread-messages-box' sx={{mx: .75, width: '1.5rem', height: '1.5rem'}}>
                            <span>{dialog.unread}</span>
                          </Box>)}
                        {dialog.lastMessage && (
                          <Typography variant='body2' sx={{color: 'grey', whiteSpace: 'nowrap'}}>
                            {getDateDiffString(dialog.lastMessage.date)}
                          </Typography>
                        )}

                      </Box>
                      {/*<Dot size='.5rem' color='green' margin='.35rem .5rem'/>*/}
                    </Box>
                    {dialog.lastMessage && (<>
                      {(isTextMessage(dialog.lastMessage) || isReplyMessage(dialog.lastMessage)) && (
                        <Typography variant='body2' className='dialog-header-info-subtitle'>
                          {getMessageSubstring(dialog.subtitle, 85)}
                        </Typography>)}
                      {isCallMessage(dialog.lastMessage) && (<Box className='chat-message-call' sx={{display: 'flex'}}>
                        <CallIcon fontSize='small'/>
                        <Typography variant='body2' sx={{ml: 1, fontWeight: 'bolder'}}>
                          {i18n.t("Call")}
                        </Typography>
                      </Box>)}
                    </>)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>)
        }
        {chat.fetchingMoreDialogs && (
          <Box sx={{m: 2, textAlign: 'center'}}>
            <CircularProgress color="primary" size={'1.5rem'}/>
          </Box>
        )}
      </>
    )}
    {(chat.dialogs.length === 0 && !chat.searchingDialogs) && (
      <Box className='no-dialogs' sx={{p: "1rem 5rem", textAlign: 'center'}}>
        <Typography variant='body' sx={{color: "#bcb7b7"}}>
          {i18n.t("There are no dialogs")}
        </Typography>
      </Box>)}
  </>
}


export default function Index() {
  const chat = useChat();

  const scrollHandler = e => {
    const {scrollHeight, scrollTop, clientHeight} = e.target
    if (scrollTop + clientHeight >= scrollHeight) {
      chat.fetchMoreDialogs()
    }
  }

  return <Box sx={{height: '100%'}}>
    <Grid container sx={{justifyContent: 'center', height: '100%'}}>
      <Grid item xs={12} sm={12} md={10} lg={5} xl={4} sx={{width: '100%', height: '100%'}}>
        <Box sx={{height: '100%'}}>
          <Paper sx={{py: 1, height: '100%', overflowY: 'auto'}} onScroll={scrollHandler}>
            <ChatList/>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  </Box>
}