import {
  Box,
  Button,
  FormControl,
  Grid, InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import i18n from "@core/configs/i18n";
import Autocomplete from "./Autocomplete";
import {getInputChangeHandler, normalizeOptions} from "@core/utils";
import productService from "../screens/product/Product.service";
import AsyncAutocomplete from "./AsyncAutocomplete";
import {period, ratings} from "./data";
import {useProductSettings} from "../hooks/useProductSettings";
import ProductCodesDropdown from "./product-codes-dropdown";
import React from "react";
import Currency from "./currency";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const yearFromOptions = (() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= 1980; i--) {
    years.push({label: String(i), code: String(i)})
  }
  return years;
})()

export default function ProductsFilter(
  {
    loading = false,
    onSubmit = () => null,
    formInput = {},
    onUpdate = (field, value) => null,
    onReset = () => null,
  }) {
  const {locations, live_locations, currency_codes} = useProductSettings();

  const getFilterChangeHandler = (field, takesEvent = true) => {
    return function (eventOrValue) {
      const value = takesEvent ? eventOrValue.target.value : eventOrValue;
      onUpdate(field, value);
    }
  }

  const searchHandler = (e) => {
    e.preventDefault();
    onSubmit();
  }

  // const [filterForm, setFilterForm] = useState({
  //   buyer: {value: null, error: null},
  //   buyer_rating: {value: null, error: null},
  //   provider: {value: null, error: null},
  //   transiter: {value: null, error: null},
  //   provider_rating: {value: null, error: null},
  //   product: {value: null, error: null},
  //   category: {value: null, error: null},
  //   year_from: {value: null, error: null},
  //   year_to: {value: null, error: null},
  //   price_from: {value: null, error: null},
  //   price_to: {value: null, error: null},
  //   location: {value: null, error: null},
  //   live_location: {value: null, error: null},
  //   announcement_code: {value: null, error: null},
  //   period: {value: null, error: null},
  //   customs_clearance: {value: null, error: null},
  //   search: {value: null, error: null},
  // })

  return (
    <Box sx={{p: 2}}>
      <form onSubmit={searchHandler} style={{w: '100%'}}>
        <Grid container>
          <> {/*First row*/}
            {/*<Grid item xs={12} md={1}>*/}
            {/*  <Box sx={{p: 1, py: .5, border: '1px solid grey'}}> image </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={4}>
              <Box sx={{px: .5, py: .45}}>
                {/*buyer*/}
                <AsyncAutocomplete
                  size='small'
                  fireFullOption
                  onChange={getFilterChangeHandler('buyer', false)}
                  selectedOption={formInput.buyer}
                  label={i18n.t("Buyer")}
                  onSearch={async value => {
                    const {result} = await productService.getBuyers(value)
                    return normalizeOptions(result.results || [], 'name')
                  }}
                  sx={{background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*year-from*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Year - from")}
                  value={formInput.year_from}
                  onChange={getFilterChangeHandler('year_from', false)}
                  options={yearFromOptions}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*year-to*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Year - to")}
                  value={formInput.year_to}
                  onChange={getFilterChangeHandler('year_to', false)}
                  options={yearFromOptions}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*buyer-rating*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Buyer rating")}
                  options={ratings}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*location*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Location")}
                  value={formInput.location}
                  onChange={getFilterChangeHandler('location', false)}
                  options={normalizeOptions(locations)}
                />
              </Box>
            </Grid>
          </>
          <> {/*second row*/}
            {/*<Grid item xs={12} md={1}>*/}
            {/*  <Box sx={{p: 1, py: .5, border: '1px solid grey'}}> image </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={4}>
              <Box sx={{px: .5, py: .45}}>
                {/*provider*/}
                {/*<Autocomplete label="Provider" options={providers}/>*/}
                <AsyncAutocomplete
                  size='small'
                  fireFullOption={true}
                  onChange={getFilterChangeHandler('provider', false)}
                  selectedOption={formInput.provider}
                  label={i18n.t("Provider")}
                  onSearch={async value => {
                    const {result} = await productService.getProviders(value)
                    return normalizeOptions(result.results || [], 'name')
                  }}
                  sx={{background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*price-from*/}
                <TextField
                  type='number'
                  label={i18n.t("Price - from")}
                  value={formInput.price_from}
                  onChange={getFilterChangeHandler('price_from')}
                  size='small'
                  fullWidth
                  sx={{m: 0, background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*price-to*/}
                <TextField
                  type='number'
                  label={i18n.t("Price - to")}
                  value={formInput.price_to}
                  onChange={getFilterChangeHandler('price_to')}
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <Select
                        value={formInput.currency_code}
                        onChange={getFilterChangeHandler('currency_code')}
                        size='small'
                        IconComponent={() => <></>}
                        displayEmpty
                        renderValue={(value) => {
                          if (!value) {
                            return <Currency code={"USD"} sx={{ml: {xs: 1, md: 0}, color: 'rgba(0, 0, 0, .3)'}} />
                          }
                          return <Currency code={value} sx={{ml: {xs: 1, md: 0}}} />
                        }}
                        sx={{mr: -1.75, width: {xs: '60px', md: '40px'}, pr: 2}}
                      >
                        <MenuItem value={null} sx={{color: 'lightgrey'}}>
                          <span style={{fontSize: '.8rem'}}> {i18n.t("N/A")} </span>
                        </MenuItem>
                        {currency_codes.map(({code}) => <MenuItem key={code} value={code} sx={{textAlign: 'center'}}>
                          <Currency code={code} sx={{ml: {xs: 1, md: .5}}}/>
                        </MenuItem>)}
                      </Select>
                    </InputAdornment>,
                  }}
                  sx={{m: 0, px: 0, background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*provider-rating*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Provider rating")}
                  options={ratings}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*address*/}
                <TextField
                  type='text'
                  label={i18n.t("Address")}
                  value={formInput.address}
                  onChange={getFilterChangeHandler('address')}
                  size='small'
                  fullWidth
                  sx={{m: 0, background: 'white'}}
                />
              </Box>
            </Grid>
          </>
          <> {/*third row*/}
            {/*<Grid item xs={12} md={1}>*/}
            {/*  <Box sx={{p: 1, py: .5, border: '1px solid grey'}}> image </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={4}>
              <Box sx={{px: .5, py: .45}}>
                {/*transiter*/}
                <AsyncAutocomplete
                  size='small'
                  fireFullOption={true}
                  onChange={getFilterChangeHandler('transiter', false)}
                  selectedOption={formInput.transiter}
                  label={i18n.t("Carrier")}
                  onSearch={async value => {
                    const {result} = await productService.getTransiters(value)
                    return normalizeOptions(result.results || [], 'name')
                  }}
                  sx={{background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{px: .5, py: .45}}>
                {/*announcement-code*/}
                <TextField
                  size='small'
                  label={i18n.t("Announcement code")}
                  value={formInput.announcement_code}
                  onChange={getFilterChangeHandler('announcement_code')}
                  fullWidth
                  sx={{m: 0, background: 'white'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*period*/}
                <Autocomplete
                  size='small'
                  label={i18n.t("Period")}
                  value={formInput.period}
                  onChange={getFilterChangeHandler('period', false)}
                  options={period}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .45}}>
                {/*customs clearance*/}
                <FormControl size='small' fullWidth>
                  <InputLabel id='customs-clearance-form-select'>{i18n.t("Customs Clearance")}</InputLabel>
                  <Select
                    labelId='customs-clearance-form-select'
                    id='customs-clearance-form-select-base'
                    className='bg-white'
                    label={i18n.t("Customs Clearance")}
                    value={formInput.customs_clearance}
                    onChange={getFilterChangeHandler('customs_clearance')}

                  >
                    <MenuItem value={null} sx={{color: 'lightgrey'}}>{i18n.t("None")}</MenuItem>
                    <MenuItem value={true}>{i18n.t("Yes")}</MenuItem>
                    <MenuItem value={false}>{i18n.t("No")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </>
          <> {/*third row*/}
            {/*<Grid item xs={12} md={1}>*/}
            {/*  <Box sx={{p: 0, py: 1, border: '1px solid red'}}>details</Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={12}>
              <Box sx={{px: .5, py: .45}}>
                {/*product*/}
                <ProductCodesDropdown
                  value={formInput.product_codes}
                  onChange={getFilterChangeHandler('product_codes', false)}
                  size='small'
                />
              </Box>
            </Grid>
          </>
          <>
            {/*<Grid item xs={12} md={1}>*/}
            {/*  <Box sx={{p: 0, py: 1, border: '1px solid red'}}>details</Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={9}>
              <Box sx={{px: .5, py: .35}}>
                {/*search input*/}
                <TextField
                  size='small'
                  label={i18n.t("Search")}
                  value={formInput.search}
                  onChange={getFilterChangeHandler('search')}
                  sx={{m: 0, background: 'white'}}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={1}>
              <Box sx={{px: .5, py: .35, height: '100%'}}>
                <Button
                  variant='contained'
                  color='secondary'
                  fullWidth
                  disabled={loading}
                  size='small'
                  sx={{height: '100%', color: 'white'}}
                  onClick={onReset}
                >
                  <RestartAltIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{px: .5, py: .35, height: '100%'}}>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  disabled={loading}
                  type={'submit'}
                  size='small'
                  sx={{height: '100%', color: 'white'}}
                >{i18n.t("Search")}</Button>
              </Box>
            </Grid>
          </>
        </Grid>
      </form>
    </Box>

  )
}