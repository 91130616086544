import {useAuth} from "hooks/useAuth";
import React, {useState} from "react";
import userService from "../../../user/User.service";
import {getFirstErrorString, getInputChangeHandler, showMessage} from "@core/utils";
import i18n from "@core/configs/i18n";
import {Box, Button, MenuItem, Select, Typography} from "@mui/material";
import Loader from "components/Loader";
import {LANGUAGES} from "../../../../constants";

export default function AccountLanguage() {
  const auth = useAuth();

  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState({
    language: {
      value: auth.user.language || 'en',
      error: ''
    }
  })

  const updateLanguageHandler = async (e) => {
    e.preventDefault();
    setSaving(true);
    const {ok, errors} = await userService.updateCredentials({language: formInput.language.value})
    if (ok) {
      showMessage(true, i18n.t("Language updated successfully"))
      auth.setUserInfo({
        ...auth.user,
        language: formInput.language.value,
      })
    }  else {
      if (errors.language) {
        setFormInput(prev => ({
          ...prev,
          language: {
            ...prev.language,
            error: errors.language
          }
        }))
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
    }
    setSaving(false);
  }

  return <Box className='account-language-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Change language")}
    </Typography>
    <form onSubmit={updateLanguageHandler}>
      <Select
        value={formInput.language.value}
        onChange={getInputChangeHandler('language', setFormInput)}
        sx={{background: 'white', my: 2}}
        fullWidth
      >
        {LANGUAGES.map(({code,title}) => <MenuItem key={code} value={code}>{title}</MenuItem>)}
      </Select>

      <Box>
        <Button
          disabled={saving}
          variant='contained'
          onClick={updateLanguageHandler}
          sx={{py: .5, px: 2}}
        >
          {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
          {i18n.t("Update")}
        </Button>
      </Box>
    </form>
  </Box>
}