import React, {useState} from "react";
import {
  getFormObject,
  getInputChangeHandler, getThumbnailOrOriginalImage,
  normalizeOptions,
  setFormErrors,
  valueInListOrDefaultValue
} from "@core/utils";
import {useNavigate} from "react-router-dom";
import i18n from "@core/configs/i18n";
import FormContainer from "components/FormContainer";
import Loader from "components/Loader";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper, Select,
  TextField, Typography
} from "@mui/material";
import Message from "components/Message";
import Autocomplete from "components/Autocomplete";
import AsyncAutocomplete from "components/AsyncAutocomplete";
import productService from "./Product.service";
import FileUploader from "components/file-uploader";
import {useProductSettings} from "hooks/useProductSettings";
import ProductCodesDropdown from "components/product-codes-dropdown";
import Currency from "components/currency";

const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB

function createAutocompleteValue(fromObject, labelKey = 'name', valueKey = 'id') {
  if (!fromObject || typeof fromObject !== 'object') {
    return null;
  }

  return {
    label: fromObject[labelKey],
    value: fromObject[valueKey],
  }
}

export default function SharedProductEditScreen({product, errorMessage, submitHandler: parentSubmitHandler}) {
  const navigate = useNavigate()
  const {locations, live_locations, currency_codes} = useProductSettings();

  if (!product) {
    product = {}
  }

  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState(prev => ({
    name: {value: product.name || "", error: ""},
    images: {value: product.images || [], error: ""},
    price: {value: product.price || "", error: ""},
    year: {value: product.year || "", error: ""},
    buyer: {value: createAutocompleteValue(product.buyer_data), error: ""},
    provider: {value: createAutocompleteValue(product.provider_data), error: ""},
    transiter: {value: createAutocompleteValue(product.transiter_data), error: ""},
    location: {value: product.location || "", error: ""},
    address: {value: product.address || "", error: ""},
    category: {value: product.category || "", error: ""},
    description: {value: product.description || "", error: ""},
    currency_code: {value: product.currency_code || "USD", error: ""},
    /** @type {Array<{code: String, name: String, code_length: number, parent_id: String}>} */
    product_codes: {value: product.product_codes_data || [], error: ""},
    customs_clearance: {
      value: valueInListOrDefaultValue(product.customs_clearance, [true, false], null),
      error: ""
    },
  }))

  const getPreparedFormObject = () => {
    const data = getFormObject(formInput)
    return {
      ...data,
      buyer: data.buyer ? data.buyer.value : "",
      provider: data.provider ? data.provider.value : "",
      transiter: data.transiter ? data.transiter.value : "",
    }
  }

  const handleSubmit = async () => {
    const data = getPreparedFormObject()
    data.product_codes = data.product_codes.map(item => item.code)

    const images = data.images
    const newImages = []
    const existingImages = []
    const imagePositions = []
    for (const [index, image] of Object.entries(images)) {
      if (image.id !== undefined) {
        existingImages.push(image.id);
        imagePositions.push(JSON.stringify({id: image.id, name: null, position: index}));
      } else {
        newImages.push(image);
        imagePositions.push(JSON.stringify({id: null, name: image.name, position: index}))
      }
    }

    data.images = newImages
    data.existing_images = existingImages
    data.image_positions = imagePositions

    setSaving(true)
    const {ok, result, errors} = await parentSubmitHandler(data)
    setSaving(false)
    if (ok) {
      navigate('../products')
    } else {
      setFormErrors(errors, setFormInput)
    }
  }

  const onImagesPositionChange = (fromIndex, toIndex) => {
    const images = [...formInput.images.value];
    let updatedImages = [];
    if (fromIndex < toIndex) {
      updatedImages = [
        ...images.slice(0, fromIndex),
        ...images.slice(fromIndex + 1, toIndex + 1),
        images[fromIndex],
        ...images.slice(toIndex + 1)
      ]
    } else {
      updatedImages = [
        ...images.slice(0, toIndex),
        images[fromIndex],
        ...images.slice(toIndex, fromIndex),
        ...images.slice(fromIndex + 1)
      ]
    }
    getInputChangeHandler('images', setFormInput, false)(updatedImages)
  }

  // noinspection JSValidateTypes
  return (<>
    <Paper sx={{p: {xs: 2, md: 5}, m: {xs: 2, md: 5}}}>
      <Button
        className='capitalize'
        variant='contained'
        onClick={e => navigate('../products')}
        sx={{ml: {xs: 2, md: 5}, mb: {sm: 3, md: 0}}}
      >
        {i18n.t("Go Back")}
      </Button>

      <FormContainer>
        <h1>
          {product.id ? i18n.t("Edit Product") : i18n.t("Create Product")}
        </h1>

        {errorMessage ? (
          <Message variant="danger">{errorMessage}</Message>
        ) : (
          <Box>
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(formInput.name.error)}
                label={i18n.t("Name")}
                value={formInput.name.value}
                helperText={formInput.name.error}
                onChange={getInputChangeHandler('name', setFormInput)}
                fullWidth
                sx={{mb: 2}}
              />

              <AsyncAutocomplete
                onChange={getInputChangeHandler('buyer', setFormInput, false)}
                value={formInput.buyer.value}
                label={i18n.t("Buyer")}
                fireFullOption={true}
                onSearch={async value => {
                  const {result} = await productService.getBuyers(value)
                  return normalizeOptions(result.results || [], 'name')
                }}
                selectedOption={formInput.buyer.value}
                errorMessage={formInput.buyer.error}
                sx={{mb: 2}}
              >
              </AsyncAutocomplete>

              <AsyncAutocomplete
                onChange={getInputChangeHandler('provider', setFormInput, false)}
                value={formInput.provider.value}
                label={i18n.t("Provider")}
                fireFullOption={true}
                onSearch={async value => {
                  const {result} = await productService.getProviders(value)
                  return normalizeOptions(result.results || [], 'name')
                }}
                selectedOption={formInput.provider.value}
                errorMessage={formInput.provider.error}
                sx={{mb: 2}}
              >
              </AsyncAutocomplete>

              <AsyncAutocomplete
                onChange={getInputChangeHandler('transiter', setFormInput, false)}
                value={formInput.transiter.value}
                label={i18n.t("Carrier")}
                fireFullOption={true}
                onSearch={async value => {
                  const {result} = await productService.getTransiters(value)
                  return normalizeOptions(result.results || [], 'name')
                }}
                selectedOption={formInput.transiter.value}
                errorMessage={formInput.transiter.error}
                sx={{mb: 2}}
              >
              </AsyncAutocomplete>

              <Box sx={{my: 2}}>
                <FileUploader
                  files={formInput.images.value}
                  getImageSrc={(image) => getThumbnailOrOriginalImage(image)}
                  onUpdate={getInputChangeHandler('images', setFormInput, false)}
                  error={formInput.images.error}
                  onPositionChange={onImagesPositionChange}
                  isPositionChangeEnabled={true}
                  maxSize={MAX_IMAGE_SIZE}
                />
              </Box>

              <Box sx={{display: 'flex'}}>
                <TextField
                  error={Boolean(formInput.price.error)}
                  label={i18n.t("Price")}
                  value={formInput.price.value}
                  type={'number'}
                  helperText={formInput.price.error}
                  onChange={getInputChangeHandler('price', setFormInput)}
                  fullWidth
                  sx={{mb: 2}}
                />

                <FormControl sx={{ml: 1, minWidth: '70px'}}>
                  <Select
                    value={formInput.currency_code.value}
                    onChange={getInputChangeHandler('currency_code', setFormInput)}
                    error={Boolean(formInput.currency_code.error)}
                  >
                    {currency_codes.map(({code}) => (
                      <MenuItem key={code} value={code}> <Currency code={code}/> </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TextField
                error={Boolean(formInput.year.error)}
                label={i18n.t("Year")}
                value={formInput.year.value}
                type={'number'}
                helperText={formInput.year.error}
                onChange={getInputChangeHandler('year', setFormInput)}
                fullWidth
                sx={{mb: 2}}
              />

              <Box sx={{mb: 2}}>
                <TextField
                  error={Boolean(formInput.category.error)}
                  label={i18n.t("Category")}
                  value={formInput.category.value}
                  type={'text'}
                  helperText={formInput.category.error}
                  onChange={getInputChangeHandler('category', setFormInput)}
                  fullWidth
                />
              </Box>

              <Box sx={{mb: 2}}>
                <ProductCodesDropdown
                  value={formInput.product_codes.value}
                  onChange={getInputChangeHandler('product_codes', setFormInput, false)}
                  errorMessage={formInput.product_codes.error}
                />
              </Box>

              <Box sx={{mb: 2}}>
                <Autocomplete
                  options={locations.map(e => ({label: e.name, code: e.id}))}
                  label={i18n.t("Location")}
                  value={formInput.location.value}
                  onChange={(value) => getInputChangeHandler('location', setFormInput)(value, false)}
                  errorMessage={formInput.location.error}
                />
              </Box>

              <Box sx={{mb: 2}}>
                <TextField
                  error={Boolean(formInput.address.error)}
                  label={i18n.t("Address")}
                  value={formInput.address.value}
                  type={'text'}
                  helperText={formInput.address.error}
                  onChange={getInputChangeHandler('address', setFormInput)}
                  fullWidth
                />
              </Box>

              <FormControl fullWidth sx={{mb: 2}}>
                <InputLabel id="products-page-customs-clearance-input">{i18n.t("Customs clearance")}</InputLabel>
                <Select
                  labelId="products-page-customs-clearance-input"
                  id="products-page-customs-clearance-input-select"
                  value={formInput.customs_clearance.value}
                  onChange={getInputChangeHandler('customs_clearance', setFormInput)}
                  input={<OutlinedInput label="customs_clearance"/>}>
                  <MenuItem value={null} sx={{color: 'lightgrey'}}>{i18n.t("None")}</MenuItem>
                  <MenuItem key={'customs-clearance-true'} value={true}>{i18n.t("Yes")}</MenuItem>
                  <MenuItem key={'customs-clearance-false'} value={false}>{i18n.t("No")}</MenuItem>
                </Select>
              </FormControl>

              <TextField
                error={Boolean(formInput.description.error)}
                label={i18n.t("Description")}
                value={formInput.description.value}
                helperText={formInput.description.error}
                onChange={getInputChangeHandler('description', setFormInput)}
                multiline
                rows={5}
                fullWidth
                sx={{mb: 2}}
              />

              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={e => {
                  e.preventDefault()
                  handleSubmit()
                }}
                disabled={saving}
                sx={{mt: 2}}>
                {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
                {product.id ? i18n.t("Update") : i18n.t("Create")}
              </Button>
            </form>
          </Box>
        )}
      </FormContainer>
    </Paper>
  </>)
}