import {Box, Button, CircularProgress, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import React from "react";
import Modal from "components/modal";
import {getFirstErrorString, showMessage} from "../../../@core/utils";
import userService from "../../user/User.service";
import {useAuth} from "../../../hooks/useAuth";

export default function AccountDeactivate() {
  // TODO determine if deactivation should delete user posts/products/etc...
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const [deactivatingAccount, setDeactivatingAccount] = React.useState(false);

  const auth = useAuth();

  const deactivationHandler = async () => {
    if (!showConfirmationDialog) {
      setShowConfirmationDialog(true);
      return;
    }

    setShowConfirmationDialog(false);
    setDeactivatingAccount(true);
    const {ok, result, errors} = await userService.deactivateAccount();
    if (ok) {
      showMessage(true, i18n.t("Account deactivated successfully"));
      auth.logout();
    } else {
      showMessage(false, getFirstErrorString(errors));
    }
    setDeactivatingAccount(false);
  }

  return <Box className='account-deactivate-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Deactivate your account")}
    </Typography>
    <Typography variant='body2' color='secondary' sx={{fontSize: '.8rem'}}>
      {
        i18n.t(
          "Deactivating your account will disable your profile " +
          "and others will no longer be able to search your profile."
        )}
    </Typography>
    <Typography variant='h6' sx={{fontWeight: 'bolder', mt: 4}}>
      {i18n.t("Things you should note")}
    </Typography>
    <Typography variant='body2' color='secondary' sx={{fontSize: '.8rem'}}>
      {
        i18n.t(
          "You'll still be able to activate your account within 30 days " +
          "by trying to login to your account with your credentials. After 30 days of deactivation, " +
          "Your account will become fully disabled, which means you won't be able to activate it again."
        )}
    </Typography>

    <Button
      color='error'
      fullWidth
      onClick={deactivationHandler}
      disabled={deactivatingAccount}
      sx={{mt: 2}}>
      {deactivatingAccount && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
      {i18n.t("Deactivate")}
    </Button>

    <Modal
      open={showConfirmationDialog}
      title={i18n.t("Confirmation")}
      onSubmit={deactivationHandler}
      onClose={() => setShowConfirmationDialog(false)}
    >
      <Typography>
        {i18n.t("Are you sure, you want to deactivate your account ?")}
      </Typography>
    </Modal>
  </Box>
}