import SharedProductEditScreen from "./SharedProductEditScreen";
import userService from "../user/User.service";

export default function ProductCreateScreen() {
  const submitHandler = (productData) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(productData)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(key, item)
        }
      } else {
        formData.append(key, value)
      }
    }
    return userService.createProduct(formData)
  };

  return (
    <SharedProductEditScreen
      product={{}}
      submitHandler={submitHandler}
    />
  );
}