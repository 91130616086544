import {Box, Button, TextField, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {getFirstErrorString, getInputChangeHandler, showMessage} from "@core/utils";
import React, {useState} from "react";
import {useAuth} from "hooks/useAuth";
import Loader from "components/Loader";
import userService from "../../../user/User.service";


export default function AccountEmail() {
  const auth = useAuth();

  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState({
    email: {value: auth.user.email, error: ''}
  })

  const updateEmailHandler = async (e) => {
    e.preventDefault();
    setSaving(true);
    const {ok, errors} = await userService.updateCredentials({email: formInput.email.value.trim()})
    if (ok) {
      showMessage(true, i18n.t("Email updated successfully"))
      auth.setUserInfo({...auth.user, email: formInput.email.value.trim()})
    }  else {
      if (errors.email) {
        setFormInput(prev => ({
          ...prev,
          email: {
            ...prev.email,
            error: errors.email
          }
        }))
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
    }
    setSaving(false);
  }

  return <Box className='account-email-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Change email")}
    </Typography>
    <form onSubmit={updateEmailHandler}>
      <TextField
        error={Boolean(formInput.email.error)}
        label={i18n.t("Email")}
        name='email'
        value={formInput.email.value}
        helperText={formInput.email.error}
        onChange={getInputChangeHandler('email', setFormInput)}
        fullWidth
        sx={{my: 3}}
      />
      <Button
        disabled={saving}
        variant='contained'
        onClick={updateEmailHandler}
        sx={{py: .5, px: 2}}
      >
        {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
        {i18n.t("Update")}
      </Button>
    </form>

  </Box>
}