import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import SettingsIcon from '@mui/icons-material/Settings';
import {SITE_TITLE} from "../config/main";

import {
  Autocomplete,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField
} from "@mui/material";
import i18n from "@core/configs/i18n";
import {useChat} from "../hooks/useChat";
import BasicMenu from "./menu";
import UserImage from "./user-image";
import {getUserThumbnailOriginalOrDefaultImage} from "@core/utils";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import {LANGUAGES} from "constants.js";
import {useEffect} from "react";
import {ChatList} from "../screens/chat";

const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3), width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: 'inherit', '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0), // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function ChatDialogs({color = 'white'}) {
  const chat = useChat()
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0)

  useEffect(() => {
    const unreadMessagesCount = chat.dialogs.reduce((fin, cur) => {
      return Number(cur.unread || 0) > 0 ? fin + 1 : fin
    }, 0)
    setUnreadMessagesCount(unreadMessagesCount)
  }, [chat.dialogs])

  return <Box>
    <BasicMenu
      title={<IconButton size={'large'} sx={{ml: 1, mr: 0}}>
        {unreadMessagesCount > 0 ? (<Badge badgeContent={unreadMessagesCount} color="error">
          <MailIcon
            fontSize='medium'
            color='black'
            sx={{color}}
          />
        </Badge>) : (<MailIcon
          fontSize='medium'
          color='black'
          sx={{color: color}}
        />)}
      </IconButton>}
      maxHeight={'500px'}
      width={'400px'}
      onScrollToBottom={chat.fetchMoreDialogs}
    >
      {[<ChatList key={`chat-list-0`} />]}
    </BasicMenu>
  </Box>
}

function findLanguage(byCode, defaultLanguge) {
  return LANGUAGES.find(lang => lang.code === byCode) || defaultLanguge
}

function MenuDrawer() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [locale, setLocale] = React.useState(
    findLanguage(auth.user?.language, LANGUAGES[0])
  )


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    auth.logout()
    navigate('/login')
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (<Menu
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom', horizontal: 'right',
    }}
    id={menuId}
    keepMounted
    transformOrigin={{
      vertical: 'bottom', horizontal: 'right',
    }}
    open={isMenuOpen}
    onClose={handleMenuClose}
    sx={{mt: 5, px: 5}}
  >
    <MenuItem
      onClick={e => {
        handleMenuClose()
        navigate(`/user/${auth.user.id}`)
      }}
    >{i18n.t("Profile")}</MenuItem>
    <MenuItem onClick={e => {
      handleMenuClose()
      navigate('/me/products')
    }}>{i18n.t("Products")}
    </MenuItem>
    <MenuItem onClick={e => {
      handleMenuClose()
      navigate('/me/account-settings')
    }}>{i18n.t("Settings")}
    </MenuItem>
    <MenuItem onClick={e => {
      handleMenuClose()
      logoutHandler()
    }}>{i18n.t("Logout")}
    </MenuItem>
  </Menu>);

  return <>
    <Autocomplete
      size='small'
      className='locale-autocomplete'
      options={LANGUAGES}
      getOptionLabel={(obj) => obj.title}
      style={{width: '6.5rem', color: 'white !important'}}
      value={locale}
      disableClearable
      onChange={(event, newValue) => {
        setLocale(newValue)
      }}
      renderInput={(params) => (<TextField {...params} className='locale-autocomplete-input' fullWidth/>)}
      sx={{mt: .5, mx: 1}}
    />
    <Button variant='contained'
            color='secondary'
            size='small'
            sx={{height: '2.5rem', mt: .5}}
    >
      {i18n.t("Subscribe")}
    </Button>

    {auth.user ? (<>
      <ChatDialogs/>
      <IconButton
        size="medium"
        edge="end"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <img src={getUserThumbnailOriginalOrDefaultImage(auth.user, 'image_data')}
             width='35px'
             alt='profile image'
        />
      </IconButton>
      {renderMenu}
    </>) : (<>
      <Button
        variant='contained'
        color='error'
        size='small'
        onClick={e => navigate('/login')}
        sx={{height: '2.5rem', mt: .5, mx: 2, color: 'white'}}
      >
        {i18n.t("Login")}
      </Button>
    </>)}
  </>
}

function MobileMenuDrawer() {
  const auth = useAuth();
  const chat = useChat();
  const navigate = useNavigate();

  const [locale, setLocale] = React.useState(
    findLanguage(auth.user?.language, LANGUAGES[0])
  )
  const [isMobileMenuOpened, setIsMobileMenuOpened] = React.useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0)

  useEffect(() => {
    const unreadMessagesCount = chat.dialogs.reduce((fin, cur) => {
      return Number(cur.unread || 0) > 0 ? fin + 1 : fin
    }, 0)
    setUnreadMessagesCount(unreadMessagesCount)
  }, [chat.dialogs])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsMobileMenuOpened(open);
  };

  const logoutHandler = () => {
    auth.logout()
    navigate('/login')
  };

  const goToChatsHandler = () => {
    navigate('/chats')
  }

  const list = (anchor) => (<Box
    sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300}}
    role="presentation"
    onClick={toggleDrawer(false)}
    onKeyDown={toggleDrawer(false)}
  >
    <List>
      {auth.user ? (<>
        <ListItem disablePadding sx={{mt: 5}}>
          <ListItemButton>
            <Box sx={{display: 'flex'}}>
              <UserImage src={getUserThumbnailOriginalOrDefaultImage(auth.user, 'image_data')} size={'35px'}/>
              <Typography variant={'h6'} sx={{mt: .25, mx: 2}}>
                {auth.user.name}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={e => navigate(`/user/${auth.user.id}`)}>
            <ListItemIcon>
              <AccountCircleIcon/>
            </ListItemIcon>
            <ListItemText primary={i18n.t("Profile")}></ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={e => navigate('/me/products')}>
            <ListItemIcon>
              <ViewListIcon/>
            </ListItemIcon>
            <ListItemText primary={i18n.t("Products")}></ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={e => navigate('/me/account-settings')}>
            <ListItemIcon>
              <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary={i18n.t("Settings")}></ListItemText>
          </ListItemButton>
        </ListItem>
      </>) : (<ListItem disablePadding sx={{mt: 5}}>
        <ListItemButton>
          <Button variant='contained'
                  color='error'
                  size='small'
                  className=''
                  onClick={e => toggleDrawer(false) && navigate('/login')}
                  sx={{height: '2.5rem'}}
                  fullWidth
          >
            {i18n.t("Login")}
          </Button>
        </ListItemButton>
      </ListItem>)}
      {!auth.user && (<ListItem disablePadding>
        <ListItemButton>
          <Button variant='contained'
                  color='secondary'
                  size='small'
                  className=''
                  onClick={e => toggleDrawer(false) && navigate('/register')}
                  sx={{height: '2.5rem'}}
                  fullWidth
          >
            {i18n.t("Register")}
          </Button>
        </ListItemButton>
      </ListItem>)}
    </List>
    <Divider/>
    <List>
      <ListItem disablePadding>
        <ListItemButton>
          <Button variant='contained'
                  color='primary'
                  size='medium'
                  fullWidth
          >
            {i18n.t("Subscribe")}
          </Button>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding onClick={e => e.stopPropagation()}>
        <ListItemButton>
          <Autocomplete
            size='small'
            className='locale-autocomplete'
            options={LANGUAGES}
            getOptionLabel={(obj) => obj.title}
            style={{color: 'white !important'}}
            value={locale}
            disableClearable
            onChange={(event, newValue) => {
              setLocale(newValue)
            }}
            renderInput={(params) => (<TextField {...params} fullWidth/>)}
            fullWidth
          />
        </ListItemButton>
      </ListItem>
      {auth.user && (<ListItem disablePadding>
        <ListItemButton onClick={e => goToChatsHandler()}>
          <ListItemIcon>
            <MailIcon
              fontSize='medium'
            />
          </ListItemIcon>
          <ListItemText>
            <Box sx={{display: 'flex'}}>
              {i18n.t("My chats")}
              {unreadMessagesCount > 0 && (
                <Box className='unread-messages-box' sx={{mx: .75, width: '1.5rem', height: '1.5rem'}}>
                  <span>{unreadMessagesCount}</span>
                </Box>)}
            </Box>
          </ListItemText>
        </ListItemButton>

      </ListItem>)}
      {auth.user && (<ListItem disablePadding>
        <ListItemButton onClick={e => logoutHandler()}>
          <ListItemIcon>
            <LogoutIcon/>
          </ListItemIcon>
          <ListItemText primary={i18n.t('Logout')}/>
        </ListItemButton>
      </ListItem>)}
    </List>
  </Box>);

  return (<>
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={isMobileMenuOpened}
          onClose={toggleDrawer(false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      sx={{mr: 2}}
      onClick={toggleDrawer(true)}
    >
      <MenuIcon/>
    </IconButton>
  </>);
}

export default function Header() {
  const navigate = useNavigate()
  return (<Box sx={{flexGrow: 1}}>
    <AppBar
      className='site-main-background-color'
      position="static"
      sx={{px: {sm: 2, md: 4}}}>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          onClick={e => navigate('/')}
          sx={{cursor: 'pointer'}}
        >
          {SITE_TITLE}
        </Typography>
        {/*<Search sx={{display: {xs: 'none', sm: 'block'}}}>*/}
        {/*  <SearchIconWrapper>*/}
        {/*    <SearchIcon/>*/}
        {/*  </SearchIconWrapper>*/}
        {/*  <StyledInputBase*/}
        {/*    placeholder={i18n.t("Search…")}*/}
        {/*    inputProps={{'aria-label': 'search'}}*/}
        {/*  />*/}
        {/*</Search>*/}
        <Box sx={{flexGrow: 1}}/>
        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
          <MenuDrawer/>
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
          <MobileMenuDrawer/>
        </Box>
      </Toolbar>
    </AppBar>
  </Box>);
}