import i18n from "@core/configs/i18n";
import {getQueryParamFromUrl} from "../../@core/utils";

export function MessageModel(message) {
  return {
    id: String(message.id),
    text: message.text,
    type: message.type,
    is_call: message.is_call,
    sent: new Date(Number(message.sent) * 1000),
    edited: new Date(Number(message.edited) * 1000),
    date: new Date(Number(message.edited) * 1000),
    read: Boolean(message.read),
    sender: message.sender,
    recipient: message.recipient,
    sender_name: message.sender_name,
    out: Boolean(message.out),
    is_deleted: Boolean(message.is_deleted),
    reply_to: message.reply_to,
    emoji_id: message.emoji_id,
  }
}

export function MessagesResponse(message) {
  const {next, results} = message
  return {
    data: results.map(m => MessageModel(m)),
    cursor: next ? getQueryParamFromUrl(next, 'cursor') : null,
    hasMore: Boolean(next),
  }
}

export function DialogModel(dialog) {
  return {
    id: String(dialog.id),
    created: new Date(Number(dialog.created) * 1000),
    modified: new Date(Number(dialog.modified) * 1000),
    other_user_id: dialog.other_user_id,
    unread_count: Number(dialog.unread_count),
    name: dialog.name,
    image_data: dialog.image_data,
    last_message: dialog.last_message ? MessageModel(dialog.last_message) : null,
  }
}

export function DialogsResponse(dialogs) {
  const {next, previous, results} = dialogs
  const cursor = next ? getQueryParamFromUrl(next, 'cursor') : null
  return {
    cursor,
    data: results.map(m => DialogModel(m))
  }
}

export function MessageTypeNewUnreadCount(message) {
  return {
    sender: String(message.sender),
    unread_count: Number(message.unread_count)
  }
}

export function MessageTypeMessageRead(message) {
  return {
    message_id: String(message.message_id),
    sender: String(message.sender),
    receiver: String(message.receiver),
  }
}

export function MessageTypeUserMessage(message) {
  return {
    random_id: String(message.random_id),
    text: message.text,
    image_data: message.image_data,
    sender: String(message.sender),
    receiver: String(message.receiver),
    sender_name: message.sender_name,
    reply_to: message.reply_to,
  }
}

export function MessageTypeTextMessage(message) {
  return MessageTypeUserMessage(message)
}

export function MessageTypeCallMessage(message) {
  return MessageTypeUserMessage(message)
}

export function MessageTypeReplyMessage(message) {
  return MessageTypeUserMessage(message)
}

export function MessageTypeReaction(message) {
  return {
    user_pk: String(message.user_pk),
    message_id: String(message.message_id),
    emoji_id: String(message.emoji_id),
  }
}

export function MessageTypeReactionRemove(message) {
  return {
    user_pk: String(message.user_pk),
    message_id: String(message.message_id),
  }
}

export function MessageTypeMessageIdCreated(message) {
  return {
    random_id: String(message.random_id),
    db_id: String(message.db_id),
  }
}

export function MessageTypeErrorOccurred(message) {
  if (typeof message === "object" && Array.isArray(message.error) && message.error.length === 2) {
    return {
      error: {
        type: Number(message.error[0]),
        message: message.error[1]
      }
    }
  }
  const errorMessage = String(i18n.t("Something went wrong. Please, try again"))
  return {
    error: {
      type: Number(ErrorTypes.GeneralError),
      message: errorMessage
    }
  }
}


export function MessageTypeIsTyping(message) {
  return {
    user_pk: String(message.user_pk),
    typing: Boolean(message.typing),
  }
}

export function MessageTypeUserOnlineStatus(message) {
  return {
    user_pk: String(message.user_pk),
    online: Boolean(message.online),
  }
}

export function MessageDeleted(message) {
  return {
    message_id: String(message.message_id),
    user_pk: String(message.user_pk),
  }
}

export function GenericUserPKMessage(message) {
  return {
    user_pk: String(message.user_pk),
  }
}

export function msgTypeEncoder(message, data) {
  data["msg_type"] = Number(message)
  return JSON.stringify(data)
}

export function MessageTypesDecoder(message) {
  return Number(message.msg_type)
}
export const MessageTypes = {
  WentOnline: 1,
  WentOffline: 2,
  TextMessage: 3,
  FileMessage: 4,
  IsTyping: 5,
  MessageRead: 6,
  ErrorOccurred: 7,
  MessageIdCreated: 8,
  NewUnreadCount: 9,
  TypingStopped: 10,
  CallMessage: 11,
  CallMessageOffer: 12,
  CallMessageAnswer: 13,
  CallMessageCandidate: 14,
  CallMessageReject: 15,
  CallMessageAccept: 16,
  CallMessageOfferStart: 17,
  MarkAllMessagesRead: 18,
  UserOnlineStatus: 19,
  DeleteMessage: 20,
  ReplyToMessage: 21,
  MessageReaction: 22,
  MessageReactionRemove: 23,
}

export const UserMessageTypes = {
  Text: 'text',
  File: 'file',
  Call: 'call',
  Reply: 'reply',
}

export const MessageBoxPosition = {
  Left: 'left',
  Right: 'right',
}

export const MessageBoxStatus = {
  Waiting: 'waiting',
  Sent: 'sent',
  Received: 'received',
  Read: 'read',
}

export const ErrorTypes = {
  MessageParsingError: 1,
  TextMessageInvalid: 2,
  InvalidMessageReadId: 3,
  InvalidUserPk: 4,
  InvalidRandomId: 5,
  FileMessageInvalid: 6,
  FileDoesNotExist: 7,
  GeneralError: 8,
}
