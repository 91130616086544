import React, {useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, Divider, IconButton, TextField, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import HoverableIcon from "../../hoverable-icon";
import SendIcon from '@mui/icons-material/Send';
import CallIcon from '@mui/icons-material/Call';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import VideocamIcon from '@mui/icons-material/Videocam';

import "./index.css"
import UserImage from "../../user-image";
import i18n from "@core/configs/i18n";
import eventBus from "@core/event-bus";
import {MessageTypes} from "../../../screens/chat/types";
import ChatMessages from "../chat-messages";
import {useNavigate} from "react-router-dom";
import Dot from "../../dot";
import {getUserThumbnailOriginalOrDefaultImage} from "../../../@core/utils";

const SEND_STOP_TYPING_TIMEOUT = 8 * 1000; // 15 seconds

const defaultOptions = {}

function scrollIntoElement(element) {
  if (element) {
    element.scrollIntoView({
      block: 'end',
    })
  }
}


const isMobile = window.innerWidth < 768;

export default function ChatBox(
  {
    dialog,
    title,
    messages = [],
    ungroupedMessages = [],
    options = {},
    onClose,
    onScrollToTop
  }) {
  const chatInputBoxRef = useRef()
  const chatBoxMessagesContainer = useRef()
  const typingRef = useRef()
  const [isMinimized, setIsMinimized] = useState(false)
  const [message, setMessage] = useState('')
  const [oldUngroupedMessages, setOldUngroupedMessages] = useState([])
  const chatOptions = {...defaultOptions, ...options}
  const [chatMessagesScrollHeight, setChatMessagesScrollHeight] = useState(null)
  const [replyingToMessage, setReplyingToMessage] = useState(null)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const shiftIsPressed = useRef();
  const scrollNearToBottomRef = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (chatInputBoxRef.current) {
      scrollIntoElement(chatInputBoxRef.current)
    }
  }, [])

  useEffect(() => {
    if (chatInputBoxRef.current) {
      // scroll to the bottom when we have new message and maintain the scroll position
      // when messages are added to the beginning of the list
      if (ungroupedMessages.length > oldUngroupedMessages.length) {
        if (!oldUngroupedMessages.length) {
          scrollIntoElement(chatInputBoxRef.current)
        } else {
          if (ungroupedMessages[0].data.message_id !== oldUngroupedMessages[0].data.message_id) {
            // items were added to the beginning of the list
            chatBoxMessagesContainer.current.scrollTop =
              chatBoxMessagesContainer.current.scrollHeight - chatMessagesScrollHeight;
            setChatMessagesScrollHeight(null);
          } else {
            // scroll to the bottom if we are near to the bottom
            if (scrollNearToBottomRef.current) {
              scrollIntoElement(chatInputBoxRef.current)
            }
          }
        }
      }
    }
    setOldUngroupedMessages(ungroupedMessages)
  }, [ungroupedMessages])

  useEffect(() => {
    const attachedEvent = eventBus.on('reply-to', ({message}) => {
      // TODO find a better way to handle this
      // other selected dialogs will also catch this event
      if (message.data.dialog_id === dialog.id) {
        setReplyingToMessage(message)
      }
    })
    return () => {
      // remove event for only this component
      eventBus.remove('reply-to', attachedEvent)
    }
  }, [replyingToMessage, setReplyingToMessage, dialog])

  function sendMessage() {
    if (!message.trim()) {
      return;
    }
    eventBus.dispatch(
      'send-message',
      {
        dialog,
        message,
        reply_to: replyingToMessage,
        type: replyingToMessage ? MessageTypes.ReplyToMessage : MessageTypes.TextMessage
      })
    eventBus.dispatch('stop-typing', {dialog})
    setMessage('')
    setReplyingToMessage(null)
    if (typingRef.current) {
      clearTimeout(typingRef.current)
      typingRef.current = null
    }
  }

  function closeChat(e) {
    e.stopPropagation()
    onClose()
  }

  function handleOnCall(e) {
    e.preventDefault()
    eventBus.dispatch('send-message', {dialog, message, type: MessageTypes.CallMessage})
  }

  function scrollToTopHandler() {
    setChatMessagesScrollHeight(chatBoxMessagesContainer.current.scrollHeight)
    onScrollToTop()
  }

  function typingStartHandler() {
    if (!typingRef.current) {
      eventBus.dispatch('start-typing', {dialog})
    } else {
      clearTimeout(typingRef.current)
    }
    typingRef.current = setTimeout(() => {
      eventBus.dispatch('stop-typing', {dialog})
      typingRef.current = null
    }, SEND_STOP_TYPING_TIMEOUT);
  }

  function scrollHandler(e) {
    if (e.target.scrollTop === 0) {
      scrollToTopHandler()
    }

    if (scrollNearToBottomRef.current !== undefined && chatBoxMessagesContainer.current !== undefined) {
      const {scrollTop, scrollHeight, clientHeight} = chatBoxMessagesContainer.current;
      scrollNearToBottomRef.current = scrollHeight - scrollTop - clientHeight < 100;
    }
  }

  return <Box className='chat-component shadow-1' sx={{...chatOptions}}>
    <Box className='chat-header' onClick={e => isMinimized && setIsMinimized(false)}>
      <Box className='chat-header-image' onClick={() => navigate(`/user/${dialog.id}/`)}>
        <UserImage src={getUserThumbnailOriginalOrDefaultImage(dialog, 'image_data')}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 40px)'}}>
        <Box sx={{display: 'flex', textAlign: 'left', overflow: 'hidden'}}>
          <Box sx={{ml: 1, overflow: 'hidden'}}>
            <Typography
              variant='body2'
              sx={{mt: -.25, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {title}
            </Typography>
            <Box sx={{display: 'flex'}}>
              {dialog.typing ? (
                <Typography variant='body2' color='secondary' sx={{fontSize: '.75rem'}}>
                  {i18n.t("Typing")}...
                </Typography>
              ) : (
                <>
                  <Dot size='.25rem' margin='.35rem 0' color={dialog.online ? '#346f34' : 'red'}/>
                  <Typography variant='body2'
                              sx={{ml: .5, fontSize: '.75rem', color: dialog.online ? '#346f34' : 'red'}}>
                    {dialog.online ? "Online" : "Offline"}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          {dialog.unread > 0 && (
            <Box className='unread-messages-box' sx={{mx: .75}}>
              <span>{dialog.unread}</span>
            </Box>
          )}
        </Box>
        <Box sx={{display: 'flex', minWidth: isMobile ? '70px' : '110px'}}>
          <HoverableIcon
            onClick={e => handleOnCall(e)}
            noborder="true"
            hovercolor='#eaecec'
            sx={{marginBottom: '.35rem'}}
          >
            <VideocamIcon sx={{mt: .5}}/>
          </HoverableIcon>
          {!isMobile && (
            <HoverableIcon
              onClick={() => setIsMinimized(true)}
              noborder="true"
              hovercolor='#eaecec'
              sx={{marginBottom: '.35rem'}}
            >
              <RemoveIcon sx={{mt: .5}}/>
            </HoverableIcon>
          )}
          <HoverableIcon
            onClick={e => closeChat(e)}
            noborder="true"
            hovercolor='#eaecec'
            sx={{marginBottom: '.35rem'}}
          >
            <CloseIcon sx={{mt: .5}}/>
          </HoverableIcon>
        </Box>
      </Box>
    </Box>
    <Box className='chat-box-body' sx={{display: isMinimized ? 'none !important' : null}}>
      <Box
        className='chat-box-messages'
        onScroll={e => scrollHandler(e)}
        ref={chatBoxMessagesContainer}
        sx={{overflowX: 'hidden'}}
      >
        {dialog.loading && messages.length > 0 && (
          <Box sx={{textAlign: 'center'}}>
            <CircularProgress color="primary" size='1.5rem' sx={{mr: 1}}/>
          </Box>
        )}
        <ChatMessages messages={messages} />
        {messages.length === 0 ? (
          <>
            {dialog.loading ? (
              <Box className='no-messages'>
                <CircularProgress color="primary" size='2.5rem' sx={{mr: 1}}/>
              </Box>
            ) : (
              <Box className='no-messages'>
                <Typography variant='body'>
                  {i18n.t("There are no messages yet")}
                </Typography>
              </Box>
            )}
          </>
        ) : null}
        <Box ref={chatInputBoxRef} sx={{position: "relative", bottom: '-10rem'}}></Box>
      </Box>
      <Box>
        {replyingToMessage && (
          <Box sx={{my: 2, mx: 2}}>
            <Divider/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant='body2' sx={{mt: 1}}>
                {i18n.t("Replying to")}
              </Typography>
              <IconButton size='small' onClick={() => setReplyingToMessage(null)} sx={{mr: 1, mt: .5}}>
                <CloseIcon sx={{fontSize: '1.2rem'}}/>
              </IconButton>
            </Box>
            <Typography variant='body2' color='secondary' sx={{fontSize: '.8rem', mt: 1, whiteSpace: 'pre-wrap'}}>
              {replyingToMessage.text}
            </Typography>
          </Box>
        )}
        <Box className='chat-box-footer' sx={{display: 'flex'}}>
          <Box className='chat-box-text-input' sx={{flexGrow: 1}}>
            <form
              onSubmit={e => {
                e.preventDefault()
                sendMessage()
              }}
              style={{width: '100%'}}>
              <TextField
                className='chat-box-text-input-field'
                placeholder={i18n.t('Type here')}
                value={message}
                onChange={e => setMessage(e.target.value)}
                size='small'
                fullWidth
                inputProps={{
                  autoComplete: "off",
                }}
                multiline
                maxRows={5}
                minRows={1}
                onKeyDown={e => {
                  typingStartHandler(e)

                  if (e.key === "Enter") {
                    if (shiftIsPressed.current) {
                      // continue
                    } else {
                      e.preventDefault()
                      sendMessage()
                    }
                  } else if (e.key === "Shift") {
                    shiftIsPressed.current = true;
                  }
                }}
                onKeyUp={e => {
                  if (e.key === "Shift") {
                    shiftIsPressed.current = false;
                  }
                }}
              />
              <input type='submit' style={{display: 'none'}}/>
            </form>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Box sx={{flexGrow: 1}}></Box>
              <Box sx={{flexGrow: 0}}>
                <IconButton onClick={() => sendMessage()}>
                  <SendIcon className='send-message-icon' fontSize='small'/>
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{mr: 1, mb: 1.25, ml: 0, display: 'flex', flexDirection: 'column'}}>
            <Box sx={{flexGrow: 1}}></Box>
            <Box sx={{flexGrow: 0}}>
              <IconButton size='small' onClick={e => setShowEmojiPicker(true) & e.stopPropagation()}>
                <SentimentSatisfiedAltIcon size='small'/>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box sx={{position: 'absolute', bottom: '90px', zIndex: 105}}>
      {showEmojiPicker && (
        <Picker
          data={data}
          previewPosition='none'
          navPosition='none'
          onEmojiSelect={emoji => setMessage(message + emoji.native)}
          onClickOutside={e => setShowEmojiPicker(false)}
        />
      )}
    </Box>
  </Box>
}