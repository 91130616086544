import {Outlet} from 'react-router-dom'
import Header from "components/Header";
import {Box} from "@mui/material";
import {Toaster} from "react-hot-toast";
import {useAuth} from "../hooks/useAuth";
import ChatAndVideo from "components/chat/chat-and-video";
import eventBus from "../@core/event-bus";
import {EVENT_GLOBAL_SCROLL_TO_BOTTOM} from "../constants";
import {globalScrollableElement} from "shared/refs";

export default function DefaultLayout() {
  const auth = useAuth()

  const onScroll = ev => {
    const threshold = 10; // Adjust this value as needed
    if (ev.target.scrollTop > threshold && (ev.target.clientHeight + ev.target.scrollTop + 5) >= ev.target.scrollHeight) {
      // make sure we don't fire this event if `scrollTop` is 0 and `clientHeight` = `scrollHeight`
      eventBus.dispatch(EVENT_GLOBAL_SCROLL_TO_BOTTOM)
    }
  }

  return <>
    <Header/>
    <Box id='content-wrapper'
         className='use-max-height'
         onScroll={onScroll}
         ref={globalScrollableElement}>
      <Outlet/>
    </Box>
    {auth.user && <ChatAndVideo/>}
    <Toaster position="top-right" toastOptions={{className: 'react-hot-toast'}}/>
  </>
}