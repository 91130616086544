import {Box, Button, CircularProgress, TextField} from "@mui/material";
import i18n from "@core/configs/i18n";
import React, {useState} from "react";
import {getUserThumbnailOriginalOrDefaultImage} from "@core/utils";
import {useAuth} from "hooks/useAuth";


export default function Comment({submitHandler}) {
  const auth = useAuth();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const postCommentHandler = async () => {
    if (!String(value).trim().length) {
      return;
    }

    setLoading(true);
    const ok = await submitHandler(value);
    if (ok) {
      setValue('');
    }

    setLoading(false);
  }

  return <Box className='comment-component'>
    <Box sx={{display: 'flex'}}>
      <Box className='comment-component-image'>
        <img src={getUserThumbnailOriginalOrDefaultImage(auth.user, 'image_data')}
             alt='profile-image'
             style={{width: '3rem'}}
        />
      </Box>
      <Box className='comment-component-input' sx={{width: '100%', pl: 2}}>
        <TextField
          value={value}
          onChange={e => setValue(e.target.value)}
          fullWidth
          multiline
          rows={2}
          placeholder={i18n.t("Write a comment")}
        />
      </Box>
    </Box>
    <Box sx={{textAlign: 'right', mt: 1}}>
      <Button
        variant='contained'
        size='small'
        disabled={loading}
        onClick={e => postCommentHandler()}>
        {loading && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
        {i18n.t("Comment")}
      </Button>
    </Box>
  </Box>
}