import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import UserInfo from "../user-info";
import i18n from "../../@core/configs/i18n";
import ImagesCarousel from "../images-carousel";
import {getThumbnailOrOriginalImage, valueOrNA} from "../../@core/utils";
import React from "react";
import Currency from "../currency";

export function renderPostMainContent(feed, navigate) {
  const {post_data: post} = feed;
  return <>
    <Box className='post-content' sx={{overflow: 'hidden'}}>
      <Box dangerouslySetInnerHTML={{
        __html: post.content
      }}/>
    </Box>
  </>
}


export function renderProductMainContent(feed, navigate) {
  const {product_data: product} = feed;
  return <Box sx={{mb: 2}}>
    <Button
      size='medium'
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/product/${product.id}`)
      }}
      sx={{textTransform: 'none', mt: 0, mb: 2, ml: -1}}
    >
      {i18n.t("View product")}
    </Button>
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Name")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {valueOrNA(product.name)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Price")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {product.price ? (
              <>
                {product.price}
                <Currency code={product.currency_code} fontSize='1rem'/>
              </>
            ) : (
              valueOrNA(null)
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Year")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {valueOrNA(product.year)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Category")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {valueOrNA(product.category)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Location")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {valueOrNA(product.location_data?.name)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
            {i18n.t("Address")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {valueOrNA(product.address)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Divider/>
    <Box sx={{my: 2}}>
      <Typography variant='subtitle1' sx={{fontWeight: 'bolder', fontSize: '1rem'}}>
        {i18n.t("Detailed description")}
      </Typography>
      <Divider/>
      <Typography variant='subtitle1' sx={{mt: 2}}>
        {valueOrNA(product.description)}
      </Typography>
    </Box>
    {product.images.length > 0 && (
      <Box sx={{mt: 5, mb: 7}}>
        <Box sx={{width: '100%', height: '400px'}}>
          <ImagesCarousel
            images={product.images}
            getImageFn={(item) => item.data.original}
            getOriginalImageFn={(item) => item.data.original}
            onImageClick={(index, e) => e.stopPropagation()}
            openImageOnClick={true}
            height='400px'
            width='auto'
          />
        </Box>
      </Box>
    )}
  </Box>
}


export function renderShareMainContent(feed, navigate) {
  const {post_data, product_data, shared_from_data, is_deleted} = feed;
  const type = post_data ? 'post' : 'product';
  const renderer = MAIN_CONTENT_HANDLER_BY_TYPE[type];
  const {shared_from_feed_id: itemId} = feed;
  const itemLink = `/feed/${itemId}`
  return <Box sx={{mb: 2}}>
  {!is_deleted ? (
    <Box sx={{border: '1px solid #f0f0f0', borderRadius: '5px'}}>
      <UserInfo
        data={shared_from_data}
        mainContent={renderer(feed, navigate)}
        hoverColor='rgb(253 253 253)'
        padding='.5rem 1rem'
        disableComponentClick={false}
        onClick={e => {
          e.stopPropagation();
          navigate(itemLink);
        }}
      />
    </Box>
  ) : (
    <Typography variant='subtitle2' color='secondary'>
      {i18n.t("The feed item has been deleted.")}
    </Typography>
  )}
  </Box>
}

export const MAIN_CONTENT_HANDLER_BY_TYPE = {
  post: renderPostMainContent,
  product: renderProductMainContent,
  share: renderShareMainContent,
}

export function renderFeedMainContent(feed, navigate) {
  const {type} = feed;
  const renderer = MAIN_CONTENT_HANDLER_BY_TYPE[type]
  if (renderer) {
    return renderer(feed, navigate)
  }
  throw new DOMException()
}


export function renderFeedTitle(feed) {
  if (feed.type === "share") {
    return <>
        <span>
          {feed.user_data.name}
        </span>
      <span style={{marginLeft: 6, fontWeight: '100', color: '#505b60'}}>
          {i18n.t("shared a post")}
      </span>
    </>
  } else if (feed.type === "product") {
    return <>
      <span>
          {feed.user_data.name}
      </span>
      <span style={{marginLeft: 6, fontWeight: '100', color: 'rgb(200 83 90)'}}>
          {i18n.t("created a new product")}
      </span>
    </>
  }
  return <>
    {feed.user_data.name}
  </>
}