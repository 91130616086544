export const period = [
  {label: '1 hour ago', code: 1 * 60},
  {label: '2 hours ago', code: 2 * 60},
  {label: '1 day ago', code: 1 * 24 * 60},
  {label: '2 days ago', code: 2 * 24 * 60},
  {label: '1 week ago', code: 1 * 7 * 24 * 60},
  {label: '2 weeks ago', code: 2 * 7 * 24 * 60},
]

export const ratings = [
  {label: '1'},
  {label: '2'},
  {label: '3'},
  {label: '4'},
  {label: '5'},
]