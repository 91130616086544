import {Autocomplete as MuiAutocomplete, Box, TextField} from "@mui/material";
import i18n from "@core/configs/i18n";
import React, {useEffect, useState} from "react";

export default function Autocomplete(
  {
    label,
    value,
    options,
    errorMessage,
    onChange = () => {},
    size='medium',
    ...rest
  }) {
  const defaultOption = {label: i18n.t("Choose one"), code: null, disabled: true}
  if (!options.length || options[0].code !== -1) {
    options = [
      defaultOption,
      ...options
    ]
  }

  if (value) {
    value = options.find(option => String(option.code) === String(value))
  }

  const [selectedValue, setSelectedValue] = useState(value || defaultOption)
  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  return <MuiAutocomplete
    fullWidth
    options={options}
    autoHighlight
    getOptionLabel={(option) => option.label || ""}
    getOptionDisabled={(option) => option.disabled}
    renderOption={(props, option) => (
      <Box component="li" disabled sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
        {option.label}
      </Box>
    )}
    value={selectedValue}
    onChange={(event, newValue) => {
      onChange(newValue ? newValue.code : null)
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label={i18n.t(label)}
        className='bg-white'
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off', // disable autocomplete and autofill
        }}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        size={size}
        {...rest}
      />
    )}
  />
}
