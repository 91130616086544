import i18n from "@core/configs/i18n";
import {Box} from "@mui/material";


export default function NotFound() {
  return <>
    <Box sx={{textAlign: 'center'}}>
      <h3> {i18n.t("Page not found")}</h3>
    </Box>
  </>
}