// ** React Imports
import {createContext, useEffect, useState} from 'react'
import productService from "../screens/product/Product.service";

// ** Defaults
const defaultProvider = {
  /** @type {Array<{id: number, name: String}>} */
  categories: [],
  /** @type {Array<{id: number, name: String}>} */
  locations: [],
  /** @type {Array<{id: number, name: String}>} */
  live_locations: [],
  /** @type {Array<{id: number, name: String}>} */
  countries: [],
  /** @type {Array<{code: String, name: String, code_length: number, parent_id: String}>} */
  product_codes: [],
  currency_codes: [],
}
const ProductSettingsContext = createContext(defaultProvider)

const ProductSettingsProvider = ({children}) => {
  const [productSettings, setProductSettings] = useState(defaultProvider)

  useEffect(() => {
    productService.getProductSettings().then(({ok, result, errors}) => {
      if (ok) {
        const productCodes = result.product_codes || [];
        const len6ProductCodes = productCodes
          .sort((a, b) => a.code > b.code ? 1 : -1);
        setProductSettings({
          categories: result.categories || [],
          locations: result.locations || [],
          live_locations: result.live_locations || [],
          product_codes: len6ProductCodes,
          countries: result.countries || [],
          currency_codes: result.currency_codes || [],
        })
      }
    })
  }, [])

  const values = {
    ...productSettings
  }

  return <ProductSettingsContext.Provider value={values}>{children}</ProductSettingsContext.Provider>
}

export {ProductSettingsContext, ProductSettingsProvider}
