import React, {useEffect, useState} from "react";

import Product from "components/Product";
import Loader from "components/Loader";
import Pagination from '@mui/material/Pagination';
import {Box, Grid} from "@mui/material";
import i18n from "@core/configs/i18n";
import ProductsFilter from "components/ProductsFilter";
import {getFirstErrorString, showMessage} from "@core/utils";
import productService from "../product/Product.service";
import {DEFAULT_PAGE_SIZE} from "../../constants";
import {useNavigate} from "react-router-dom";
import {useProductFilters} from "../../hooks/useProductFilters";
import provider from "react-redux/lib/components/Provider";

const PRODUCTS_PER_PAGE = DEFAULT_PAGE_SIZE;

function getSavedProductsFilter() {
  return JSON.parse(
    localStorage.getItem('home:productsFilter') || '{}'
  )
}

function getSavedPage() {
  return parseInt(localStorage.getItem('home:page') || '1')
}

const defaultFilter = {
  buyer: null,
  provider: null,
  transiter: null,
  buyer_rating: '',
  provider_rating: '',
  product: '',
  category: '',
  year_from: '',
  year_to: '',
  price_from: '',
  price_to: '',
  location: '',
  address: '',
  announcement_code: '',
  period: '',
  customs_clearance: '',
  search: '',
  /** @type {Array<{code: string, name: string}>} */
  product_codes: [],
  currency_code: null,
}

function HomeScreen() {
  const {
    filterRef: providerFilterRef,
    pageRef: providerPageRef,
  } = useProductFilters();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(providerPageRef.current);
  const [count, setCount] = useState(0);
  const [productsFilter, setProductsFilter] = useState({
    ...defaultFilter,
    ...providerFilterRef.current,
  })

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts(page);
  }, []);

  useEffect(() => {
    providerFilterRef.current = {...productsFilter}
  }, [productsFilter])

  useEffect(() => {
    providerPageRef.current = page;
  }, [page])

  const getPreparedProductsFilter = () => {
    return {
      ...productsFilter,
      buyer: productsFilter.buyer ? productsFilter.buyer.value : null,
      provider: productsFilter.provider ? productsFilter.provider.value : null,
      transiter: productsFilter.transiter ? productsFilter.transiter.value : null,
      /** Send as a string joined by `,` */
      product_codes: productsFilter.product_codes.map(item => item.code).join(',')
    }
  }

  const fetchProducts = async page => {
    setLoading(true);
    const filters = getPreparedProductsFilter();
    const {ok, result, errors} = await productService.getProducts(filters, page, PRODUCTS_PER_PAGE);
    if (ok) {
      const {results, count} = result;
      setProducts(results);
      setCount(count);
    } else {
      showMessage(false, getFirstErrorString(errors));
    }
    setLoading(false);
  }

  const searchHandler = () => {
    if (page !== 1){
      setPage(1)
    }
    fetchProducts(1)
  }

  return (
    <Box sx={{my: 5, mx: 2}}>
      <Box>
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={7}>
            <Box sx={{mb: 5}}>
              <ProductsFilter
                loading={loading}
                onSubmit={searchHandler}
                formInput={productsFilter}
                onUpdate={(field, value) => setProductsFilter(prev => ({...prev, [field]: value}))}
                onReset={() => setProductsFilter(defaultFilter)}
              />
            </Box>
            {loading ? (
              <Loader/>
            ) : (
              <Box>
                <Grid container justifyContent='center'>
                  {products.map((product) => {
                    return (
                      <Grid item
                            key={product.id}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={3}
                            sx={{mb: 4, px: 1}}>
                        <Product product={product} onClick={() => navigate(`/product/${product.id}`)}/>
                      </Grid>
                    );
                  })}
                  {!products.length && (
                    <p style={{
                      fontSize: '1.2 rem',
                      textAlign: 'center',
                      width: '100%'
                    }}>{i18n.t("There are no products")}</p>
                  )}
                </Grid>
                {count > PRODUCTS_PER_PAGE && (
                  <Pagination
                    count={Math.ceil(count / PRODUCTS_PER_PAGE)}
                    page={page}
                    onChange={(_, page) => {
                      setPage(page);
                      fetchProducts(page);
                    }}
                    color="primary"
                  />
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HomeScreen;
