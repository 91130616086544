import React, {useEffect, useState} from 'react';
import {ErrorCode, useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import i18n from "@core/configs/i18n";
import {Box, Grid, Typography} from "@mui/material";
import {isMimeTypeImage, isMimeTypePdf, isMimeTypeWord, readSourceFile, showMessage} from "@core/utils";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import "./index.css"
import {useDrag, useDrop} from "react-dnd";

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  transition: border .24s ease-in-out;
`;

const ITEM_TYPES = {
  FILE: 'file',
}

function UploadedFile(
  {
    index,
    file,
    onRemove,
    onPositionChange,
    isPositionChangeEnabled = false,
    getImageSrc = (image) => null,
  }) {
  const [src, setSrc] = useState(null)

  const [{isDragging}, drag] = useDrag(() => ({
    type: ITEM_TYPES.FILE,
    item: {index},
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [index, onPositionChange])
  const [{isOver}, drop] = useDrop(() => ({
    accept: ITEM_TYPES.FILE,
    drop: (event) => {
      if (onPositionChange) {
        const {index: startImageIndex} = event;
        onPositionChange(startImageIndex, index)
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }), [index, onPositionChange])

  useEffect(() => {
    if (isMimeTypeImage(file.type)) {
      // TODO make sure we never read the same file again
      if (file.id !== undefined) {
        setSrc(getImageSrc(file))
      } else {
        readSourceFile(file).then(result => {
          setSrc(result)
        })
      }
    } else if (isMimeTypePdf(file.type)) {
      setSrc('/images/icons/pdf.png');
    } else if (isMimeTypeWord(file.type)) {
      setSrc('/images/icons/word.png');
    } else {
      setSrc('/images/icons/file.png');
    }
  }, [file])
  return <Box>
    <Box
      ref={drop}
      sx={{
        position: 'relative',
        background: 'white',
        opacity: isOver ? ".2" : "1",
        ...(isPositionChangeEnabled && {":hover": {opacity: '.65'}})
      }}>
      <Box ref={drag} sx={{opacity: isDragging ? '.3' : 1}}>
        <Box
          onClick={e => e.stopPropagation()}
          sx={{
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}>
          <img  
            src={src}
            alt={file.name}
            style={{height: '100px'}}
          />
        </Box>
      </Box>
      <Box sx={{position: 'absolute', top: '-1rem', right: '-1rem', zIndex: '1'}}>
        <IconButton
          fontSize='small'
          sx={{background: '#dcd9d9'}}
          onClick={e => e.stopPropagation() & onRemove()}
        >
          <ClearIcon fontSize='small'/>
        </IconButton>
      </Box>
    </Box>
  </Box>
}


const emptyFiles = [];

export default function FileUploader(
  {
    /** @type {{name: String, id: number, [String]: String|number|boolean}}*/
    files = emptyFiles,
    error,
    onUpdate,
    maxFiles = 20,
    maxSize = 1 * 1024 * 1024,
    accept = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],
    onPositionChange = null,
    isPositionChangeEnabled = false,
    getImageSrc = (image) => null,
  }
) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dropzoneAccept, setDropzoneAccept] = useState({});

  useEffect(() => {
    const acceptObj = {};
    for (const type of accept) {
      acceptObj[type] = []
    }
    setDropzoneAccept(acceptObj)
  }, [])

  useEffect(() => {
    setUploadedFiles(files)
  }, [files])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: maxFiles,
    maxSize: maxSize,
    accept: dropzoneAccept,
    onDrop: async (newFiles) => {
      const allFiles = [...uploadedFiles, ...newFiles].slice(0, maxFiles)
      setUploadedFiles(allFiles)
      onUpdate(allFiles)
    },
    onDropRejected: (fileRejections, event) => {
      let hasTooManyFilesRejectionError = false;
      for (const fileRejection of fileRejections) {
        const {file, errors} = fileRejection
        for (const error of errors) {
          const {code} = error
          if (code === ErrorCode.FileInvalidType) {
            // noinspection JSCheckFunctionSignatures
            showMessage(
              false,
              i18n.t("Ignoring file '{{name}}' as it has unsupported type", {name: file.name}),
              10 * 1000,
            )
          } else if (code === ErrorCode.FileTooLarge) {
            showMessage(
              false,
              i18n.t("Ignoring file - '{{name}}' as its size - {{size}}MB exceeds max allowed size - {{max_size}}MB", {
                name: file.name,
                size: Math.round(file.size / 1024 / 1024 * 100) / 100,
                max_size: maxSize / 1024 / 1024
              }),
              10 * 1000)
          } else if (code === ErrorCode.TooManyFiles) {
            hasTooManyFilesRejectionError = true;
          }
        }
      }
      if (hasTooManyFilesRejectionError) {
        // noinspection JSCheckFunctionSignatures
        showMessage(
          false,
          i18n.t("You can't upload more than {{max_files}} files", {max_files: maxFiles}),
          10 * 1000
        )
      }
    }
  })

  const removeFile = (index) => {
    const newFiles = [...uploadedFiles]
    newFiles.splice(index, 1)
    setUploadedFiles(newFiles)
    onUpdate(newFiles)
  }

  return (
    <Box
      className={"file-uploader-container " + (error ? "file-uploader-component-error" : "")}
      sx={{p: 2,}}>
      <Box
        {...getRootProps({isFocused, isDragAccept, isDragReject})}
        sx={{
          py: 4,
          border: '1px dashed #dcd9d9',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#fafafa",
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{textAlign: 'center'}}>
          <CloudUploadIcon sx={{fontSize: '2.5rem', color: 'lightgrey'}}/>
          <br/>
          <Typography color='secondary' variant='body'>
            {i18n.t("Drag and drop or")} <Typography variant='span' color='primary'>{i18n.t("browse")}</Typography>
          </Typography>
        </Box>
      </Box>
      <Container>
        {uploadedFiles.length > 0 && (
          <Grid container sx={{mt: '1rem', mx: 0}}>
            {uploadedFiles.map((file, index) => {
              return <Grid key={index} item xs={6} sm={4} md={4} lg={3} sx={{my: .5, pr: 2, mt: 2}}>
                <UploadedFile
                  index={index}
                  file={file}
                  onRemove={e => removeFile(index)}
                  onPositionChange={onPositionChange}
                  isPositionChangeEnabled={isPositionChangeEnabled}
                  getImageSrc={getImageSrc}
                />
              </Grid>
            })}
          </Grid>
        )}
      </Container>
      {
        error && <Typography color='error' textAlign='left' mt={1}>
          {error}
        </Typography>
      }
    </Box>
  )
    ;
}