// ** React Imports
import {createContext, useState} from 'react'

// ** Defaults
const defaultProvider = {
  showImages: false,
  images: [],
  activeIndex: 0,
  setActiveIndex: (index) => null,
  getImageFn: (item) => item,
  /** `getImageFn` should take image object and return `src` */
  openImages: (images, index, getImageFn = (item) => item) => null,
  closeImages: () => null,
}
const ImagesContext = createContext(defaultProvider)

const ImagesProvider = ({children}) => {
  const [showImages, setShowImages] = useState(defaultProvider.showImages)
  const [images, setImages] = useState(defaultProvider.images)
  const [getImageFn, setGetImageFn] = useState(defaultProvider.getImageFn)
  const [activeIndex, setActiveIndex] = useState(defaultProvider.activeIndex)

  const values = {
    showImages,
    images,
    activeIndex,
    setActiveIndex,
    getImageFn,
    openImages: (images, index = 0, getImageFn = (item) => item) => {
      setShowImages(true)
      setImages(images)
      setActiveIndex(index)
      setGetImageFn(() => getImageFn)
    },
    closeImages: () => {
      setShowImages(false)
      setImages(defaultProvider.images)
      setActiveIndex(0)
      setGetImageFn(defaultProvider.getImageFn)
    }
  }

  return <ImagesContext.Provider value={values}>{children}</ImagesContext.Provider>
}

export {ImagesContext, ImagesProvider}
