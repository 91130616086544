import {useAuth} from "hooks/useAuth";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export default function AuthGuard({children}) {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user) {
      navigate('/login')
    }
  })

  return auth.user ? children : null;
}