import i18n from "@core/configs/i18n";


const HOME_TABS = [
  {
    title: () => i18n.t("General"),
    tab: "general",
  },
  {
    title: () => i18n.t("Posts"),
    tab: "posts",
  },
  {
    title: () => i18n.t("Following"),
    tab: "following",
  },
  {
    title: () => i18n.t("Followers"),
    tab: "followers",
  },
]

export default HOME_TABS
