import {Box, Button, Divider, FormControl, InputAdornment, MenuItem, Paper, Select, TextField} from "@mui/material";
import AsyncAutocomplete from "./AsyncAutocomplete";
import i18n from "@core/configs/i18n";
import productService from "../screens/product/Product.service";
import {normalizeOptions} from "@core/utils";
import Autocomplete from "./Autocomplete";
import {useProductSettings} from "../hooks/useProductSettings";
import ProductCodesDropdown from "./product-codes-dropdown";
import Currency from "./currency";
import React from "react";

export default function ProductsFilterLeftPanel(
  {
    onSearch,
    formFilter,
    onUpdate = (field, value) => null,
  }) {
  const {locations, live_locations, currency_codes} = useProductSettings();

  const getFilterChangeHandler = (field, takesEvent = true) => {
    return function (eventOrValue) {
      const value = takesEvent ? eventOrValue.target.value : eventOrValue;
      onUpdate(field, value);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    onSearch()
  }

  return <>
    <Paper>
      <Box sx={{py: 2}}>
        <form onSubmit={handleSearch}>
          <Box sx={{px: 2, mb: 2, mt: 1}}>
            <AsyncAutocomplete
              onChange={getFilterChangeHandler('buyer', false)}
              value={formFilter.buyer}
              label={i18n.t("Buyer")}
              onSearch={async value => {
                const {result} = await productService.getBuyers(value)
                return normalizeOptions(result.results || [], 'name')
              }}
              size='small'
              sx={{background: 'white'}}
            />
          </Box>
          <Box sx={{px: 2, my: 2}}>
            <AsyncAutocomplete
              onChange={getFilterChangeHandler('provider', false)}
              value={formFilter.provider}
              label={i18n.t("Provider")}
              onSearch={async value => {
                const {result} = await productService.getProviders(value)
                return normalizeOptions(result.results || [], 'name')
              }}
              size='small'
              sx={{background: 'white'}}
            />
          </Box>
          <Box sx={{px: 2, my: 2}}>
            <AsyncAutocomplete
              onChange={getFilterChangeHandler('transiter', false)}
              value={formFilter.transiter}
              label={i18n.t("Carrier")}
              onSearch={async value => {
                const {result} = await productService.getTransiters(value)
                return normalizeOptions(result.results || [], 'name')
              }}
              size='small'
              sx={{background: 'white'}}
            />
          </Box>
          <Box sx={{py: 0, px: 2}}>
            <ProductCodesDropdown
              value={formFilter.product_codes}
              onChange={getFilterChangeHandler('product_codes', false)}
              size='small'
              showCheckboxes={false}
              fontSize='.75rem'
            />
          </Box>
          <Box sx={{px: 2, my: 2}}>
            <TextField
              type='text'
              label={i18n.t("Category")}
              value={formFilter.category}
              onChange={getFilterChangeHandler('category')}
              size='small'
              fullWidth
              sx={{m: 0, background: 'white'}}
            />
          </Box>
          <Divider sx={{borderColor: '#6b6a68'}}/>
          <Box sx={{px: 2, my: 2}}>
            {i18n.t("Price")}
            <Box sx={{display: 'flex', mt: 2}}>
              <TextField
                type='number'
                label={i18n.t("From")}
                value={formFilter.price_from}
                onChange={getFilterChangeHandler('price_from')}
                size='small'
                fullWidth
                sx={{m: 0, background: 'white'}}
              />
              <TextField
                type='number'
                label={i18n.t("To")}
                value={formFilter.price_to}
                onChange={getFilterChangeHandler('price_to')}
                size='small'
                sx={{ml: 1, background: 'white'}}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <Select
                      value={formFilter.currency_code}
                      onChange={getFilterChangeHandler('currency_code')}
                      size='small'
                      IconComponent={() => <></>}
                      displayEmpty
                      renderValue={(value) => {
                        if (!value) {
                          return <Currency code={"USD"} sx={{ml: {xs: 1, md: 0}, color: 'rgba(0, 0, 0, .3)'}} />
                        }
                        return <Currency code={value} sx={{ml: {xs: 1, md: 0}}} />
                      }}
                      sx={{mr: -1.75, width: {xs: '60px', md: '40px'}, pr: 2}}
                    >
                      <MenuItem value={null} sx={{color: 'lightgrey'}}>
                        <span style={{fontSize: '.8rem'}}> {i18n.t("N/A")} </span>
                      </MenuItem>
                      {currency_codes.map(({code}) => <MenuItem key={code} value={code} sx={{textAlign: 'center'}}>
                        <Currency code={code} sx={{ml: {xs: 1, md: .5}}}/>
                      </MenuItem>)}
                    </Select>
                  </InputAdornment>,
                }}
              />
            </Box>
          </Box>

          <Divider sx={{borderColor: '#6b6a68'}}/>
          <Box sx={{px: 2, my: 2}}>
            {i18n.t("Year")}
            <Box sx={{display: 'flex', mt: 2}}>
              <TextField
                type='number'
                label={i18n.t("From")}
                value={formFilter.year_from}
                onChange={getFilterChangeHandler('year_from')}
                size='small'
                fullWidth
                sx={{m: 0, background: 'white'}}
              />
              <TextField
                type='number'
                label={i18n.t("To")}
                value={formFilter.year_to}
                onChange={getFilterChangeHandler('year_to')}
                size='small'
                sx={{ml: 1, background: 'white'}}
                fullWidth
              />
            </Box>
          </Box>

          <Divider sx={{borderColor: '#6b6a68'}}/>
          <Box sx={{px: 2, my: 2}}>
            <TextField
              type='text'
              label={i18n.t("Announcement code")}
              value={formFilter.announcement_code}
              onChange={getFilterChangeHandler('announcement_code')}
              size='small'
              fullWidth
              sx={{m: 0, background: 'white'}}
            />
          </Box>
          <Box sx={{px: 2, my: 2}}>
            <Autocomplete
              label={i18n.t("Location")}
              value={formFilter.location}
              onChange={getFilterChangeHandler('location', false)}
              options={normalizeOptions(locations)}
              size='small'
            />
          </Box>
          <Box sx={{px: 2, my: 2}}>
            <TextField
              type='text'
              label={i18n.t("Address")}
              value={formFilter.address}
              onChange={getFilterChangeHandler('address')}
              size='small'
              fullWidth
              sx={{m: 0, background: 'white'}}
            />
          </Box>
          <Divider sx={{borderColor: '#6b6a68'}}/>
          <Box sx={{px: 2, my: 2}}>
            {i18n.t("Customs clearance")}
            <FormControl fullWidth sx={{mt: 2}}>
              <Select
                labelId='customs-clearance-form-select'
                id='customs-clearance-form-select-base'
                value={formFilter.customs_clearance}
                onChange={getFilterChangeHandler('customs_clearance')}
                size='small'
                sx={{background: 'white'}}
              >
                <MenuItem value={null} sx={{color: 'lightgrey'}}>
                  {i18n.t("None")}
                </MenuItem>
                <MenuItem value={true}>{i18n.t("Yes")}</MenuItem>
                <MenuItem value={false}>{i18n.t("No")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{px: 2}}>
            <Button
              variant='contained'
              fullWidth
              type={'submit'}
            >
              {i18n.t("Search")}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  </>
}