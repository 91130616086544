import {DIALOGS_ENDPOINT, MESSAGES_ENDPOINT, MESSAGES_PER_PAGE} from "./send";
import axiosClient from "../../axios";
import {
  DialogsResponse,
  GenericUserPKMessage,
  MessageBoxPosition,
  MessageDeleted,
  MessagesResponse,
  MessageTypeCallMessage,
  MessageTypeErrorOccurred,
  MessageTypeIsTyping,
  MessageTypeMessageIdCreated,
  MessageTypeMessageRead,
  MessageTypeNewUnreadCount,
  MessageTypeReaction,
  MessageTypeReactionRemove,
  MessageTypeReplyMessage,
  MessageTypes,
  MessageTypesDecoder,
  MessageTypeTextMessage,
  MessageTypeUserOnlineStatus
} from "./types";
import {
  getMessageStatus,
  getSubtitleTextFromMessageModel
} from "./utils";
import {
  createMessageBoxFromMessageTypeCallMessage,
  createMessageBoxFromMessageTypeReplyMessage,
  createMessageBoxFromMessageTypeTextMessage
} from "./create";

export async function fetchPrevDialogMessages(dialog, messagesCursor) {
  const {id: toUserId} = dialog;
  let url = `${MESSAGES_ENDPOINT}${toUserId}/?page_size=${MESSAGES_PER_PAGE}`
  if (messagesCursor) {
    url += `&cursor=${messagesCursor}`
  }
  let response = null;
  try {
    response = await axiosClient.get(url)
  } catch (e) {
    return {
      messages: [],
      cursor: null,
      hasMore: false
    }
  }
  const {data, cursor, hasMore} = MessagesResponse(response.data)
  const messages = data.map(message => {
    return {
      position: message.out ? MessageBoxPosition.Right : MessageBoxPosition.Left,
      type: message.type,
      text: message.text,
      title: message.sender_name,
      status: getMessageStatus(message),
      date: message.sent,
      is_deleted: message.is_deleted,
      data: {
        dialog_id: message.out ? message.recipient : message.sender,
        message_id: String(message.id),
        out: message.out,
      },
      emoji_id: message.emoji_id,
      reply_to: message.reply_to
    }
  }).sort((a, b) => a.date - b.date)
  return {messages, cursor, hasMore}
}


export async function fetchDialogs(query = '', cursor = null) {
  let dataObj = []
  try {
    const params = {
      search: query,
      page_size: 20
    }
    if (cursor) {
      params['cursor'] = cursor
    }
    const response = await axiosClient.get(DIALOGS_ENDPOINT, {params});
    dataObj = DialogsResponse(response.data)
  } catch (e) {
    throw e
  }
  const {data, cursor: nextCursor} = dataObj
  const dialogs = data.map(dialog => ({
    id: dialog.other_user_id,
    alt: dialog.name,
    title: dialog.name,
    image_data: dialog.image_data,
    date: dialog.last_message && (dialog.last_message.sent || dialog.last_message.created),
    lastMessage: dialog.last_message,
    subtitle: getSubtitleTextFromMessageModel(dialog.last_message),
    unread: dialog.unread_count
  }))
  return {
    cursor: nextCursor,
    dialogs,
  }
}


const incomingWebsocketMessageHandlers = (function () {
  let result;

  function handleTextMessage(messageData, callbacks) {
    result = MessageTypeTextMessage(messageData)
    result = createMessageBoxFromMessageTypeTextMessage(result)
    callbacks.addMessage(result)
  }

  function handleCallMessage(messageData, callbacks) {
    result = MessageTypeCallMessage(messageData)
    result = createMessageBoxFromMessageTypeCallMessage(result)
    callbacks.addMessage(result)
  }

  function handleFileMessage(messageData, callbacks) {
    // result = MessageTypeFileMessage(messageData)
    // result = createMessageBoxFromMessageTypeFileMessage(result)
    // callbacks.addMessage(result)
  }

  function handleMessageIdCreated(messageData, callbacks) {
    result = MessageTypeMessageIdCreated(messageData)
    callbacks.replaceMessageId(messageData.random_id, messageData.db_id)
  }

  function handleIsTyping(messageData, callbacks) {
    result = MessageTypeIsTyping(messageData)
    callbacks.addPKToTyping(result.user_pk, result.typing)
  }

  function handleWentOnline(messageData, callbacks, isOnline) {
    result = GenericUserPKMessage(messageData)
    callbacks.changePKOnlineStatus(result.user_pk, isOnline)
  }

  function handleMessageRead(messageData, callbacks) {
    result = MessageTypeMessageRead(messageData)
    callbacks.setMessageIdAsRead(result.message_id)
  }

  function handleNewUnreadCount(messageData, callbacks) {
    result = MessageTypeNewUnreadCount(messageData)
    callbacks.newUnreadCount(result.sender, result.unread_count)
  }

  function handleUserOnlineStatus(messageData, callbacks) {
    result = MessageTypeUserOnlineStatus(messageData)
    callbacks.updateUserOnlineStatus(result.user_pk, result.online)
  }

  function handleMarkAllMessagesRead(messageData, callbacks) {
    result = GenericUserPKMessage(messageData)
    const {user_pk: dialog_id} = result;
    callbacks.markAllMessagesAsRead(dialog_id)
  }

  function handleDeleteMessage(messageData, callbacks) {
    result = MessageDeleted(messageData)
    const {user_pk, message_id} = result;
    callbacks.deleteMessage(message_id, user_pk)
  }

  function handleReplyToMessage(messageData, callbacks) {
    result = MessageTypeReplyMessage(messageData)
    result = createMessageBoxFromMessageTypeReplyMessage(result)
    callbacks.addMessage(result)
  }

  function handleMessageReaction(messageData, callbacks) {
    result = MessageTypeReaction(messageData)
    const {user_pk: dialog_id, message_id, emoji_id} = result;
    callbacks.clientMessageReaction(message_id, dialog_id, emoji_id)
  }

  function handleMessageReactionRemove(messageData, callbacks) {
    result = MessageTypeReactionRemove(messageData)
    const {user_pk: dialog_id, message_id} = result;
    callbacks.clientMessageReaction(message_id, dialog_id)
  }

  function handleErrorOccurred(messageData, callbacks) {
    return MessageTypeErrorOccurred(messageData)
  }

  return {
    [MessageTypes.TextMessage]: (messageData, callbacks) => handleTextMessage(messageData, callbacks),
    [MessageTypes.CallMessage]: (messageData, callbacks) => handleCallMessage(messageData, callbacks),
    [MessageTypes.MessageIdCreated]: (messageData, callbacks) => handleMessageIdCreated(messageData, callbacks),
    [MessageTypes.IsTyping]: (messageData, callbacks) => handleIsTyping(messageData, callbacks),
    [MessageTypes.WentOnline]: (messageData, callbacks) => handleWentOnline(messageData, callbacks, true),
    [MessageTypes.WentOffline]: (messageData, callbacks) => handleWentOnline(messageData, callbacks, false),
    [MessageTypes.MessageRead]: (messageData, callbacks) => handleMessageRead(messageData, callbacks),
    [MessageTypes.NewUnreadCount]: (messageData, callbacks) => handleNewUnreadCount(messageData, callbacks),
    [MessageTypes.UserOnlineStatus]: (messageData, callbacks) => handleUserOnlineStatus(messageData, callbacks),
    [MessageTypes.MarkAllMessagesRead]: (messageData, callbacks) => handleMarkAllMessagesRead(messageData, callbacks),
    [MessageTypes.DeleteMessage]: (messageData, callbacks) => handleDeleteMessage(messageData, callbacks),
    [MessageTypes.ReplyToMessage]: (messageData, callbacks) => handleReplyToMessage(messageData, callbacks),
    [MessageTypes.MessageReaction]: (messageData, callbacks) => handleMessageReaction(messageData, callbacks),
    [MessageTypes.MessageReactionRemove]: (messageData, callbacks) => handleMessageReactionRemove(messageData, callbacks),
    [MessageTypes.ErrorOccurred]: (messageData, callbacks) => handleErrorOccurred(messageData, callbacks),
  }
})()

export function handleIncomingWebsocketMessage(sock, message, callbacks) {
  const messageData = JSON.parse(message)
  const type = MessageTypesDecoder(messageData)
  const handler = incomingWebsocketMessageHandlers[type]
  if (handler) {
    const errorObj = handler(messageData, callbacks)
    if (errorObj && errorObj.error) {
      return errorObj.error.message
    }
    return null;
  } else {
    // We should leave the code other socket might be connected which will handle this case
  }
}
