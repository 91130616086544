import toast from "react-hot-toast";
import i18n from "../configs/i18n";
import moment from "moment";
import draftToHtml from 'draftjs-to-html';
import {convertFromRaw, convertToRaw} from "draft-js";
import EditorState from "draft-js/lib/EditorState";
import {Typography} from "@mui/material";
import {
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF,
  FILE_TYPE_WORD,
  FILE_TYPES_MAPPER,
  SUPPORTED_COUNTRY_CODE_PREFIXES
} from "constants.js";

/**
 *
 * @param setFormFields {Function} state function to update form fields
 * @param errors {{
 *   [field: string]: String
 * } | {
 *   non_field_errors: [String]
 * } | null}
 */
export const setFormErrors = (errors, setFormFields) => {
  if (!errors) {
    // remove all errors
    setFormFields(prevForm => {
      const newForm = {}
      for (const field in prevForm) {
        newForm[field] = {value: prevForm[field].value, error: ''}
      }

      return newForm
    })
  } else {
    for (const field in errors) {
      setFormFields(prevForm => {
        return prevForm.hasOwnProperty(field) ? {
          ...prevForm,
          [field]: {
            value: prevForm[field].value,
            error: errors[field]
          }
        } : prevForm
      })
    }
    const non_field_errors = errors.non_field_errors
    if (Array.isArray(non_field_errors) && non_field_errors.length) {
      const firstErrorMessage = errors.non_field_errors[0]
      showMessage(false, firstErrorMessage)
    } else if (typeof non_field_errors === 'string') {
      showMessage(false, non_field_errors)
    }
  }
}

export const showMessage = (success, message, duration = 5 * 1000) => {
  if (success) {
    toast.success(message, {duration})
  } else {
    toast.error(message, {duration})
  }
}

export function getInputChangeHandler(field, setFormInput, takesEvent = true) {
  return function (eventOrValue, isEvent = true) {
    setFormInput(prev => ({
      ...prev,
      [field]: {
        value: (isEvent && takesEvent) ? eventOrValue.target.value : eventOrValue, error: null,
      }
    }))
  }
}

export function getFormObject(formInput) {
  const form = {}
  Object.keys(formInput).forEach(key => {
    form[key] = formInput[key].value
  })
  return form
}


export function normalizeOptions(options, labelKey = 'name', codeKey = 'id') {
  return options.map(option => ({
    label: option[labelKey],
    code: option[codeKey]
  }))
}

export function getFirstErrorString(errorStringOrErrorsObject) {
  try {
    if (typeof errorStringOrErrorsObject === 'string') {
      return errorStringOrErrorsObject
    } else if (typeof errorStringOrErrorsObject === 'object') {
      if (Array.isArray(errorStringOrErrorsObject)) {
        return errorStringOrErrorsObject[0]
      } else {
        const values = Object.values(errorStringOrErrorsObject)
        if (values.length) {
          const firstError = values[0]
          if (Array.isArray(firstError)) {
            return firstError[0]
          } else {
            return firstError
          }
        }
      }
    }
    return null
  } catch (e) {
    return null
  }

}

export function getDateDiffString(createdAt, fromDateTime = null) {
  const productDateTime = new Date(createdAt);
  const currentDateTime = fromDateTime || new Date();
  const diffTime = Math.abs(currentDateTime - productDateTime);
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays > 0) {
    if (diffDays > 30) {
      return moment(createdAt).format('MMM DD')
    }
    return `${diffDays}${i18n.t('d')}`;
  } else if (diffHours > 0) {
    return `${diffHours}${i18n.t('h')}`;
  } else {
    return `${diffMinutes}${i18n.t('m')}`;
  }
}


export function editorContentToHtml(contentString) {
  const contentObject = JSON.parse(contentString)
  return draftToHtml(contentObject)
}

export function getMessageSubstring(message, len) {
  if (!message) {
    return ""
  }

  if (message.length > len) {
    return message.substring(0, len) + '...'
  }
  return message
}

export function draftStateToJson(state) {
  const content = state.getCurrentContent();
  const rawState = convertToRaw(content);
  return JSON.stringify(rawState);
}

export function draftJsonToState(json) {
  return EditorState.createWithContent(
    convertFromRaw(JSON.parse(json))
  )
}

export function normalizeErrorResponseData(data = {}) {
  const newData = {};
  for (const [errorKey, errors] of Object.entries(data)) {
    let error = null;
    if (Array.isArray(errors)) {
      if (errors.length > 0) {
        error = errors[0];
      }
    } else if (typeof errors === "object") {
      const values = Object.values(errors);
      if (values.length > 0) {
        const firstItem = values[0];
        if (typeof firstItem === "string") {
          error = firstItem;
        } else if (Array.isArray(firstItem) && firstItem.length > 0 && typeof firstItem[0] === "string") {
          error = firstItem[0];
        }
      }
    } else if (typeof errors === "string") {
      error = errors;
    }

    if (!error) {
      error = i18n.t("There was an error for this field: ") + `"${errorKey}"`
    }
    newData[errorKey] = error;
  }
  return newData;
}

export function readSourceFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result)
    }
    reader.onerror = ev => {
      resolve(null)
    }
    reader.readAsDataURL(file)
  })
}

export const DEFAULT_IMAGE = "/images/image-placeholder.jpg";

export function getImagesOrDefaultImages(images) {
  if (!images || !images.length) {
    return [
      {
        data: {
          original: DEFAULT_IMAGE
        }
      }
    ]
  }
  return images
}

export function getFirstThumbnailOriginalOrDefaultImageFromImages(images = [], dataField = 'data') {
  if (!images.length) {
    return DEFAULT_IMAGE;
  }
  const firstImage = images[0];
  if (!firstImage[dataField]) {
    return DEFAULT_IMAGE;
  }
  const data = firstImage[dataField];
  return data.thumbnail || data.original || DEFAULT_IMAGE;
}

export function getThumbnailOrOriginalImage(image, dataField = 'data') {
  const data = image[dataField];
  return data.thumbnail || data.original;
}


export const USER_DEFAULT_IMAGE = "/images/profile-image.png";

export function getUserOriginalOrDefaultImage(image, dataField = 'data') {
  if (!image) {
    return USER_DEFAULT_IMAGE;
  }
  const data = image[dataField];
  return data?.original || USER_DEFAULT_IMAGE;
}

export function getUserThumbnailOriginalOrDefaultImage(image, dataField = 'data') {
  if (!image) {
    return USER_DEFAULT_IMAGE;
  }
  const data = image[dataField];
  return data?.thumbnail || data?.original || USER_DEFAULT_IMAGE;
}

export function getQueryParamFromUrl(url, param) {
  const queryString = url.split('?')[1]
  const parts = queryString.split('&')
  for (const part of parts) {
    const [key, value] = part.split('=')
    if (key === param) {
      return decodeURIComponent(value)
    }
  }
  return null;
}

export function valueOrNA(value) {
  if (value === null || value === undefined || value === "") {
    return <Typography variant='caption' color='secondary'>
      {i18n.t("N/A")}
    </Typography>
  }
  return value
}


export function detectCountryFromPhoneNumber(phoneNumber) {
  for (const codeObj of SUPPORTED_COUNTRY_CODE_PREFIXES) {
    if (String(phoneNumber).startsWith(codeObj.prefix)) {
      return codeObj.code
    }
  }
  return null;
}

export function isMimeTypeImage(mimeType) {
  return FILE_TYPES_MAPPER[mimeType] === FILE_TYPE_IMAGE;
}

export function isMimeTypePdf(mimeType) {
  return FILE_TYPES_MAPPER[mimeType] === FILE_TYPE_PDF;
}

export function isMimeTypeWord(mimeType) {
  return FILE_TYPES_MAPPER[mimeType] === FILE_TYPE_WORD;
}

export function getUserToken() {
  return localStorage.getItem('ACCESS_TOKEN')
}


export function valueInListOrDefaultValue(value, list, defaultValue) {
  if (list.includes(value)) {
    return value
  }
  return defaultValue
}

export function getDatesDifferenceInHours(date1, date2) {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return diffTime / (1000 * 60 * 60);
}