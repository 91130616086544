// noinspection DuplicatedCode

import {createContext, useEffect, useRef, useState} from 'react'
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  getSubtitleTextFromMessageBox,
} from "../screens/chat/utils";
import {showMessage} from "@core/utils";
import {useAuth} from "../hooks/useAuth";
import {MessageBoxStatus, MessageTypes} from "../screens/chat/types";
import {
  create_ws_endpoint, markAllMessagesReadWIthUser, sendDeleteMessage, sendFindUserOnlineStatus,
  sendIsTypingMessage, sendMessageReaction, sendMessageReactionRemove,
  sendOutgoingCallMessage, sendOutgoingReplyToMessage,
  sendOutgoingTextMessage
} from "../screens/chat/send";
import {fetchDialogs, fetchPrevDialogMessages, handleIncomingWebsocketMessage} from "../screens/chat/receive";
import {createNewDialogByDialogIdAndTitle, createNewDialogModelFromIncomingMessageBox} from "../screens/chat/create";
import i18n from "../@core/configs/i18n";

const CHECK_ONLINE_STATUS_INTERVAL_SECONDS = 15 * 60; // 15 minutes in seconds

const defaultProvider = {
  openedDialogs: [],
  dialogs: [],
  searchingDialogs: false,
  fetchingMoreDialogs: false,
  openDialog: (id, title, image) => {
  },
  closeDialog: (id) => {
  },
  getDialogMessages: (dialog) => {
  },
  sendMessage: (dialog, message, replyTo, type) => {
  },
  startTyping: (dialog) => {
  },
  stopTyping: (dialog) => {
  },
  searchDialogs: (query) => {
  },
  fetchMoreDialogs: () => {
  },
  deleteMessage: (messageId, dialogId) => {
  },
  messageReaction: (messageId, dialogId, emojiId) => {
  },
  messageReactionRemove(messageId, dialogId) {
  }
}
const ChatContext = createContext(defaultProvider)

function addPropsToDialog(dialog, {messages = [], hasMore = true, cursor = null} = {}) {
  return {
    ...dialog,
    hasMoreMessages: hasMore,
    messages: messages,
    cursor: cursor,
    loading: false,
    hasFetchedMessages: false,
    online: false,
    typing: false,
    firstRequestResults: null,
  }
}

function resetDialogMessages(dialog) {
  return {
    ...dialog,
    cursor: null,
    hasMoreMessages: true,
    hasFetchedMessages: false,
    firstRequestResults: null,
    messages: [],
  }
}

function saveOnlyDialogFirstRequestMessages(dialog) {
  const firstRequestResults = dialog.firstRequestResults;
  // This is the result of the first request to fetch prev messages for the dialog
  let {cursor, hasMoreMessages, firstMessageId} = firstRequestResults
  // But it's possible that we sent a message in this dialog
  // so `messages` will lack the new messages,
  // so we should find them in the `dialog` object and add them to `messages`
  // we aren't saving messages because they might change
  let messages = []
  if (firstMessageId !== null) {
    const dialogMessages = dialog.messages;
    for (let i = dialogMessages.length - 1; i >= 0; i--) {
      messages.push(dialogMessages[i])
      if (String(dialogMessages[i].data.message_id) === String(firstMessageId)) {
        break
      }
    }
    messages = messages.reverse()
  } else {
    // If a dialog has no messages, then we should add the new messages to the dialog
    messages = dialog.messages
  }
  // If after first request results, we have added many messages, then we should reset the dialog messages
  // otherwise we can update `firstRequestResults` to include the new messages as well
  if (messages.length < 100) {
    return {
      ...dialog,
      cursor,
      hasMoreMessages,
      messages,
      hasFetchedMessages: true,
    }
  } else {
    return resetDialogMessages(dialog)
  }
}

const chatHelper = {
  socket: null,
  selfInfo: {pk: null, name: null},
  dialogs: [],
  setDialogs: () => null,
  selectedDialogs: [],
  setSelectedDialogs: () => null,

  init(accessToken, userInfo) {
    this.initSocket(accessToken)
    this.initUserInfo(userInfo)
    this.listen()
  },

  updateStateVars({dialogs, setDialogs, selectedDialogs, setSelectedDialogs}) {
    this.dialogs = dialogs
    this.setDialogs = setDialogs
    this.selectedDialogs = selectedDialogs
    this.setSelectedDialogs = setSelectedDialogs
  },

  initUserInfo({id: pk, name}) {
    this.selfInfo = {pk: String(pk), name}
  },

  initSocket(accessToken) {
    if (this.socket) {
      this.socket.close()
    }
    this.socket = new ReconnectingWebSocket(create_ws_endpoint(accessToken))
  },

  destroy() {
    if (this.socket) {
      this.socket.close()
      this.socket = null;
    }
    this.updateStateVars({
      dialogs: [],
      setDialogs: () => null,
      selectedDialogs: [],
      setSelectedDialogs: () => null,
    })
  },

  refresh(accessToken) {
    this.initSocket(accessToken)
    this.listen()
  },

  listen() {
    const self = this;
    this.socket.onopen = function (e) {
    }
    this.socket.onclose = function (e) {
    }
    this.socket.onmessage = function (e) {
      let errMsg = handleIncomingWebsocketMessage(this.socket, e.data, {
        addMessage: self.addMessage.bind(self),
        replaceMessageId: self.replaceMessageId.bind(self),
        addPKToTyping: self.addPKToTyping.bind(self),
        setMessageIdAsRead: self.setMessageIdAsRead.bind(self),
        newUnreadCount: self.newUnreadCount.bind(self),
        updateUserOnlineStatus: self.updateUserOnlineStatus.bind(self),
        markAllMessagesAsRead: self.markAllMessagesAsRead.bind(self),
        deleteMessage: self.deleteClientMessage.bind(self),
        clientMessageReaction: self.clientMessageReaction.bind(self),
      });
      if (errMsg) {
        showMessage(false, errMsg)
      }
    };
  },

  findOnlineStatuses() {
    for (const selectedDialog of this.selectedDialogs) {
      sendFindUserOnlineStatus(this.socket, selectedDialog.id)
    }
  },

  updateUserOnlineStatus(pk, isOnline) {
    this.setSelectedDialogs(prev => prev.map(dialog => ({
      ...dialog,
      online: String(dialog.id) === String(pk) ? isOnline : dialog.online
    })))
  },

  newUnreadCount(dialog_id, count) {
    // If dialog is opened, don't update it's `unread`
    if (!this.selectedDialogs.some(dialog => String(dialog.id) === String(dialog_id))) {
      this.updateDialog(dialog_id, {unread: count})
      // TODO we may add unread count to minimized chats
    }
  },

  setMessageIdAsRead(msg_id) {
    msg_id = String(msg_id)
    const stateUpdater = dialogs => dialogs.map(dialog => {
      if (dialog.messages.some(message => String(message.data.message_id) === msg_id)) {
        return {
          ...dialog,
          messages: dialog.messages.map(message => ({
            ...message,
            status: String(message.data.message_id) === msg_id ? MessageBoxStatus.Read : message.status
          }))
        }
      }
      return dialog;
    })

    this.setDialogs(prev => stateUpdater(prev))
    this.setSelectedDialogs(prev => stateUpdater(prev))
  },

  addPKToTyping(pk, isTyping) {
    if (pk === this.selfInfo.pk) {
      return;
    }
    this.setSelectedDialogs(prev => prev.map(dialog => ({
      ...dialog,
      online: (String(pk) === String(dialog.id)) ? true : dialog.online,
      typing: (String(pk) === String(dialog.id)) ? isTyping : dialog.typing
    })))
  },

  replaceMessageId(old_id, new_id) {
    old_id = String(old_id)
    new_id = String(new_id)

    const getNewMessagesForDialog = dialog => {
      return dialog.messages.map(message => {
        if (String(message.data.message_id) === old_id) {
          return {
            ...message,
            data: {
              ...message.data,
              message_id: new_id
            },
            status: message.data.out ? MessageBoxStatus.Sent : MessageBoxStatus.Received
          }
        } else {
          return message;
        }
      })
    }
    this.setDialogs(dialogs => {
      return dialogs.map(dialog => {
        if (dialog.messages.some(message => String(message.data.message_id) === old_id)) {
          return {
            ...dialog,
            messages: getNewMessagesForDialog(dialog)
          }
        }
        return dialog;
      })
    });
    this.setSelectedDialogs(dialogs => {
      return dialogs.map(dialog => {
        const targetMessage = dialog.messages.find(message => String(message.data.message_id) === old_id)
        if (targetMessage) {
          if (!targetMessage.data.out) {
            // mark this message read only if it was received
            this.markDialogMessagesAsRead(dialog.id)
          }
          return {
            ...dialog,
            messages: getNewMessagesForDialog(dialog)
          }
        }
        return dialog;
      })
    })
  },

  addMessage(msg) {
    // if we have opened the dialog, we should mark the message as read
    const weReceivedMessage = !msg.data.out;
    const messageIdIsCreated = msg.data.message_id > 0

    if (weReceivedMessage && messageIdIsCreated) {
      const openedDialogWhereWeReceivedMessage = this.selectedDialogs.find(d => String(d.id) === String(msg.data.dialog_id))
      if (openedDialogWhereWeReceivedMessage) {
        this.markDialogMessagesAsRead(msg.data.dialog_id)
        msg.status = MessageBoxStatus.Read
      }
    }

    if (weReceivedMessage) {
      const weHaveSuchDialog = this.dialogs.some((e) => String(e.id) === String(msg.data.dialog_id))
      if (!weHaveSuchDialog) {
        const newDialog = createNewDialogModelFromIncomingMessageBox(msg)
        this.setDialogs(prev => [addPropsToDialog(newDialog), ...prev])
      }
    }

    this.setDialogs(dialogs => {
      return dialogs.map(dialog => {
        if (String(dialog.id) === String(msg.data.dialog_id)) {
          return {
            ...dialog,
            messages: [...dialog.messages, {...msg}],
            subtitle: getSubtitleTextFromMessageBox(msg),
            lastMessage: msg
          }
        }
        return dialog;
      })
    })

    this.setSelectedDialogs(prev => {
      return prev.map(dialog => ({
        ...dialog,
        messages: String(dialog.id) === String(msg.data.dialog_id) ? [...dialog.messages, {...msg}] : dialog.messages,
        online: (!msg.data.out && String(dialog.id) === String(msg.data.dialog_id)) ? true : dialog.online,
      }))
    })
  },

  markAllMessagesAsRead(dialogId) {
    const updater = prev => prev.map(dialog => ({
      ...dialog,
      messages: (String(dialog.id) === String(dialogId)
          ? dialog.messages.map(message => ({
            ...message,
            status: message.data.out ? MessageBoxStatus.Read : message.status
          }))
          : dialog.messages
      ),
      online: String(dialog.id) === String(dialogId) ? true : dialog.online
    }))

    this.setDialogs(updater)
    this.setSelectedDialogs(updater)
  },

  markDialogMessagesAsRead(dialogId) {
    markAllMessagesReadWIthUser(this.socket, dialogId)
    this.updateDialog(dialogId, {unread: 0})
  },

  findUserOnlineStatus(dialogId) {
    sendFindUserOnlineStatus(this.socket, dialogId)
  },

  performSendingMessage(dialog, message, replyTo, type = MessageTypes.TextMessage) {
    function sendTextMessage(dialog, message) {
      if (message.trim().length) {
        const msgBox = sendOutgoingTextMessage(this.socket, message.trim(), dialog.id, this.selfInfo);
        this.addMessage(msgBox);
      }
    }

    function sendCallMessage(dialog, message) {
      const msgBox = sendOutgoingCallMessage(this.socket, "", dialog.id, this.selfInfo);
      this.addMessage(msgBox);
    }

    function sendReplyToMessage(dialog, message, replyTo) {
      const replyToId = replyTo.data.message_id;
      const replyText = replyTo.text;
      const msgBox = sendOutgoingReplyToMessage(
        this.socket,
        message,
        {id: replyToId, text: replyText},
        dialog.id,
        this.selfInfo
      );
      this.addMessage(msgBox);
    }

    const sendMessageFunctionByType = {
      [MessageTypes.TextMessage]: sendTextMessage.bind(this),
      [MessageTypes.CallMessage]: sendCallMessage.bind(this),
      [MessageTypes.ReplyToMessage]: sendReplyToMessage.bind(this),
    }

    const messageSenderFunction = sendMessageFunctionByType[type]
    if (messageSenderFunction) {
      messageSenderFunction(dialog, message, replyTo)
    } else {
      throw new Error('Unknown message type')
    }
  },

  updateDialog(id, data, updateDialogs = true, updateSelectedDialogs = true) {
    id = String(id)
    if (updateDialogs) {
      this.setDialogs(prev => {
        return prev.map(el => ({
          ...el,
          ...(String(el.id) === id && data)
        }))
      })
    }
    if (updateSelectedDialogs) {
      this.setSelectedDialogs(prev => {
        return prev.map(el => ({
          ...el,
          ...(String(el.id) === id && data)
        }))
      })
    }
  },

  async getDialogMessages(dialog) {
    const {hasMoreMessages, loading, cursor} = dialog;
    if (loading) {
      return
    }
    if (hasMoreMessages) {
      this.updateDialog(dialog.id, {loading: true}, false, true)
      let result = null;
      try {
        result = await fetchPrevDialogMessages(dialog, cursor)
      } catch (e) {
      }
      if (result) {
        let messages;
        if (!dialog.hasFetchedMessages) {
          messages = result.messages;
        } else {
          messages = [...result.messages, ...dialog.messages]
        }
        const {cursor, hasMore} = result;
        this.updateDialog(dialog.id,
          {
            messages,
            cursor,
            hasMoreMessages: hasMore,
            loading: false,
            hasFetchedMessages: true,
            firstRequestResults: dialog.firstRequestResults || {
              cursor,
              hasMoreMessages: hasMore,
              firstMessageId: result.messages.length ? result.messages[0].data.message_id : null
            }
          })
      } else {
        this.updateDialog(dialog.id, {loading: false}, false, true)
      }
    }
  },

  startTyping(dialog) {
    sendIsTypingMessage(this.socket, dialog.id, true)
  },

  stopTyping(dialog) {
    sendIsTypingMessage(this.socket, dialog.id, false)
  },

  updateMessagePropertyInDialogsAndSelectedDialogs(messageId, dialogId, property, value) {
    const updater = dialogs => dialogs.map(dialog => ({
      ...dialog,
      messages: String(dialog.id) === String(dialogId)
        ? dialog.messages.map(message => ({
          ...message,
          [property]: String(message.data.message_id) === String(messageId) ? value : message[property]
        }))
        : dialog.messages
    }))
    this.setDialogs(updater)
    this.setSelectedDialogs(updater)
  },

  deleteClientMessage(messageId, dialogId) {
    this.updateMessagePropertyInDialogsAndSelectedDialogs(messageId, dialogId, 'is_deleted', true)
  },

  deleteMessage(messageId, dialogId) {
    sendDeleteMessage(this.socket, messageId, dialogId)
    this.deleteClientMessage(messageId, dialogId)
  },

  clientMessageReaction(messageId, dialogId, emojiId = null) {
    this.updateMessagePropertyInDialogsAndSelectedDialogs(messageId, dialogId, 'emoji_id', emojiId)
  },

  messageReaction(messageId, dialogId, emojiId) {
    sendMessageReaction(this.socket, emojiId, messageId, dialogId)
    this.clientMessageReaction(messageId, dialogId, emojiId)
  },

  messageReactionRemove(messageId, dialogId) {
    sendMessageReactionRemove(this.socket, messageId, dialogId)
    this.clientMessageReaction(messageId, dialogId, null)
  }
}


const ChatProvider = ({children}) => {
  const auth = useAuth()
  const prevUser = useRef(null)
  const [dialogs, setDialogs] = useState([])
  const [selectedDialogs, setSelectedDialogs] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchingDialogs, setSearchingDialogs] = useState(false)
  const [fetchingMoreDialogs, setFetchingMoreDialogs] = useState(false)
  const [dialogsCursor, setDialogsCursor] = useState(null)
  const [hasMoreDialogs, setHasMoreDialogs] = useState(true)
  const onlineStatusesLastCheckedAt = useRef();

  function clearState() {
    setDialogs([])
    setSelectedDialogs([])
    setSearchQuery('')
    setSearchingDialogs(false)
    setFetchingMoreDialogs(false)
    setDialogsCursor(null)
    setHasMoreDialogs(true)
    onlineStatusesLastCheckedAt.current = null;
  }

  useEffect(() => {
    if (!auth.user) {
      chatHelper.destroy()
      clearState();
      prevUser.current = null;
      return;
    }

    if (prevUser.current) {
      if (prevUser.current.access_token === auth.user.access_token) {
        return
      } else if (prevUser.current.id === auth.user.id) {
        // `access_token` was refreshed
        chatHelper.refresh(auth.user.access_token);
        prevUser.current = auth.user;
        return
      }
    }

    prevUser.current = auth.user;
    chatHelper.init(
      auth.user.access_token,
      auth.user
    )

    fetchDialogs(searchQuery, dialogsCursor).then((response) => {
      setDialogsResponse(response)
    }).catch(err => {
    })
  }, [auth.user])

  useEffect(() => {
    chatHelper.updateStateVars({
      dialogs,
      selectedDialogs,
      setDialogs,
      setSelectedDialogs,
    })
  }, [dialogs, selectedDialogs, setDialogs, setSelectedDialogs])

  useEffect(() => {
    const lastCheckedAt = onlineStatusesLastCheckedAt.current?.getTime() || 0;
    const now = new Date().getTime();
    const elapsedSeconds = (now - lastCheckedAt) / 1000;
    const timeToWaitInSeconds = elapsedSeconds >= CHECK_ONLINE_STATUS_INTERVAL_SECONDS ?
      0
      : CHECK_ONLINE_STATUS_INTERVAL_SECONDS - elapsedSeconds;

    const timeoutId = setTimeout(() => {
      chatHelper.findOnlineStatuses();
      onlineStatusesLastCheckedAt.current = new Date();
    }, timeToWaitInSeconds * 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [selectedDialogs, setSelectedDialogs])

  function selectDialog(dialogId, dialogTitle, dialogImageData) {
    dialogId = String(dialogId)

    // if dialog is already opened, do nothing
    if (selectedDialogs.some((e) => String(e.id) === String(dialogId))) {
      return;
    }

    // find dialog from current dialogs or create new one
    let dialog = null;
    if (!dialogs.some((e) => String(e.id) === String(dialogId))) {
      dialog = createNewDialogByDialogIdAndTitle(dialogId, dialogTitle, dialogImageData)
      dialog = addPropsToDialog(dialog)
      setDialogs(prev => [{...dialog}, ...prev])
    } else {
      dialog = dialogs.find((e) => String(e.id) === String(dialogId))
    }

    // We shouldn't show more than 3 opened boxes for large screens.
    // Below logics calculate the number of opened boxes and removes the last one if needed.
    let allSelectedDialogs = [dialog, ...selectedDialogs];
    const screenWidth = window.screen.availWidth;
    if (screenWidth <= 900) {
      setSelectedDialogs(allSelectedDialogs.slice(0, 1))
    } else if (screenWidth <= 1400) {
      setSelectedDialogs(allSelectedDialogs.slice(0, 2))
    } else {
      setSelectedDialogs(allSelectedDialogs.slice(0, 3))
    }

    if (dialog.unread > 0) {
      // mark all messages read in this dialog
      chatHelper.markDialogMessagesAsRead(dialog.id)
    }

    // Fetch messages for this dialog
    // We should fetch messages only if there are no messages in the dialog
    if (!dialog.hasFetchedMessages) {
      chatHelper.getDialogMessages(dialog)
    }

    if (String(auth.user.id) !== String(dialogId)) {
      chatHelper.findUserOnlineStatus(dialogId)
    } else {
      chatHelper.updateUserOnlineStatus(auth.user.id, true)
    }
  }

  function closeDialog(id) {
    id = String(id)
    setSelectedDialogs(prev => prev.filter((e) => String(e.id) !== id))
    setDialogs(prev => prev.map(dialog => {
      if (String(dialog.id) === id) {
        // save only first request results and remove other messages
        // because rendering all messages for the next opening of the dialog is very slow
        if (dialog.firstRequestResults !== null) {
          return saveOnlyDialogFirstRequestMessages(dialog)
        } else {
          return resetDialogMessages(dialog)
        }
      }
      return dialog
    }))
  }

  async function searchDialogs(query) {
    if (searchingDialogs) {
      return;
    }

    setHasMoreDialogs(true)
    setSearchQuery(query)
    setDialogsCursor(null)
    setSearchingDialogs(true)
    try {
      const response = await fetchDialogs(query)
      setDialogsResponse(response)
    } catch (e) {
      showMessage(false, i18n.t("Something went wrong. Please, try again"))
    }
    setSearchingDialogs(false)
  }

  function setDialogsResponse({cursor, dialogs}, append = false) {
    const updatedDialogs = dialogs.map(dialog => addPropsToDialog(dialog))
    if (append) {
      setDialogs(prev => [...prev, ...updatedDialogs])
    } else {
      setDialogs(updatedDialogs)
    }
    setDialogsCursor(cursor)
    setHasMoreDialogs(!!cursor)
  }

  async function fetchMoreDialogsHandler() {
    if (fetchingMoreDialogs || !hasMoreDialogs) {
      return;
    }

    setFetchingMoreDialogs(true)
    try {
      const response = await fetchDialogs(searchQuery, dialogsCursor)
      setDialogsResponse(response, true)
    } catch (e) {
      showMessage(false, i18n.t("Something went wrong. Please, try again"))
    }
    setFetchingMoreDialogs(false)
  }

  const values = {
    openedDialogs: selectedDialogs,
    dialogs: dialogs,
    openDialog: selectDialog,
    closeDialog: closeDialog,
    getDialogMessages: chatHelper.getDialogMessages.bind(chatHelper),
    sendMessage: chatHelper.performSendingMessage.bind(chatHelper),
    startTyping: chatHelper.startTyping.bind(chatHelper),
    stopTyping: chatHelper.stopTyping.bind(chatHelper),
    searchingDialogs,
    searchDialogs,
    fetchingMoreDialogs,
    fetchMoreDialogs: fetchMoreDialogsHandler,
    deleteMessage: chatHelper.deleteMessage.bind(chatHelper),
    messageReaction: chatHelper.messageReaction.bind(chatHelper),
    messageReactionRemove: chatHelper.messageReactionRemove.bind(chatHelper),
  }
  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>
}

export {ChatContext, ChatProvider}
