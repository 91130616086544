import {styled} from "@mui/material";
import {default as MuiBadge} from "@mui/material/Badge";


const Badge = styled(MuiBadge)(({theme}) => ({
  padding: '.15rem .5rem',
  background: "rgba(217, 239, 246, 0.44) !important",
  color: "#919aa1 !important",
  fontSize: '.76rem !important',
  borderRadius: '.25rem',
  fontWeight: 'normal',
  fontFamily: '"Helvetica" !important',
}));

export default Badge;