// noinspection JSValidateTypes

import {useEffect, useRef, useState} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import ClearIcon from '@mui/icons-material/Clear';
import {MessageTypes, msgTypeEncoder} from "../../../screens/chat/types";

import "./index.css"
import i18n from "@core/configs/i18n";
import {MAX_TIME_FOR_WAIT_TO_CALL, CALL_PARAMS} from "./constants";

export default function VideoP2P({socket, callTo, onCallStart}) {
  const socketRef = useRef(socket)
  const [incomingCallData, setIncomingCallData] = useState(null)
  const delayedEndCallRef = useRef()

  useEffect(() => {
    if (delayedEndCallRef.current) {
      clearTimeout(delayedEndCallRef.current)
      delayedEndCallRef.current = null
    }

    if (incomingCallData) {
      delayedEndCallRef.current = setTimeout(() => {
        rejectCall()
        delayedEndCallRef.current = null
      }, MAX_TIME_FOR_WAIT_TO_CALL)
    }
  }, [incomingCallData])

  useEffect(() => {
    if (callTo) {
      makeACall();
      onCallStart();
    }
  }, [callTo])

  socketRef.current.onmessage = (event) => {
    handleSocketMessage(event)
  }

  const handleSocketMessage = async (event) => {
    const data = JSON.parse(event.data)
    const msgType = data['msg_type']

    const acceptedTypes = [
      MessageTypes.CallMessageOfferStart,
      MessageTypes.CallMessageAccept,
    ]
    if (!acceptedTypes.includes(msgType)) {
      return
    }

    const onOfferStart = async (data) => {
      if (incomingCallData !== null) {
        rejectCall(i18n.t("User is in another call"))
      } else {
        const {from_user = null} = data
        setIncomingCallData({from_user})
      }
    }

    const onAccept = async (data) => {
      const {to_id = null} = data
      if (incomingCallData && String(to_id) === String(incomingCallData.from_user.id)) {
        setIncomingCallData(null);
      }
    }

    const mapHandlers = {
      [MessageTypes.CallMessageOfferStart]: onOfferStart,
        [MessageTypes.CallMessageAccept]: onAccept
    }

    try {
      const handler = mapHandlers[msgType]
      if (handler) {
        handler(data)
      }
    } catch (e) {
      setIncomingCallData(null);
    }
  }

  const makeACall = () => {
    window.open(
        `#/call-to?user_id=${callTo.id}`,
        '_blank',
        CALL_PARAMS
    );
  }

  const acceptCall = async () => {
    window.open(
        `#/accept-call?user_id=${incomingCallData.from_user.id}`,
        '_blank',
        CALL_PARAMS
    );
    setIncomingCallData(null);
  }

  const rejectCall = (reason = null) => {
    socketRef.current.send(
      msgTypeEncoder(
          MessageTypes.CallMessageReject, {
            from_user_id: String(incomingCallData.from_user.id),
            reason
          })
    )
    setIncomingCallData(null)
  }

  return <>
    {(incomingCallData !== null) ?
      <Box className='video-p2p video-p2p-blur'>
        <Box className='video-p2p-incoming-call'>
          <Typography variant='h5'>
            <b> {incomingCallData.from_user.name} </b>
            {i18n.t('is calling you')}
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconButton color='success' onClick={acceptCall}>
              <CallIcon fontSize='large'/>
            </IconButton>
            <IconButton color='error' sx={{mx: 1}} onClick={e => rejectCall(i18n.t("User cancelled the call"))}>
              <ClearIcon fontSize='large'/>
            </IconButton>
          </Box>
        </Box>
      </Box> : null}
  </>
}