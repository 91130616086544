import {useAuth} from "hooks/useAuth";
import React, {useEffect, useRef, useState} from "react";
import {
  getFormObject,
  getInputChangeHandler,
  setFormErrors,
  showMessage,
  readSourceFile,
  getUserOriginalOrDefaultImage, USER_DEFAULT_IMAGE
} from "@core/utils";
import userService from "../User.service";
import i18n from "@core/configs/i18n";
import Modal from "components/modal";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";

export default function EditScreen({open, onUpdate, onClose}) {
  const {user, setUserInfo} = useAuth()

  const userPhotoRef = useRef();
  const [userImageFile, setUserImageFile] = useState();
  const [userImage, setUserImage] = useState();

  const [editingProfile, setEditingProfile] = useState(false)
  const [formInput, setFormInput] = useState({
    id_number: {value: user.id_number || '', error: ''},
    name: {value: user.name || '', error: ''},
    about: {value: user.about || '', error: ''},
    is_provider: {value: user.is_provider, error: ''},
    is_buyer: {value: user.is_buyer, error: ''},
    is_transiter: {value: user.is_transiter, error: ''},
  })

  useEffect(() => {
    if (open) {
      setUserImage(getUserOriginalOrDefaultImage(user, 'image_data'))
      setUserImageFile(null)
    }
  }, [open])

  async function updateProfile() {
    setFormErrors(null, setFormInput)
    setEditingProfile(true)

    const productData = getFormObject(formInput);
    const formData = new FormData();
    for (const [key, value] of Object.entries(productData)) {
      formData.append(key, value)
    }

    if (userImageFile && userImageFile.name && userImageFile.size) {
      formData.append('image', userImageFile)
    } else if (!userImage) {
      formData.append('has_deleted_image', "1")
    }

    const {ok, result, errors} = await userService.updateProfile(formData)
    if (ok) {
      showMessage(true, i18n.t("Profile updated successfully"))
      onUpdate(result)
      onClose()
      setUserInfo(result)
    } else {
      setFormErrors(errors, setFormInput)
      if (errors.image) {
        showMessage(false, errors.image)
      }
    }
    setEditingProfile(false)
  }

  const showUploadedUserImage = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const src = await readSourceFile(file);
    if (src) {
      setUserImageFile(file)
      setUserImage(src)
    } else {
    }
  }

  return <>
    <Modal
      open={open}
      onSubmit={e => updateProfile()}
      onClose={e => onClose()}
      loading={editingProfile}
      title={i18n.t("Edit profile")}
      noPadding='true'
    >
      <Box>
        <Box className='profile-image-box'>
          <Box sx={{background: 'lightgrey', position: 'relative'}}>
            <Box
              sx={{
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}>
              <img src={userImage || USER_DEFAULT_IMAGE} style={{width: '100%'}}/>
            </Box>
            <Box sx={{position: 'absolute', top: '1rem', left: '50%', transform: 'translateX(-50%)'}}>
              <Box sx={{display: 'flex'}}>
                <Button
                  variant='contained'
                  size='small' sx={{mx: 1}}
                  onClick={e => userPhotoRef.current.click()}>
                  {i18n.t("Change")}
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='error'
                  onClick={e => setUserImage(null) & setUserImageFile(null)}>
                  {i18n.t("Remove")}
                </Button>
              </Box>
            </Box>
            <input
              ref={userPhotoRef}
              style={{display: 'none'}}
              type="file"
              accept="image/*"
              onChange={e => showUploadedUserImage(e)}
            />
          </Box>
        </Box>
        <Box sx={{m: 2}}>
          <form onSubmit={e => {
            e.preventDefault()
            updateProfile()
          }}>
            <TextField
              label={i18n.t("Name")}
              value={formInput.name.value}
              onChange={getInputChangeHandler('name', setFormInput)}
              error={!!formInput.name.error}
              helperText={formInput.name.error}
              fullWidth
              sx={{my: 1}}
            />
            <TextField
              label={i18n.t("Identification number")}
              value={formInput.id_number.value}
              onChange={getInputChangeHandler('id_number', setFormInput)}
              error={!!formInput.id_number.error}
              helperText={formInput.id_number.error}
              fullWidth
              sx={{my: 1}}
            />

            <TextField
              label={i18n.t("About")}
              value={formInput.about.value}
              onChange={getInputChangeHandler('about', setFormInput)}
              error={!!formInput.about.error}
              helperText={formInput.about.error}
              fullWidth
              multiline
              rows={3}
              sx={{my: 1}}
            />

            <FormGroup>
              <FormControlLabel
                checked={formInput.is_provider.value}
                onChange={e => getInputChangeHandler('is_provider', setFormInput)(e.target.checked, false)}
                control={<Checkbox/>}
                label={i18n.t("I am a provider")}/>

              <FormControlLabel
                checked={formInput.is_buyer.value}
                onChange={e => getInputChangeHandler('is_buyer', setFormInput)(e.target.checked, false)}
                control={<Checkbox/>}
                label={i18n.t("I am a buyer")}/>

              <FormControlLabel
                checked={formInput.is_transiter.value}
                onChange={e => getInputChangeHandler('is_transiter', setFormInput)(e.target.checked, false)}
                control={<Checkbox/>}
                label={i18n.t("I am a carrier")}/>
            </FormGroup>

            <button type='submit' style={{display: 'none'}}></button>
          </form>
        </Box>
      </Box>

    </Modal>
  </>
}