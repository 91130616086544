import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

import "./@core/configs/i18n";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ChatProvider} from "./contexts/ChatContext";
import {AuthProvider} from "./contexts/AuthContext";
import {ImagesProvider} from "./contexts/ImagesContext";
import BackgroundImagesModal from "./components/background-images-modal";
import {ProductSettingsProvider} from "./contexts/ProductSettingsContext";
import {P2PVideoProvider} from "./contexts/P2PVideoContext";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import {ProductFiltersProvider} from "./contexts/ProductFiltersContext";
init({data})

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f7e16'
    },
    secondary: {
      main: '#5a5a5a'
    }
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <HashRouter>
        <ImagesProvider>
          <ProductSettingsProvider>
            <AuthProvider>
              <ChatProvider>
                <P2PVideoProvider>
                  <ProductFiltersProvider>
                    <DndProvider backend={HTML5Backend}>
                      <App/>
                    </DndProvider>
                  </ProductFiltersProvider>
                </P2PVideoProvider>
              </ChatProvider>
            </AuthProvider>
            <BackgroundImagesModal/>
          </ProductSettingsProvider>
        </ImagesProvider>
      </HashRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
