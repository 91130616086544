import {Box, Button, TextField, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {detectCountryFromPhoneNumber, getFirstErrorString, getInputChangeHandler, showMessage} from "@core/utils";
import React, {useState} from "react";
import {useAuth} from "hooks/useAuth";
import Loader from "components/Loader";
import userService from "../../../user/User.service";
import MuiPhoneNumber from "material-ui-phone-number";
import {DEFAULT_COUNTRY_CODE, SUPPORTED_COUNTRY_CODES} from "constants.js";


export default function AccountPhone() {
  const auth = useAuth();

  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState({
    phone_number: {value: auth.user.phone_number, error: ''}
  })

  const updatePhoneHandler = async (e) => {
    e.preventDefault();
    setSaving(true);
    const phoneNumber = formInput.phone_number.value.replaceAll(/[\s()-]/g, '');
    const {ok, errors} = await userService.updateCredentials({phone_number: phoneNumber})
    if (ok) {
      showMessage(true, i18n.t("Phone number updated successfully"))
      auth.setUserInfo({...auth.user, phone_number: formInput.phone_number.value})
    }  else {
      if (errors.phone_number) {
        setFormInput(prev => ({
          ...prev,
          phone_number: {
            ...prev.phone_number,
            error: errors.phone_number
          }
        }))
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
    }
    setSaving(false);
  }

  return <Box className='account-phone-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Change phone number")}
    </Typography>
    <form onSubmit={updatePhoneHandler}>
      <Box sx={{mb: 2, bg: 'white'}}>
        <MuiPhoneNumber
          autoFormat={true}
          defaultCountry={detectCountryFromPhoneNumber(formInput.phone_number.value) || DEFAULT_COUNTRY_CODE}
          variant="outlined"
          label={i18n.t("Phone Number")}
          value={formInput.phone_number.value}
          onChange={getInputChangeHandler("phone_number", setFormInput, false)}
          error={Boolean(formInput.phone_number.error)}
          helperText={formInput.phone_number.error}
          fullWidth
          sx={{mt: 2}}
        />
      </Box>

      <Button
        disabled={saving}
        variant='contained'
        onClick={updatePhoneHandler}
        sx={{py: .5, px: 2}}
      >
        {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
        {i18n.t("Update")}
      </Button>
    </form>

  </Box>
}