// ** React Imports
import {createContext, useRef, useState} from 'react'
import {CALL_CONSTRAINTS, CALL_SERVERS} from "../components/chat/video-p2p/constants";
import {MessageTypes, msgTypeEncoder} from "../screens/chat/types";

// ** Defaults
const defaultProvider = {
    video1Ref: {current: null},
    video2Ref: {current: null},
    localStreamRef: {current: null},
    remoteStreamRef: {current: null},
    peerConnectionRef: {current: null},
    socketRef: {current: null},
    gotSecondUser: false,
    audioEnabled: false,
    videoEnabled: false,
    setGotSecondUser: (newValue) => null,
    setAudioEnabled: (newValue) => null,
    setVideoEnabled: (newValue) => null,
    endCall: () => null,
    turnOnCamera: () => null,
    toggleMic: () => null,
    toggleCamera: () => null,
    endCallIfSecondUserLeaves: () => null,
    createRemoteStream: () => null,
    createPeerConnection: () => null,
}
const P2PVideoContext = createContext(defaultProvider)

const P2PVideoProvider = ({children}) => {
    const video1Ref = useRef()
    const video2Ref = useRef()
    const localStreamRef = useRef()
    const remoteStreamRef = useRef()
    const peerConnectionRef = useRef()
    const socketRef = useRef(null);

    const [gotSecondUser, setGotSecondUser] = useState(false);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);

    const endCall = () => {
        if (remoteStreamRef.current) {
            try {
                remoteStreamRef.current.getTracks().forEach((track) => {
                    track.stop()
                })
            } catch (e) {
                //
            }
        }
        window.close();
    }

    const turnOnCamera = async () => {
        if (!localStreamRef.current) {
            const localStream = await navigator.mediaDevices.getUserMedia(CALL_CONSTRAINTS)
            video1Ref.current.srcObject = localStream
            localStreamRef.current = localStream
        }
    }

    const toggleMic = async () => {
        try {
            const localStream = localStreamRef.current;
            let audioTrack = localStream.getTracks().find(track => track.kind === 'audio')
            if (audioTrack.enabled) {
                audioTrack.enabled = false
                setAudioEnabled(false)
            } else {
                audioTrack.enabled = true
                setAudioEnabled(true)
            }
        } catch (e) {
            //
        }
    }

    const toggleCamera = async () => {
        try {
            const localStream = localStreamRef.current;
            const videoTrack = localStream.getTracks().find(track => track.kind === 'video')
            if (videoTrack.enabled) {
                videoTrack.enabled = false
                setVideoEnabled(false)
            } else {
                videoTrack.enabled = true
                setVideoEnabled(true)
            }
        } catch (e) {
            //
        }

    }

    const endCallIfSecondUserLeaves = () => {
        if (peerConnectionRef.current) {
            peerConnectionRef.current.oniceconnectionstatechange = function () {
                if (peerConnectionRef.current.iceConnectionState === 'disconnected') {
                    endCall();
                }
            }
        }
    }

    const createRemoteStream = () => {
        remoteStreamRef.current = new MediaStream()
        video2Ref.current.srcObject = remoteStreamRef.current
    }

    const createPeerConnection = async (user_pk) => {
        peerConnectionRef.current = new RTCPeerConnection(CALL_SERVERS)
        createRemoteStream()

        localStreamRef.current.getTracks().forEach((track) => {
            peerConnectionRef.current.addTrack(track, localStreamRef.current)
        })

        peerConnectionRef.current.ontrack = (event) => {
            // Got connection
            event.streams[0].getTracks().forEach((track) => {
                remoteStreamRef.current.addTrack(track)
            })
            setGotSecondUser(true)
            endCallIfSecondUserLeaves()
        }

        peerConnectionRef.current.onicecandidate = async (event) => {
            if (event.candidate) {
                const dataToSend = {'type': 'candidate', 'candidate': event.candidate, 'user_pk': String(user_pk)}
                socketRef.current.send(msgTypeEncoder(MessageTypes.CallMessageCandidate, dataToSend))
            }
        }
    }

    const values = {
        video1Ref,
        video2Ref,
        localStreamRef,
        remoteStreamRef,
        peerConnectionRef,
        socketRef,
        gotSecondUser,
        audioEnabled,
        videoEnabled,
        setGotSecondUser,
        setAudioEnabled,
        setVideoEnabled,
        endCall,
        turnOnCamera,
        toggleMic,
        toggleCamera,
        endCallIfSecondUserLeaves,
        createRemoteStream,
        createPeerConnection,
    }

    return <P2PVideoContext.Provider value={values}>{children}</P2PVideoContext.Provider>
}

export {P2PVideoContext, P2PVideoProvider}
