
export const CALL_CONSTRAINTS = {
    video: {
        width: {min: 640, ideal: 1920, max: 1920},
        height: {min: 480, ideal: 1080, max: 1080},
    },
    audio: true
}

export const CALL_SERVERS = {
    iceServers: [
        {
            urls: "stun:a.relay.metered.ca:80",
        },
        {
            urls: "turn:a.relay.metered.ca:80",
            username: process.env.REACT_APP_TURN_SERVER_USERNAME,
            credential: process.env.REACT_APP_TURN_SERVER_CREDENTIAL,
        },
        {
            urls: "turn:a.relay.metered.ca:443",
            username: process.env.REACT_APP_TURN_SERVER_USERNAME,
            credential: process.env.REACT_APP_TURN_SERVER_CREDENTIAL,
        },
        {
            urls: "turn:a.relay.metered.ca:443?transport=tcp",
            username: process.env.REACT_APP_TURN_SERVER_USERNAME,
            credential: process.env.REACT_APP_TURN_SERVER_CREDENTIAL,
        },
    ],
}


export const MAX_TIME_FOR_WAIT_TO_CALL = 5 * 60 * 1000 // end call after 30 seconds of no response

export const CALL_PARAMS = (function(){
    let width = 0;
    let height = 0;
    let left = 0;
    let top= 0;
    try {
        width = Math.min(window.screen.width, 1000);
        height = Math.min(window.screen.height, 800);
        left = (window.screen.width - width) / 2;
        top = (window.screen.height - height) / 2;
    } catch (e) {
        // pass
    }
    return `
      scrollbars=no,resizable=no,status=no,location=no,toolbar=no,
      menubar=no,width=${width},height=${height},left=${left},top=${top}
    `;
})()
