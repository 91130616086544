import {Box} from "@mui/material";
import i18n from "@core/configs/i18n";
import {useNavigate} from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyIcon from '@mui/icons-material/Key';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import RestoreIcon from '@mui/icons-material/Restore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UserInfo from "components/user-info";

export default function AccountSettings() {
  const navigate = useNavigate();
  return <Box className='account-settings-page'>
    <UserInfo
      avatar={<PersonOutlineIcon />}
      title={i18n.t("Account information")}
      subtitle={i18n.t("See your account information like your email and phone number")}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('account-information')}
      disableComponentClick={false}
    />
    <UserInfo
      avatar={<KeyIcon />}
      title={i18n.t("Change your password")}
      subtitle={i18n.t("Change your password at any time")}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('password')}
      disableComponentClick={false}
    />

    <UserInfo
      avatar={<InsertDriveFileIcon />}
      title={i18n.t("Registration certificate(s)")}
      subtitle={i18n.t("Download your registration certificate(s)")}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('certificates')}
      disableComponentClick={false}
    />

    <UserInfo
      avatar={<RestoreIcon />}
      title={i18n.t("Login history")}
      subtitle={i18n.t("See information like when you logged in, device type, browser and so on")}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('login-history')}
      disableComponentClick={false}
    />

    <UserInfo
      avatar={<PersonOffIcon />}
      title={i18n.t("Deactivate your account")}
      subtitle={i18n.t("Find out how you can deactivate your account")}
      subtitleColor='rgb(83, 100, 113)'
      rightContent={<KeyboardArrowRightIcon />}
      onClick={() => navigate('deactivate')}
      disableComponentClick={false}
    />
  </Box>
}