import {Box, Grid, Paper, Typography, useTheme} from "@mui/material";
import i18n from "@core/configs/i18n";
import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AppsIcon from '@mui/icons-material/Apps';
import {
  getDateDiffString,
  getImagesOrDefaultImages,
  getMessageSubstring,
  getThumbnailOrOriginalImage,
  valueOrNA
} from "@core/utils";
import ImagesCarousel from "./images-carousel";
import Product from "./Product";
import Currency from "./currency";

export default function ProductCard({product, onClick}) {
  const theme = useTheme()
  return <>
    <Box sx={{display: {xs: 'block', sm: 'none'}}}>
      <Product product={product} onClick={onClick}/>
    </Box>
    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
      <Paper>
        <Box sx={{p: 2}}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Box className='product-image' sx={{cursor: 'pointer'}}>
                <Box sx={{display: {sm: 'block', xs: 'none'}}}>
                  <ImagesCarousel
                    images={getImagesOrDefaultImages(product.images)}
                    getImageFn={(item) => getThumbnailOrOriginalImage(item)}
                    height='120px'
                    width='auto'
                    onImageClick={onClick}
                    openImageOnClick={false}
                  />
                </Box>
                <Box sx={{display: {sm: 'none', xs: 'block'}}}>
                  <ImagesCarousel
                    images={getImagesOrDefaultImages(product.images)}
                    getImageFn={(item) => getThumbnailOrOriginalImage(item)}
                    height='100%'
                    width='auto'
                    onImageClick={onClick}
                    openImageOnClick={false}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container justifyContent='between' sx={{px: 2}}>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                  <Box sx={{display: 'flex'}}>
                    <Typography variant='body1' sx={{cursor: 'pointer'}} onClick={onClick}>
                      {getMessageSubstring(product.name, 60)}
                    </Typography>
                    <Typography variant='body1' sx={{color: theme.palette.secondary.light, mx: 1}}>
                      {product.year}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                  <Box justifyContent='end' sx={{display: 'flex'}}>
                    {product.customs_clearance !== null && (
                      product.customs_clearance === true ? (
                        <Typography variant={'caption'} color='green'>
                          {i18n.t("Customs cleared")}
                        </Typography>
                      ) : (
                        <Typography variant={'caption'} color='red'>
                          {i18n.t("Customs not cleared")}
                        </Typography>
                      )
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container sx={{justifyContent: 'center', mt: 2, px: 2}}>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                  <Grid container>
                    <Grid item sm={6} sx={{display: 'flex'}}>
                      <LocationOnIcon htmlColor='grey' sx={{transform: 'translateY(.25rem)'}}/>
                      <Typography variant='caption' sx={{mx: 1, mt: 1}}>
                        {valueOrNA(product.location_data?.name)}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} sx={{display: 'flex'}}>
                      <PersonOutlineIcon sx={{transform: 'translateY(.25rem)'}}/>
                      <Typography variant='caption' sx={{mt: 1}}>
                        {getMessageSubstring(product.user_data?.name, 35)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Box sx={{textAlign: 'right'}}>
                    <Typography variant='h5'>
                      {product.price}
                      <Currency code={product.currency_code} fontSize='1.5rem'/>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent='between' sx={{mb: 2, px: 2}}>
                <Grid item md={12} lg={8}>
                  <Grid container>
                    <Grid item sm={12} sx={{display: 'flex'}}>
                      <AppsIcon htmlColor='grey' sx={{transform: 'translateY(.25rem)'}}/>
                      <Typography variant='caption' sx={{mx: 1, mt: 1}}>
                        {product.category || i18n.t('N/A')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} lg={4}>
                  <Box sx={{textAlign: 'right'}}>
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent='between' sx={{py: 0, px: 2, mt: 1}}>
                <Grid item md={12} lg={8}>
                  <Grid container>
                    <Typography variant='caption' sx={{color: theme.palette.secondary.light}}>
                      {product.seen_count} {i18n.t("views")}
                    </Typography>
                    <Typography variant='caption' sx={{color: theme.palette.secondary.light, mx: 1}}>
                      {' - '}
                      {getDateDiffString(product.created_at)} {' '}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  </>
}