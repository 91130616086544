import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {getQueryParamFromUrl, getUserToken, showMessage} from "../../@core/utils";
import ReconnectingWebSocket from "reconnecting-websocket";
import {MessageTypes, msgTypeEncoder} from "../chat/types";

import "components/chat/video-p2p/index.css"
import {useP2PVideo} from "../../hooks/useP2PVideo";
import VideoUI from "../../components/chat/video-p2p/VideoUI";
import i18n from "../../@core/configs/i18n";
import {create_ws_endpoint} from "../chat/send";

export default function CallTo() {
    const [userId, setUserId] = useState(null);
    const location = useLocation();

    const {
        video1Ref,
        video2Ref,
        peerConnectionRef,
        socketRef,
        setGotSecondUser,
        gotSecondUser,
        audioEnabled,
        videoEnabled,
        endCall,
        toggleMic,
        toggleCamera,
        turnOnCamera,
        createPeerConnection,
    } = useP2PVideo();

    useEffect(() => {
        const userId = getQueryParamFromUrl(location.search, 'user_id')
        if (userId) {
            setUserId(userId);
            const accessToken = getUserToken();
            if (accessToken) {
                socketRef.current = new ReconnectingWebSocket(create_ws_endpoint(accessToken));
            }
            turnOnCamera()
        }
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        }
    }, [])

    if (socketRef.current) {
        const socket = socketRef.current;
        socket.onopen = (e) => {
            if (socket.readyState === socket.OPEN) {
                socketRef.current.send(msgTypeEncoder(MessageTypes.CallMessageOfferStart, {to_id: userId}))
            }
        }
        socket.onmessage = (e) => {
            handleMessage(e)
        }

        async function onAcceptReceive(data) {
            const {from_user = {}} = data
            if (String(userId) === String(from_user.id)) {
                await createOffer(userId)
            }
        }

        function onAnswerReceive(data) {
            const {from_user} = data;
            if (String(userId) === String(from_user.id)) {
                addAnswer(data.answer)
            }
        }

        function onIceCandidateReceive(data) {
            const {from_user} = data
            if (String(from_user.id) === String(userId)) {
                peerConnectionRef.current.addIceCandidate(data.candidate)
            }
        }

        function handleRejectReceive(data) {
            const {from_user} = data
            if (String(from_user.id) === String(userId)) {
                endCall()
                const reason = data.reason ? data.reason : i18n.t('User cancelled the call')
                showMessage(false, reason)
            }
        }

        const acceptedTypes = [
            MessageTypes.CallMessageAccept,
            MessageTypes.CallMessageAnswer,
            MessageTypes.CallMessageCandidate,
            MessageTypes.CallMessageReject
        ]
        const handleMessage = (e) => {
            const data = JSON.parse(e.data)
            const msgType = data['msg_type']
            if (!acceptedTypes.includes(msgType)) {
                return
            }

            const mapHandlers = {
                [MessageTypes.CallMessageCandidate]: onIceCandidateReceive,
                [MessageTypes.CallMessageAccept]: onAcceptReceive,
                [MessageTypes.CallMessageAnswer]: onAnswerReceive,
                [MessageTypes.CallMessageReject]: handleRejectReceive,
            }

            try {
                const handler = mapHandlers[msgType]
                if (handler) {
                    handler(data)
                }
            } catch (e) {
                endCall()
            }
        }
    }

    const addAnswer = (answer) => {
        if (!peerConnectionRef.current.currentRemoteDescription) {
            setGotSecondUser(true);
            peerConnectionRef.current.setRemoteDescription(answer)
        }
    }

    const createOfferObject = async () => {
        return await peerConnectionRef.current.createOffer()
    }

    const sendOffer = (to_id, offer) => {
        const socketData = {'type': 'offer', 'offer': offer, 'user_pk': to_id}
        socketRef.current.send(msgTypeEncoder(MessageTypes.CallMessageOffer, socketData))
    }

    const createOffer = async (user_pk) => {
        await turnOnCamera()
        await createPeerConnection(user_pk)
        const offer = await createOfferObject();
        await peerConnectionRef.current.setLocalDescription(offer)
        sendOffer(user_pk, offer)
    }

    return <VideoUI
        video1Ref={video1Ref}
        video2Ref={video2Ref}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
        toggleMic={toggleMic}
        toggleCamera={toggleCamera}
        endCall={endCall}
        gotSecondUser={gotSecondUser}
    />
}