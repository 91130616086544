import getUnicodeFlagIcon from "country-flag-icons/unicode";

export const DEFAULT_AVATAR_URL = './images/profile-image.png';

export const DEFAULT_PAGE_SIZE = 16;

// EVENTS
export const EVENT_GLOBAL_SCROLL_TO_BOTTOM = 'global:scroll-to-bottom';

export const DEFAULT_COUNTRY_CODE = 'ge';
export const SUPPORTED_COUNTRY_CODES = ['ge', 'ru', 'fr', 'tr'];

export const SUPPORTED_COUNTRY_CODE_PREFIXES = [
  {code: 'ge', prefix: '+995'},
  {code: 'ru', prefix: '+7'},
  {code: 'fr', prefix: '+33'},
  {code: 'tr', prefix: '+90'},
]

export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_PDF = 'pdf';
export const FILE_TYPE_WORD = 'word';
export const FILE_TYPE_OTHER = 'other';

export const FILE_TYPES = {
  [FILE_TYPE_IMAGE]: ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],
  [FILE_TYPE_PDF]: ['application/pdf'],
  [FILE_TYPE_WORD]: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.text-template',
  ],
  [FILE_TYPE_OTHER]: ['*'],
}

export const FILE_TYPES_MAPPER = (function () {
  /**
   * @type {{
   *   'image/jpeg': 'image',
   *   'application/pdf': 'pdf',
   *   'application/msword': 'word',
   *   [String]: [String]
   * }}
   */
  const mapper = {};
  for (const [type, types] of Object.entries(FILE_TYPES)) {
    for (const t of types) {
      mapper[t] = type;
    }
  }
  return mapper;
})();

export const LANGUAGES = [
  {
    code: 'en',
    title: `${getUnicodeFlagIcon('US')} EN`
  },
  {
    code: 'fr',
    title: `${getUnicodeFlagIcon('FR')} FR`
  },
  {
    code: 'ge',
    title: `${getUnicodeFlagIcon('GE')} GE`
  },
  {
    code: 'ru',
    title: `${getUnicodeFlagIcon('RU')} RU`
  },
  {
    code: 'tr',
    title: `${getUnicodeFlagIcon('TR')} TR`
  },
]