import i18n from "@core/configs/i18n";
import {Tag, MailOutline} from "@mui/icons-material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import RoofingIcon from '@mui/icons-material/Roofing';
import SettingsIcon from '@mui/icons-material/Settings';

export default [
  {
    title: i18n.t("Home"),
    page: "home",
    exists: false,
    icon: RoofingIcon,
  },
  {
    title: i18n.t("Explore"),
    page: "explore",
    icon: Tag,
  },
  {
    title: i18n.t("Notifications"),
    page: "notifications",
    icon: NotificationsNoneIcon,
  },
  {
    title: i18n.t("Messages"),
    page: "messages",
    icon: MailOutline,
  },
  {
    title: i18n.t("Bookmarks"),
    page: "bookmarks",
    icon: BookmarkBorderIcon,
  },
  {
    title: i18n.t("Account settings"),
    page: "account-settings",
    icon: SettingsIcon,
    exists: true,
  },
]