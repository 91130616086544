import React, {Fragment, useEffect, useState} from "react";

import Loader from "components/Loader";
import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from "@core/configs/i18n";
import {
  Box,
  Button, IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, Typography
} from "@mui/material";
import {getFirstErrorString, getImagesOrDefaultImages, getThumbnailOrOriginalImage, valueOrNA} from "@core/utils";
import {Link, useNavigate} from "react-router-dom";
import userService from "../user/User.service";
import {showMessage} from "@core/utils";
import ImagesCarousel from "components/images-carousel";
import Currency from "components/currency";

const ROWS_PER_PAGE_OPTIONS = [5, 8, 15, 25, 50];

function UserProductListScreen() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [deletingProductId, setDeletingProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  useEffect(() => {
    fetchProducts(page)
  }, []);

  const fetchProducts = (page, size = perPage) => {
    setLoading(true);
    userService.getProducts(page, size).then(({ok, result, errors}) => {
      if (ok) {
        const {results, count} = result;
        setProducts(results);
        setCount(count);
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
      setLoading(false);
    })
  }

  /* HANDLER */
  const deleteHandler = async (id) => {
    if (window.confirm(i18n.t("Are you sure you want to delete this product ?"))) {
      setDeletingProductId(id);
      const {ok} = await userService.deleteProduct(id);
      if (ok) {
        setProducts(products.filter((product) => product.id !== id));
      } else {
        showMessage(false, i18n.t("Can't delete product. Please, try again"));
      }
      setDeletingProductId(null);
    }
  };

  const createProductHandler = () => {
    navigate("../product/create");
  };

  return (
    <Box sx={{m: 3}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Box>
          <h1>{i18n.t("Products")}</h1>
        </Box>

        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={createProductHandler}
            sx={{my: 2, textTransform: 'none'}}
          >
            <AddIcon/>
            {i18n.t("Create Product")}
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper} sx={{p: 2}}>
          <Table sx={{minWidth: 650}}>
            <TableHead>
              <TableRow>
                <TableCell className='fw-bolder'>#</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("IMAGE")}</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("NAME")}</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("PRICE")}</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("CATEGORY")}</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("ADVERTISEMENT CODE")}</TableCell>
                <TableCell className='fw-bolder' align="center">{i18n.t("ACTIONS")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Loader/>
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {products.map((product, index) => (
                    <TableRow
                      key={`product-${product.id}`}
                      sx={{
                        '&:last-child td, &:last-child th': {border: 0},
                        background: (deletingProductId === product.id ? '#e6e4e4' : 'white')
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell width='15%' component="th" scope="row">
                        <Box sx={{height: '10rem', minWidth: '150px'}}>
                          <ImagesCarousel
                            images={getImagesOrDefaultImages(product.images)}
                            getImageFn={(item) => getThumbnailOrOriginalImage(item)}
                            getOriginalImageFn={(item) => item.data.original}
                            height='8rem'
                            width='auto'
                          />
                        </Box>
                      </TableCell>
                      <TableCell width='15%' align="center">{product.name}</TableCell>
                      <TableCell width='15%' align="center">
                        {product.price}
                        <Currency code={product.currency_code}/>
                      </TableCell>
                      <TableCell width='15%' align="center">{valueOrNA(product.category)}</TableCell>
                      <TableCell width='20%' align="center">{product.announcement_code}</TableCell>
                      <TableCell width='15%' align="center">
                        <Link to={`../product/${product.id}/edit`}>
                          <IconButton
                            color='primary'
                            disabled={deletingProductId === product.id}
                          >
                            <BorderColorIcon fontSize='small'/>
                          </IconButton>
                        </Link>

                        <IconButton
                          color='error'
                          onClick={() => deleteHandler(product.id)}
                          disabled={deletingProductId === product.id}
                        >
                          {deletingProductId === product.id ? (
                            <Loader size='1rem'/>
                          ) : (
                            <DeleteIcon fontSize='small'/>
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              )}
              {(!loading && products.length === 0) && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    {i18n.t("No products found")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={(_, page) => {
              setPage(Number(page) + 1)
              fetchProducts(Number(page) + 1, perPage)
            }}
            onRowsPerPageChange={ev => {
              setPerPage(Number(ev.target.value));
              setPage(1);
              fetchProducts(1, Number(ev.target.value))
            }}
            labelRowsPerPage={
              <Typography variant='inherit' sx={{mt: '.75rem'}}>
                {i18n.t("Rows per page")}
              </Typography>
            }
            labelDisplayedRows={({from, to, count}) => {
              return (
                <Typography variant='inherit' sx={{mt: '1rem'}}>
                  {from}-{to} ({count})
                </Typography>
              )
            }}
            SelectProps={{disabled: loading}}
            backIconButtonProps={{disabled: page - 1 === 0}}
            nextIconButtonProps={{disabled: page - 1 === Math.ceil(count / perPage) - 1}}
            sx={{mx: 3}}
          />
        </TableContainer>
      </Box>
    </Box>
  );
}

export default UserProductListScreen;
