import {useEffect, useRef} from "react";
import eventBus from "../../@core/event-bus";
import {EVENT_GLOBAL_SCROLL_TO_BOTTOM} from "../../constants";

export default function GlobalInfiniteScroll(
  {
    children,
    onScrollToBottom = () => {},
    hasMore = true
  }) {

  useEffect(() => {
    if (!hasMore) {
      return;
    }
    const scrollToBottomListener = () => {
      onScrollToBottom()
    }

    const listener = eventBus.on(EVENT_GLOBAL_SCROLL_TO_BOTTOM, scrollToBottomListener)

    return () => {
      eventBus.remove(EVENT_GLOBAL_SCROLL_TO_BOTTOM, listener)
    }
  }, [onScrollToBottom])

  return <>
    {children}
  </>
}