import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import {getFirstErrorString, getInputChangeHandler, setFormErrors, showMessage} from "@core/utils";
import {useAuth} from "hooks/useAuth";
import i18n from "@core/configs/i18n";
import userService from "../../user/User.service";
import {SITE_TITLE} from "config/main";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import AuthLayout from "../../../layouts/AuthLayout";

function LoginScreen() {
  /* STATE */
  const [formInput, setFormInput] = useState({
    email: {value: '', error: ''}, password: {value: '', error: ''},
  })
  const [loading, setLoading] = useState(false);
  const [showDeactivationBox, setShowDeactivationBox] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate()
  const auth = useAuth();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (location.search.includes('logout_user')) {
      auth.logout()
    } else {
      if (auth.user) {
        navigate(redirect);
      }
    }

  }, []);

  const activateAccountHandler = async () => {
    setLoading(true);
    const {ok, result, errors} = await userService.activateAccount(
      formInput.email.value,
      formInput.password.value
    );
    setLoading(false);
    if (ok) {
      setShowDeactivationBox(false);
      auth.setLoggedInUserInfo(result);
      showMessage(true, i18n.t("Account activated successfully"));
      navigate('/')
    } else {
      showMessage(false, getFirstErrorString(errors));
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    const {ok, result, errors} = await auth.login(formInput.email.value, formInput.password.value)
    setLoading(false);
    if (!ok) {
      // User is deactivated and can be activated
      if (errors.user_deactivation_error !== undefined) {
        setShowDeactivationBox(true);
      } else {
        setFormErrors(errors, setFormInput);
      }
    } else {
      navigate('/')
    }
  };

  return (
    <AuthLayout>
      <Paper sx={{maxWidth: {xs: '100%', md: '400px'}}}>
        <Box sx={{p: 4, pb: 1}}>
          {showDeactivationBox === false ? (
            <form onSubmit={submitHandler}>
              <TextField
                error={Boolean(formInput.email.error)}
                label={i18n.t("Email")}
                value={formInput.email.value}
                helperText={formInput.email.error}
                onChange={getInputChangeHandler('email', setFormInput)}
                fullWidth
                sx={{mb: 2, bg: 'white'}}
              />

              <TextField
                type={showPassword ? 'text' : 'password'}
                error={Boolean(formInput.password.error)}
                label={i18n.t("Password")}
                value={formInput.password.value}
                helperText={formInput.password.error}
                onChange={getInputChangeHandler('password', setFormInput)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {!showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
                sx={{mb: 2, bg: 'white'}}
              />

              <Button
                type="submit"
                variant='contained'
                color="primary"
                size='large'
                sx={{width: '100%', px: 5}}>
                {loading && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
                {i18n.t("Sign In")}
              </Button>

              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{textAlign: 'center', my: 2}}>
                  <Button
                    onClick={e => navigate('/forgot-password')}
                    sx={{textTransform: 'none'}}>
                    <Typography variant='body2'>
                      {i18n.t("Forgot password")}
                    </Typography>
                  </Button>
                </Box>
                <Box sx={{textAlign: 'center', mt: 2}}>
                  <Button
                    color='secondary'
                    onClick={e => navigate('/register')}
                    sx={{textTransform: 'none'}}>
                    <Typography variant='body2'>
                      {i18n.t("Create an account")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </form>
          ) : (
            <Box>
              <Typography variant='body'>
                {i18n.t("Your account has been deactivated, but you can still activate it.")}
              </Typography>
              <Button
                variant='contained'
                fullWidth
                size='large'
                sx={{mt: 4}}
                onClick={activateAccountHandler}
                disabled={loading}
              >
                {loading && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
                {i18n.t("Activate Account")}
              </Button>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                size='large'
                sx={{mt: 1, mb: 4}}
                onClick={() => {
                  getInputChangeHandler('email', setFormInput, false)('')
                  getInputChangeHandler('password', setFormInput, false)('')
                  setShowDeactivationBox(false)
                }}
              >
                {i18n.t("Back")}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </AuthLayout>
  );
}

export default LoginScreen;
