import "./index.css"
import {Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {Fragment, useEffect, useState} from "react";
import {getQueryParamFromUrl, showMessage} from "@core/utils";
import Loader from "components/Loader";
import UserInfo from "components/user-info";
import userService from "../../../User.service";
import GlobalInfiniteScroll from "components/global-infinite-scroll";
import {DEFAULT_PAGE_SIZE} from "constants.js";


const FOLLOWERS_PER_PAGE = DEFAULT_PAGE_SIZE;
export default function Followers({user, is_hidden}) {
  const [loading, setLoading] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchUsers(cursor)
  }, [])

  const fetchUsers = cursor => {
    if (loading) return;
    setLoading(true)
    userService.getFollowers(user.id, cursor, FOLLOWERS_PER_PAGE).then(({ok, result, errors}) => {
      if (!ok) {
        showMessage(false, i18n.t("Something went wrong. Please, refresh the page"))
      } else {
        const {results, next} = result
        setFollowers(prev => [...prev, ...results]);
        if (next) {
          const cursor = getQueryParamFromUrl(next, 'cursor');
          setCursor(cursor);
        } else {
          setHasMore(false);
        }
      }
      setLoading(false)
    })
  }

  return (
    <div className="followers-tab">
      <Fragment>
        {followers.length === 0 ? (
          <Typography sx={{textAlign: 'center', color: 'grey', mt: 2}}>
            {i18n.t("There are no users")}
          </Typography>
        ) : (
          <Fragment>
            <GlobalInfiniteScroll
              hasMore={hasMore}
              onScrollToBottom={() => fetchUsers(cursor)}>
              {followers.map(follow =>
                <UserInfo
                  key={follow.id}
                  data={follow}
                  underSubtitleContent={<Typography>{follow.about}</Typography>}
                />
              )}
            </GlobalInfiniteScroll>
            {loading && (
              <Loader size='3rem' mt='1rem'/>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}