import "./index.css"
import {Button} from "@mui/material";
import i18n from "@core/configs/i18n";
import userService from "../../screens/user/User.service";
import {getFirstErrorString, showMessage} from "@core/utils";
import {useEffect, useState} from "react";

export default function FollowButton(
  {
    following, userId, onUpdate = () => null
  }) {
  const [loading, setLoading] = useState(false)
  const [innerFollowing, setInnerFollowing] = useState(following)

  useEffect(() => {
    setInnerFollowing(following)
  }, [following])

  async function clickHandler(e) {
    e.preventDefault()
    e.stopPropagation()

    if (innerFollowing) {
      setLoading(true)
      const {ok, errors} = await userService.unfollowUser(userId)
      if (ok) {
        showMessage(true, i18n.t("You are not following this user anymore"))
        setInnerFollowing(false)
        onUpdate(false)
      } else {
        showMessage(false, getFirstErrorString(errors))
      }
      setLoading(false)
    } else {
      setLoading(true)
      const {ok, errors} = await userService.followUser(userId)
      if (ok) {
        showMessage(true, i18n.t("You are now following this user"))
        setInnerFollowing(true)
        onUpdate(true)
      } else {
        showMessage(false, getFirstErrorString(errors))
      }
      setLoading(false)
    }
  }

  return <>
    <Button
      variant='contained'
      color='secondary'
      size='small'
      className={`follow-button ` + (innerFollowing ? 'is-following-button' : 'is-not-following-button')}
      disabled={loading}
      onClick={clickHandler}
    >
      {innerFollowing ? i18n.t('Unfollow') : i18n.t('Follow')}
    </Button>
  </>
}