// ** React Imports
import {createContext, useRef, useState} from 'react'

// ** Defaults
const defaultProvider = {
  filterRef: {current: {}},
  pageRef: {current: 1},
}
const ProductFiltersContext = createContext(defaultProvider)

const ProductFiltersProvider = ({children}) => {
  const filterRef = useRef(defaultProvider.filterRef.current)
  const pageRef = useRef(defaultProvider.pageRef.current)

  const values = {
    filterRef,
    pageRef,
  }

  return <ProductFiltersContext.Provider value={values}>{children}</ProductFiltersContext.Provider>
}

export {ProductFiltersContext, ProductFiltersProvider}
