import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import i18n from "@core/configs/i18n";
import {useCallback, useEffect, useRef, useState} from "react";
import {useProductSettings} from "../../hooks/useProductSettings";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const MAX_OPTIONS_COUNT = 100; // Must be even number

export default function ProductCodesDropdown(
  {
    value,
    onChange,
    errorMessage,
    size = 'medium',
    showCheckboxes = true,
    fontSize = '1rem',
  }) {
  const {product_codes} = useProductSettings();

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [lastOptionIndex, setLastOptionIndex] = useState(0)

  const firstOptionRef = useRef();
  const listBoxRef = useRef();

  const lastScrollTop = useRef(0);

  useEffect(() => {
    const newOptions = filteredOptions.slice(0, MAX_OPTIONS_COUNT)
    setOptions([...newOptions]);
    setLastOptionIndex(newOptions.length);
    if (listBoxRef.current) {
      listBoxRef.current.scrollTop = 0;
    }
  }, [filteredOptions])

  useEffect(() => {
    setFilteredOptions(product_codes);
  }, [product_codes])

  useEffect(() => {
    setFilteredOptions(prev => {
      return product_codes.filter((option) => {
        const value = option.code + ' - ' + i18n.t(option.name);
        return value.toLowerCase().includes(filterValue.toLowerCase());
      })
    });
  }, [filterValue])

  const onListBoxRefChange = useCallback((node) => {
    if (node === null) {
      // nothing, node was unmounted
    } else {
      listBoxRef.current = node;
      if (lastScrollTop.current) {
        node.scrollTop = lastScrollTop.current;
        lastScrollTop.current = 0;
      }
    }
  }, [])
  /**
   * This function will make sure that the options are loaded when the user scrolls to the bottom of the list
   * And also when the user scrolls to the top of the list
   * So if we have 500 messages, we only show 100 at a time (because of performance reasons)
   * At first we show messages of this range: 0 - 100, then when the user scrolls to the bottom of the list
   * we show messages of this range: 100 - 200, then 200 - 300, etc.
   * If the user scrolls up, we'll show: 100 - 200 first and then 0 - 100
   */
  const handleScroll = e => {
    const node = e.currentTarget;
    const position = node.scrollTop + node.clientHeight;
    if (node.scrollTop === 0) {
      if (lastOptionIndex - MAX_OPTIONS_COUNT > 0) {
        const optionsAbove = lastOptionIndex - MAX_OPTIONS_COUNT;
        const toAddOptionsCount = Math.min(MAX_OPTIONS_COUNT / 2, optionsAbove);
        const newOptions = filteredOptions.slice(
          lastOptionIndex - MAX_OPTIONS_COUNT - toAddOptionsCount,
          lastOptionIndex - MAX_OPTIONS_COUNT
        );
        setOptions([...newOptions, ...options.slice(0, MAX_OPTIONS_COUNT - newOptions.length)]);
        setLastOptionIndex(lastOptionIndex - newOptions.length);

        const firstOptionElm = firstOptionRef.current;
        listBoxRef.current.scrollTo(0, firstOptionElm.offsetTop);
      }
    } else if (node.scrollHeight - position <= 1) {
      if (lastOptionIndex < filteredOptions.length) {
        const newOptions = filteredOptions.slice(lastOptionIndex, lastOptionIndex + MAX_OPTIONS_COUNT / 2);
        setOptions([...options.slice(newOptions.length), ...newOptions])
        setLastOptionIndex(lastOptionIndex + newOptions.length)
      }
    }
  }

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(e, value) => onChange(value)}
      onClose={e => {
        if (listBoxRef.current) {
          lastScrollTop.current = listBoxRef.current.scrollTop;
        } else {
          lastScrollTop.current = 0;
        }
      }}
      options={options}
      inputValue={inputValue}
      onInputChange={(ev, value, reason) => {
        if (reason !== "reset") {
          setInputValue(value)
        }
      }}
      disableCloseOnSelect
      ListboxProps={{
        role: "list-box",
        ref: onListBoxRefChange,
        ...(filteredOptions.length > MAX_OPTIONS_COUNT && {onScroll: handleScroll}),
      }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `${option.code} - ${i18n.t(option.name)}`}
      renderOption={(props, option, {selected}) => (
        <li {...props} {...(props['data-option-index'] === 0 && {ref: firstOptionRef})}>
          {showCheckboxes && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{marginRight: 8}}
              checked={selected}
            />
          )}
          <span style={{fontSize}}>{option.code + ' - ' + i18n.t(option.name)}</span>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={i18n.t("Product")}
          placeholder={i18n.t("Choose multiple")}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      filterOptions={(options, params) => {
        if (params.inputValue.toLowerCase() !== filterValue.toLowerCase()) {
          setTimeout(() => {
            setFilterValue(params.inputValue);
          }, 100)
        }
        return options;
      }}
      size={size}
      sx={{background: 'white'}}
    />
  );
}