/* REACT BOOTSTRAP */

/* COMPONENTS */
import HomeScreen from "./screens/home/HomeScreen";
import ProductScreen from "./screens/product/ProductScreen";
import LoginScreen from "./screens/auth/login/LoginScreen";
import RegisterScreen from "./screens/auth/register/RegisterScreen";
import UserProductListScreen from "./screens/product/UserProductListScreen";
import ProductEditScreen from "./screens/product/ProductEditScreen";
import {default as UserProductScreen} from "./screens/user/ProductScreen";

/* REACT ROUTER */
import {Link, Navigate, Route, Routes} from "react-router-dom";
import NotFound from "./screens/errors/NotFound";
import DefaultLayout from "./layouts/DefaultLayout";
import CachedProfilePage from "./screens/user/profile";
import ProductCreateScreen from "./screens/product/ProductCreateScreen";
import ProfilePageLayout from "./layouts/profile-page-layout";
import AccountSettings from "./screens/account-settings";
import AccountInformation from "./screens/account-settings/account-information";
import AuthGuard from "./components/guards/auth";
import AccountEmail from "./screens/account-settings/account-information/email";
import AccountPassword from "./screens/account-settings/account-information/password";
import AccountPhone from "./screens/account-settings/account-information/phone";
import BlankLayout from "./layouts/BlankLayout";
import AccountCountry from "./screens/account-settings/account-information/country";
import AccountLanguage from "./screens/account-settings/account-information/language";
import AccountDeactivate from "./screens/account-settings/deactivate";
import AccountLoginHistory from "./screens/account-settings/login-history";
import AccountCertificates from "./screens/account-settings/certificates";
import AcceptCall from "./screens/call/AcceptCall";
import CallTo from "./screens/call/CallTo";
import Feed from "./screens/feed";
import Chats from "./screens/chat";
import ForgotPassword from "./screens/auth/forgot-password";
import PasswordReset from "./screens/auth/password-reset";
function App() {
  return (
    <Routes>
      <Route element={<DefaultLayout/>}>
        <Route path="/login" element={<LoginScreen/>}/>
        <Route path="/register" element={<RegisterScreen/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/password-reset/:token" element={<PasswordReset/>}/>

        <Route element={<AuthGuard> <BlankLayout/> </AuthGuard>}>
          <Route exact path="/" element={<HomeScreen/>}/>
          <Route path="/product/:id" element={<ProductScreen/>}/>
          <Route path="/user/:id/products" element={<UserProductScreen/>}/>
          <Route path='/chats' element={<Chats/>}/>

          <Route element={<AuthGuard> <ProfilePageLayout/> </AuthGuard>}>
            <Route element={<CachedProfilePage/>}>
              <Route path="/user/:user_id" />
              <Route path="/user/:user_id/:tab"/>
              <Route path="/feed/:id" element={<Feed />} />
            </Route>
          </Route>

          <Route>
            <Route path="/me">
              {/** User private products related routes*/}
              <Route element={<AuthGuard><BlankLayout/></AuthGuard>}>
                <Route path="products" element={<UserProductListScreen/>}/>
                <Route path="product/create" element={<ProductCreateScreen/>}/>
                <Route path="product/:id/edit" element={<ProductEditScreen/>}/>
              </Route>

              <Route element={<AuthGuard> <ProfilePageLayout/> </AuthGuard>}>
                {/** Account settings related routes*/}
                <Route path="account-settings">
                  <Route path='' element={<AccountSettings/>}/>
                  <Route path="account-information">
                    <Route path='' element={<AccountInformation/>}/>
                    <Route path='email' element={<AccountEmail/>}/>
                    <Route path='phone' element={<AccountPhone/>}/>
                    <Route path='country' element={<AccountCountry/>}/>
                    <Route path='language' element={<AccountLanguage/>}/>
                  </Route>
                  <Route path="password" element={<AccountPassword/>}/>
                  <Route path="certificates" element={<AccountCertificates/>}/>
                  <Route path="login-history" element={<AccountLoginHistory/>}/>
                  <Route path="deactivate" element={<AccountDeactivate/>}/>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<BlankLayout/>}>
        <Route path='/accept-call' element={<AcceptCall/>}/>
        <Route path='/call-to' element={<CallTo/>}/>
      </Route>
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  );
}

export default App;
