import React, {useState, useEffect, useRef} from "react";

/* REACT ROUTER */
import {Link, useLocation, useNavigate} from "react-router-dom";

/* REACT BOOTSTRAP */

/* COMPONENTS */
import FormContainer from "components/FormContainer";
import {getFirstErrorString, getInputChangeHandler, getUserToken, showMessage} from "@core/utils";


/* ACTION CREATORS */
import i18n from "@core/configs/i18n";
import {
  Box,
  Button,
  Checkbox, CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Alert, Grid, InputAdornment, IconButton
} from "@mui/material";
import {getFormObject, setFormErrors} from "@core/utils";
import {useAuth} from "hooks/useAuth";
import MuiPhoneNumber from 'material-ui-phone-number';
import {DEFAULT_COUNTRY_CODE, FILE_TYPE_PDF, FILE_TYPE_WORD, FILE_TYPES, SUPPORTED_COUNTRY_CODES} from "constants.js";
import FileUploader from "components/file-uploader";
import userService from "../../user/User.service";
import ComponentOverlappingLoader from "components/component-overlapping-loader";
import {SITE_TITLE} from "config/main";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function PersonalInformationStep({stepProps, onFinish}) {
  const auth = useAuth()
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const [formInput, setFormInput] = useState({
    name: {value: "", error: ""},
    email: {value: "", error: ""},
    phone_number: {value: "", error: ""},
    id_number: {value: "", error: ""},
    password: {value: "", error: ""},
    confirm_password: {value: "", error: ""},
    is_provider: {value: false, error: ""},
    is_buyer: {value: false, error: ""},
    is_transiter: {value: false, error: ""},
  })
  const submitHandler = async (e) => {
    e.preventDefault();

    if (formInput.password.value !== formInput.confirm_password.value) {
      setFormErrors({confirm_password: i18n.t("Passwords do not match")}, setFormInput)
    } else {
      setFormErrors(null, setFormInput)
      setLoading(true)
      const formObject = getFormObject(formInput);
      formObject.phone_number = formObject.phone_number.replaceAll(/[\s()-]/g, '');
      const {ok, result, errors} = await auth.register(formObject);
      setLoading(false)
      if (ok) {
        onFinish(result)
      } else {
        setFormErrors(errors, setFormInput)
      }
    }
  };

  return <>
    <Box>
      <form onSubmit={submitHandler}>
        <TextField
          type='text'
          error={Boolean(formInput.name.error)}
          label={i18n.t("Name")}
          value={formInput.name.value}
          helperText={formInput.name.error}
          onChange={getInputChangeHandler("name", setFormInput)}
          fullWidth
          sx={{mb: 2, bg: 'white'}}
        />

        <TextField
          type='text'
          error={Boolean(formInput.id_number.error)}
          label={i18n.t("Identification number")}
          value={formInput.id_number.value}
          helperText={formInput.id_number.error}
          onChange={getInputChangeHandler("id_number", setFormInput)}
          fullWidth
          sx={{mb: 2, bg: 'white'}}
        />

        <TextField
          type='email'
          error={Boolean(formInput.email.error)}
          label={i18n.t("Email Address")}
          value={formInput.email.value}
          helperText={formInput.email.error}
          onChange={getInputChangeHandler("email", setFormInput)}
          fullWidth
          sx={{mb: 2, bg: 'white'}}
        />

        <Box sx={{mb: 2, bg: 'white'}}>
          <MuiPhoneNumber
            autoFormat={true}
            defaultCountry={DEFAULT_COUNTRY_CODE}
            variant="outlined"
            label={i18n.t("Phone Number")}
            onChange={getInputChangeHandler("phone_number", setFormInput, false)}
            error={Boolean(formInput.phone_number.error)}
            helperText={formInput.phone_number.error}
            fullWidth
          />
        </Box>

        <TextField
          type={showPassword ? 'text' : 'password'}
          error={Boolean(formInput.password.error)}
          label={i18n.t("Enter Password")}
          value={formInput.password.value}
          helperText={formInput.password.error}
          onChange={getInputChangeHandler("password", setFormInput)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={e => setShowPassword(!showPassword)}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          sx={{mb: 2, bg: 'white'}}
        />

        <TextField
          type={showConfirmPassword ? 'text' : 'password'}
          error={!!formInput.confirm_password.error}
          label={i18n.t("Confirm Password")}
          value={formInput.confirm_password.value}
          helperText={formInput.confirm_password.error}
          onChange={getInputChangeHandler("confirm_password", setFormInput)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={e => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {!showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          sx={{mb: 2, bg: 'white'}}
        />

        <FormGroup>
          <FormControlLabel
            checked={formInput.is_provider.value}
            onChange={e => getInputChangeHandler('is_provider', setFormInput)(e.target.checked, false)}
            control={<Checkbox/>}
            label={i18n.t("I am a provider")}
          />

          <FormControlLabel
            value={formInput.is_buyer.value}
            onChange={e => getInputChangeHandler('is_buyer', setFormInput)(e.target.checked, false)}
            control={<Checkbox/>}
            label={i18n.t("I am a buyer")}
          />

          <FormControlLabel
            value={formInput.is_transiter.value}
            onChange={e => getInputChangeHandler('is_transiter', setFormInput)(e.target.checked, false)}
            control={<Checkbox/>}
            label={i18n.t("I am a carrier")}
          />
        </FormGroup>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          size='large'
          sx={{width: '100%', mt: 1, px: 4}}>
          {loading && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
          {i18n.t("Register")}
        </Button>

        <Button
          color='secondary'
          onClick={e => navigate('/login')}
          sx={{mt: 2, textTransform: 'none'}}
        >
          <Typography variant='body' color='primary'>
            {i18n.t("Sign In")}
          </Typography>
        </Button>
      </form>
    </Box>
  </>
}

const MAX_ALLOWED_DOCUMENTS = 5;
const MAX_ALLOWED_DOCUMENT_SIZE = 25 * 1024 * 1024; // 25MB
const ALLOWED_DOCUMENT_TYPES = [
  {
    label: () => i18n.t("pdf"),
    types: FILE_TYPES[FILE_TYPE_PDF],
  },
  {
    label: () => i18n.t("word"),
    types: FILE_TYPES[FILE_TYPE_WORD],
  },
];

const SUPPORTED_MIME_TYPES = ALLOWED_DOCUMENT_TYPES.map(type => type.types).flat();

function DocumentUploadStep(
  {
    stepProps: {
      user_access_token,
      document_verification_failed,
      has_to_upload_documents,
      setGlobalLoader
    },
    onFinish
  }
) {
  const [uploading, setUploading] = useState(false);
  const [addedFiles, setAddedFiles] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(true);

  const uploadDocumentsHandler = async e => {
    const formData = new FormData();
    formData.append('access_token', user_access_token)
    for (const file of addedFiles) {
      formData.append('documents', file)
    }
    setUploading(true);
    const {ok, result, errors} = await userService.uploadDocuments(formData);
    setUploading(false);
    if (ok) {
      if (document_verification_failed || has_to_upload_documents) {
        showMessage(true, i18n.t("You've successfully uploaded your registration certificate(s)."));
      } else {
        showMessage(true, i18n.t("You've registered successfully."));
      }
      onFinish({
        access_token: user_access_token,
        ...result,
      });
    } else {
      showMessage(
        false,
        getFirstErrorString(errors) || i18n.t("Something went wrong. Please, try again")
      );
    }
  }

  return <>
    <Box>
      {document_verification_failed && showErrorMessage && (
        <Alert onClose={() => setShowErrorMessage(false)} severity="error" sx={{my: 2}}>
          {i18n.t("Your registration certificate(s) were not verified. Please, upload valid certificate(s).")}
        </Alert>
      )}
      <h4> {i18n.t("Registration certificate(s)")}</h4>
      <Typography sx={{mt: 2}}>
        {i18n.t("Please, upload your registration certificate(s). At least one document is required. You can upload upto {{count}}", {count: MAX_ALLOWED_DOCUMENTS})}
      </Typography>
      <Typography variant='body2' color='secondary' sx={{mt: 2, mb: 1}}>
        {i18n.t("Allowed document types: {{types}}", {types: ALLOWED_DOCUMENT_TYPES.map(type => type.label()).join(", ")})}
      </Typography>
      <FileUploader
        maxSize={MAX_ALLOWED_DOCUMENT_SIZE}
        maxFiles={MAX_ALLOWED_DOCUMENTS}
        accept={SUPPORTED_MIME_TYPES}
        onUpdate={files => setAddedFiles(files)}
      />
      <Button
        variant='contained'
        fullWidth
        disabled={addedFiles.length === 0}
        onClick={uploadDocumentsHandler}
        sx={{mt: 2}}
      >
        {uploading && <CircularProgress color="inherit" size={20} sx={{mr: 1}}/>}
        {i18n.t("Upload")}
      </Button>
    </Box>
  </>
}

const STEP_PERSONAL_INFORMATION = 0;
const STEP_DOCUMENT_UPLOAD = 1

const STEPS = [
  {
    step: STEP_PERSONAL_INFORMATION,
    key: 'personal-information',
    label: () => i18n.t("Personal Information"),
    renderContent: (stepProps, onFinish) => {
      return <PersonalInformationStep
        stepProps={stepProps}
        onFinish={result => {
          onFinish({
            step: STEP_PERSONAL_INFORMATION,
            result
          })
        }}
      />
    }
  },
  {
    step: STEP_DOCUMENT_UPLOAD,
    key: 'document-upload',
    label: () => i18n.t("Document upload"),
    renderContent: (stepProps, onFinish) => {
      return <DocumentUploadStep
        stepProps={stepProps}
        onFinish={result => {
          onFinish({
            step: STEP_DOCUMENT_UPLOAD,
            result
          })
        }}
      />
    }
  }
]

function RegisterScreen() {
  const auth = useAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const [hasDocumentVerificationFailed, setHasDocumentVerificationFailed] = useState(false)
  const [hasToUploadDocuments, setHasToUploadDocuments] = useState(false)
  const [userAccessToken, setUserAccessToken] = useState(null)
  const [activeStep, setActiveStep] = useState(STEPS[0].step);
  const [showGlobalLoader, setShowGlobalLoader] = useState(false);

  /* SETTING UP REDIRECT */
  useEffect(() => {
    if (location.search.includes('document_verification_failed')) {
      const userAccessToken = getUserToken();
      // This is very important !
      auth.logout();

      if (!userAccessToken) {
        navigate('/login');
        return;
      }

      setActiveStep(STEPS[STEP_DOCUMENT_UPLOAD].step);
      setHasDocumentVerificationFailed(true);
      setUserAccessToken(userAccessToken)
    } else if (location.search.includes('has_to_upload_documents')) {
      const userAccessToken = getUserToken();
      // This is very important !
      auth.logout();

      if (!userAccessToken) {
        navigate('/login');
        return;
      }

      setActiveStep(STEPS[STEP_DOCUMENT_UPLOAD].step);
      setHasToUploadDocuments(true);
      setUserAccessToken(userAccessToken)
    } else {
      if (auth.user) {
        navigate('/');
      }
    }
  }, []);

  const onStepFinish = ({step, result}) => {
    if (step === STEP_PERSONAL_INFORMATION) {
      setUserAccessToken(result.access_token)
      setActiveStep(STEPS[1].step)
    } else if (step === STEP_DOCUMENT_UPLOAD) {
      auth.setUserInfo({
        access_token: result.access_token,
        ...result
      });
      navigate('/');
    }
  }

  const getStepProps = (step) => {
    const defaultProps = {
      setGlobalLoader: setShowGlobalLoader
    }
    if (step === STEP_DOCUMENT_UPLOAD) {
      return {
        ...defaultProps,
        document_verification_failed: hasDocumentVerificationFailed,
        has_to_upload_documents: hasToUploadDocuments,
        user_access_token: userAccessToken
      }
    }
    return defaultProps;
  }

  /* HANDLERS */
  return (
    <Box sx={{mx: 5, my: 5}}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6} sx={{width: '100%'}}>
          <Box sx={{textAlign: 'center', mt: {xs: 0, md: '150px'}, my: 2}}>
            <Typography variant='h3' className='site-main-color'>
              {SITE_TITLE}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{position: 'relative', maxWidth: {xs: '100%', md: '500px'}}}>
            {showGlobalLoader && <ComponentOverlappingLoader/>}
            <Box sx={{pt: 5, pb: 1}}>
              <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {STEPS.map((step) => (
                    <Step key={step.key}>
                      <StepLabel>{step.label()}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{px: 4, my: 2}}>
                  {STEPS[activeStep].renderContent(getStepProps(activeStep), onStepFinish)}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RegisterScreen;
