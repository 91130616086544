import {useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {getQueryParamFromUrl, getUserToken} from "../../@core/utils";
import ReconnectingWebSocket from "reconnecting-websocket";
import {MessageTypes, msgTypeEncoder} from "../chat/types";

import "components/chat/video-p2p/index.css"
import {useP2PVideo} from "hooks/useP2PVideo";
import VideoUI from "components/chat/video-p2p/VideoUI";
import {create_ws_endpoint} from "../chat/send";

export default function AcceptCall() {
    const [userId, setUserId] = useState(null);
    const location = useLocation();

    const receivedIceCandidates = useRef([]);

    const {
        video1Ref,
        video2Ref,
        peerConnectionRef,
        socketRef,
        gotSecondUser,
        audioEnabled,
        videoEnabled,
        endCall,
        turnOnCamera,
        toggleMic,
        toggleCamera,
        createPeerConnection,
    } = useP2PVideo();

    useEffect(() => {
        const userId = getQueryParamFromUrl(location.search, 'user_id')
        if (userId) {
            setUserId(userId);
            const accessToken = getUserToken();
            if (accessToken) {
                socketRef.current = new ReconnectingWebSocket(create_ws_endpoint(accessToken));
            }
        }
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        }
    }, [])

    if (socketRef.current) {
        const socket = socketRef.current;
        socket.onopen = (e) => {
            if (socket.readyState === socket.OPEN) {
                socketRef.current.send(msgTypeEncoder(MessageTypes.CallMessageAccept, {to_id: userId}))
            }
        }
        socket.onmessage = (e) => {
            handleMessage(e)
        }

        const acceptedTypes = [
            MessageTypes.CallMessageOffer,
            MessageTypes.CallMessageCandidate,
        ]
        const handleMessage = (e) => {
            const data = JSON.parse(e.data)
            const msgType = data['msg_type']
            if (!acceptedTypes.includes(msgType)) {
                return
            }

            const mapHandlers = {
                [MessageTypes.CallMessageOffer]: handleOfferReceive,
                [MessageTypes.CallMessageCandidate]: handleCandidateReceive,
            }

            try {
                const handler = mapHandlers[msgType]
                if (handler) {
                    handler(data)
                }
            } catch (e) {
                console.log(e)
            }
        }

        async function handleOfferReceive(data) {
            const {from_user} = data
            if (String(from_user.id) === String(userId)) {
                try {
                    const {offer} = data;
                    await turnOnCamera()
                    await createAnswer(userId, offer)
                } catch (e) {
                    console.log(e)
                    // endCall()
                }
            }
        }

        function handleCandidateReceive(data) {
            const {from_user} = data
            if (String(from_user.id) === String(userId)) {
                if (peerConnectionRef.current) {
                    peerConnectionRef.current.addIceCandidate(data.candidate)
                } else {
                    receivedIceCandidates.current.push(data.candidate)
                }
            }
        }
    }

    function sendAnswer(to_id, answer) {
        const dataToSend = {'type': 'answer', 'answer': answer, 'user_pk': String(to_id)}
        socketRef.current.send(msgTypeEncoder(MessageTypes.CallMessageAnswer, dataToSend))
    }

    function addReceivedIceCandidates() {
        receivedIceCandidates.current.forEach(candidate => {
            peerConnectionRef.current.addIceCandidate(candidate)
        });
    }

    const createAnswer = async (user_pk, offer) => {
        await createPeerConnection(user_pk)
        await peerConnectionRef.current.setRemoteDescription(offer)
        const answer = await peerConnectionRef.current.createAnswer()
        await peerConnectionRef.current.setLocalDescription(answer);
        sendAnswer(user_pk, answer)
        addReceivedIceCandidates();
    }

    return <VideoUI
        video1Ref={video1Ref}
        video2Ref={video2Ref}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
        toggleMic={toggleMic}
        toggleCamera={toggleCamera}
        endCall={endCall}
        gotSecondUser={gotSecondUser}
    />
}