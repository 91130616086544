import {Box, Button, MenuItem, Select, TextField, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {getFirstErrorString, getInputChangeHandler, showMessage} from "@core/utils";
import React, {useState} from "react";
import {useAuth} from "hooks/useAuth";
import Loader from "components/Loader";
import userService from "../../../user/User.service";
import {useProductSettings} from "../../../../hooks/useProductSettings";


export default function AccountCountry() {
  const {countries} = useProductSettings();
  const auth = useAuth();

  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState({
    country: {
      value: auth.user.country || (countries.length && countries[0].id),
      error: ''
    }
  })

  const updateCountryHandler = async (e) => {
    e.preventDefault();
    setSaving(true);
    const {ok, errors} = await userService.updateCredentials({country: formInput.country.value})
    if (ok) {
      showMessage(true, i18n.t("Country updated successfully"))
      auth.setUserInfo({
        ...auth.user,
        country: formInput.country.value,
        country_data: countries.find(c => c.id === formInput.country.value)
      })
    }  else {
      if (errors.country) {
        setFormInput(prev => ({
          ...prev,
          country: {
            ...prev.country,
            error: errors.country
          }
        }))
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
    }
    setSaving(false);
  }

  return <Box className='account-country-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Change country")}
    </Typography>
    <form onSubmit={updateCountryHandler}>
      <Select
        value={formInput.country.value}
        onChange={getInputChangeHandler('country', setFormInput)}
        sx={{background: 'white', my: 2}}
        fullWidth
      >
        {countries.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
      </Select>

      <Box>
        <Button
          disabled={saving}
          variant='contained'
          onClick={updateCountryHandler}
          sx={{py: .5, px: 2}}
        >
          {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
          {i18n.t("Update")}
        </Button>
      </Box>
    </form>

  </Box>
}