import ChatBoxes from "../boxes";
import VideoP2P from "../video-p2p";
import {useEffect, useState} from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import {useAuth} from "hooks/useAuth";
import {Box} from "@mui/material";
import {create_ws_endpoint} from "screens/chat/send";


export default function ChatAndVideo() {
  const auth = useAuth()
  const [isReady, setIsReady] = useState(false)
  const [socket, setSocket] = useState(null)
  const [callToDialog, setCallToDialog] = useState(null)
  // const [callStarted, setCallStarted] = useState(false)

  useEffect(() => {
    // logged-in user's `access_token` might change but that isn't an issue here
    // because socket has to connect only once and after connection is established
    // it will be kept open until the user logs out
    const newSocket = new ReconnectingWebSocket(
      create_ws_endpoint(auth.user.access_token)
    )
    setSocket(newSocket)
    setIsReady(true)

    return () => {
      newSocket.close()
    }
  }, [])

  return isReady ? (
    <Box>
      <ChatBoxes onCall={dialog => setCallToDialog(dialog)}/>
      <VideoP2P callTo={callToDialog} onCallStart={() => setCallToDialog(null)} socket={socket}/>
    </Box>
  ) : null
}