import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import React, {useEffect, useState} from "react";
import userService from "../../user/User.service";
import DownloadIcon from '@mui/icons-material/Download';
import UserInfo from "components/user-info";
import {getFirstErrorString, showMessage} from "../../../@core/utils";

const DOCUMENT_TYPE_WORD = 'word';
const DOCUMENT_TYPE_PDF = 'pdf';

function getDocumentImageUrl(type) {
  if (type === DOCUMENT_TYPE_PDF) {
    return '/images/icons/pdf.png'
  } else if (type === DOCUMENT_TYPE_WORD) {
    return '/images/icons/word.png'
  }
  return '/images/icons/file.png'
}

export default function AccountCertificates() {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [downloadinngDocumentId, setDownloadinngDocumentId] = useState(null);
  useEffect(() => {
    setLoading(true)
    userService.getRegistrationCertificates().then(({ok, result, errors}) => {
      setDocuments(result)
      setLoading(false)
    })
  }, [])
  const downloadDocumentHandler = async document => {
    setDownloadinngDocumentId(document.id)

    async function downloadUploadedDocument(shouldShowMessage = true) {
      const {ok, result, errors} = await userService.getDocumentPublicUrl(document.id)
      if (ok) {
        window.open(result, "_blank")
      } else {
        if (shouldShowMessage) {
          showMessage(false, getFirstErrorString(errors))
        }
      }
      return {ok, result, errors}
    }

    async function downloadLocalDocument() {
      const {ok, result, errors} = await userService.downloadDocument(document.id)
      if (ok) {
        const downloadUrl = window.URL.createObjectURL(new Blob([result]));
        const link = window.document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', document.name);
        window.document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        // With the high probability, documents were uploaded so we can't download local documents
        // Let's try to get public url for the uploaded document and download it
        const {ok, result, errors} = await downloadUploadedDocument(false)
        if (!ok) {
          showMessage(false, i18n.t("Something went wrong. Please, try again"))
        }
      }
    }

    if (document.is_uploaded) {
      await downloadUploadedDocument()
    } else {
      await downloadLocalDocument()
    }

    setDownloadinngDocumentId(null)
  };
  return <>
    <Box className='account-certificates-page' sx={{p: 3}}>
      <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
        {i18n.t("Your certificate(s)")}
      </Typography>
      <Box sx={{my: 4}}>
        <Box sx={{textAlign: 'center'}}>
          {loading && <CircularProgress color="primary" sx={{mr: 1}}/>}
        </Box>
        {(!loading && documents.length === 0) && <Typography variant='body2' color='secondary' textAlign='center'>
          {i18n.t("You don't have any certificate(s) yet")}
        </Typography>}
        {documents.map(document => {
          return <Box key={document.id} sx={{my: 2}}>
            <UserInfo
              avatar={<img src={getDocumentImageUrl(document.type)} alt='pdf' width={50}/>}
              title={document.name}
              subtitle={`${Math.round(document.size * 100 / 1024 / 1024) / 100} MB`}
              subtitleColor='rgb(83, 100, 113)'
              rightContent={
                <IconButton
                  disabled={downloadinngDocumentId === document.id}
                  onClick={() => downloadDocumentHandler(document)}>
                  <DownloadIcon/>
                </IconButton>
              }
              onClick={() => null}
              disableComponentClick={true}
              disableHover={true}
              disableClick={true}
              sx={document.id === downloadinngDocumentId ? {opacity: .5} : {}}
            />
          </Box>
        })}
      </Box>
    </Box>
  </>
}