import {memo} from "react";
import {groupedMessagesAreSame} from "../utils";
import {getDatesDifferenceInHours} from "@core/utils";
import {Box, Typography} from "@mui/material";
import moment from "moment";
import UserMessages from "../user-messages";

function getHoursBetweenMessagesGroups(message1, message2) {
  return getDatesDifferenceInHours(message1.date, message2.date)
}

function renderMessageDate(message, index, showFullDate = false) {
  return <Typography variant='body2' textAlign='center' sx={{fontSize: '.75rem', mb: 3, mt: index > 0 ? 3 : 1, color: 'grey'}}>
    {showFullDate
      ? moment(message.messages[0].date).format('DD.MM.YYYY HH:mm')
      : moment(message.messages[0].date).format('HH:mm')}
  </Typography>
}

const ChatMessages = memo(({messages}) => {
  console.log("refreshing chat messages")
  const messagesLength = messages.length;
  return <>
    {messages.map((message, index) => {
      let differenceInHours = 24 + 1;
      if (index > 0) {
        const prevMessages = messages[index - 1]
        const pervMessage = prevMessages.messages[prevMessages.messages.length - 1]
        const currentMessage = message.messages[0]
        differenceInHours = getHoursBetweenMessagesGroups(currentMessage, pervMessage)
      }
      return <Box key={message.messages[0].data.message_id}>
        {differenceInHours > 1 && renderMessageDate(message, index, differenceInHours > 24)}

        <Box sx={{mb: '.5rem', ...(message.position === "right" && {marginLeft: 'auto', marginRight: '0'})}}>
          <Box sx={{display: 'flex', justifyContent: message.position === "right" ? "end" : "start", width: '100%'}}>
            <UserMessages
              position={message.position}
              messages={message.messages}
              showMessageStatus={index === messagesLength - 1}
            />
          </Box>
        </Box>
      </Box>
    })}
  </>
}, (oldProps, newProps) => {
  return groupedMessagesAreSame(oldProps.messages, newProps.messages)
})

export default ChatMessages