import {Box, Button, TextField, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {getFirstErrorString, getInputChangeHandler, showMessage} from "@core/utils";
import React, {useState} from "react";
import Loader from "components/Loader";
import userService from "../../../user/User.service";


export default function AccountPassword() {
  const [saving, setSaving] = useState(false);
  const [formInput, setFormInput] = useState({
    confirmPassword: {value: '', error: ''},
    password: {value: '', error: ''}
  })

  const updatePasswordHandler = async (e) => {
    e.preventDefault();
    if (formInput.password.value !== formInput.confirmPassword.value) {
      setFormInput(prev => ({
        ...prev,
        confirmPassword: {
          ...prev.confirmPassword,
          error: i18n.t("Passwords do not match")
        },
      }))
      return;
    }
    setSaving(true);
    const {ok, errors} = await userService.updateCredentials({password: formInput.password.value})
    if (ok) {
      showMessage(true, i18n.t("Password updated successfully"))
    } else {
      if (errors.password) {
        setFormInput(prev => ({
          ...prev,
          password: {
            ...prev.password,
            error: errors.password
          }
        }))
      } else {
        showMessage(false, getFirstErrorString(errors));
      }
    }
    setSaving(false);
  }

  return <Box className='account-password-page' sx={{p: 3}}>
    <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
      {i18n.t("Change password")}
    </Typography>
    <form onSubmit={updatePasswordHandler}>
      <TextField
        error={Boolean(formInput.password.error)}
        label={i18n.t("Password")}
        name='password'
        type='password'
        autoComplete='off'
        value={formInput.password.value}
        helperText={formInput.password.error}
        onChange={getInputChangeHandler('password', setFormInput)}
        fullWidth
        sx={{mt: 3}}
      />
      <TextField
        error={Boolean(formInput.confirmPassword.error)}
        label={i18n.t("Confirm password")}
        name='confirm-password'
        type='password'
        autoComplete='off'
        value={formInput.confirmPassword.value}
        helperText={formInput.confirmPassword.error}
        onChange={getInputChangeHandler('confirmPassword', setFormInput)}
        fullWidth
        sx={{mt: 2, mb: 3}}
      />
      <Button
        type='submit'
        disabled={saving}
        variant='contained'
        onClick={updatePasswordHandler}
        sx={{py: .5, px: 2}}
      >
        {saving && <Loader size='1rem' margin='.25rem .5rem 0 0'/>}
        {i18n.t("Update")}
      </Button>
    </form>

  </Box>
}