import {Box, styled} from "@mui/material";

const HoverableIcon = styled(Box)((
  {
    theme,
    padding = "0.2rem 0.4rem",
    hovercolor,
    noborder = false,
    margin,
    sx
  }) => ({
  margin,
  padding,
  borderRadius: '50%',
  background: 'transparent',
  border: !noborder && '1px solid lightgrey',
  cursor: 'pointer',
  "&:hover": {
    border: !noborder && `1px solid ${hovercolor ? hovercolor : 'lightgrey'}`,
    background: hovercolor ? hovercolor : '#e0e4e4',
  },
  sx
}))

export default HoverableIcon