import {
  MessageBoxStatus,
} from "./types";

export function getRandomId() {
  return Math.floor(Math.random() * 1000000000) * -1
}

export function getSubtitleTextFromMessageModel(msg) {
  if (!msg) {
    return "";
  }
  return msg.out ? "You: " + msg.text : msg.text
}

export function getSubtitleTextFromMessageBox(msg) {
  if (!msg) {
    return "";
  }
  return msg.data.out ? "You: " + msg.text : msg.text
}

export function getMessageStatus(message) {
  if (message.read === true) {
    return MessageBoxStatus.Read
  } else if (message.out === true && message.read === false) {
    return MessageBoxStatus.Sent
  } else if (message.out === false && message.read === false) {
    return MessageBoxStatus.Received
  }
  return "";
}
