import "./index.css";
import {Box, TextField, Typography, Paper, InputAdornment} from "@mui/material";
import i18n from "@core/configs/i18n";
import {useEffect, useRef, useState} from "react";
import userService from "../../screens/user/User.service";
import {showMessage} from "@core/utils";
import Loader from "../Loader";
import UserInfo from "../user-info";
import {SearchRounded} from "@mui/icons-material";

export default function SearchUsers({showFollowButton = false}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const delayedSearchRef = useRef(null)

  useEffect(() => {
    searchUsers()
  }, [])

  function searchUsers(query) {
    setLoading(true)
    userService.searchUsers(query).then(({ok, result, errors}) => {
      if (!ok) {
        showMessage(false, i18n.t("Something went wrong. Please, refresh the page"))
      } else {
        const {results} = result
        setUsers(results)
      }
      setLoading(false)
    })
  }

  function delayedSearch(value) {
    if (delayedSearchRef.current) {
      clearTimeout(delayedSearchRef.current)
    }
    delayedSearchRef.current = setTimeout(() => {
      searchUsers(value)
    }, 500)
  }

  return <Box className='search-users-component'>
    <Box className='search-input'>
      <TextField
        placeholder={i18n.t("Search users")}
        value={searchQuery}
        onChange={e => {
          setSearchQuery(e.target.value)
          delayedSearch(e.target.value)
        }}
        size='medium'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded/>
            </InputAdornment>
          )
        }}
        sx={{'& fieldset': {borderRadius: '1.5rem'}}}
        fullWidth/>
    </Box>
    <Box className='search-users-list'>
      {(
        <Box sx={{my: 2}}>
          <Paper sx={{borderRadius: '1rem', background: 'rgb(247, 249, 249)'}}>
            <Typography variant='h6' sx={{fontWeight: 'bolder', p: 1, px: 2}}>
              {i18n.t("You might like")}
            </Typography>
            {loading ? (
              <Box sx={{py: 1, pb: 3}}>
                <Loader size='3rem'/>
              </Box>
            ) : (
              <>
                {users.length === 0 && (
                  <Box sx={{textAlign: 'center', py: 1, pb: 3}}>
                    <Typography variant='body' sx={{color: 'grey'}}>
                      {i18n.t("There are no users")}
                    </Typography>
                  </Box>
                )}
                <Box>
                  {users.map(user => {
                    return <UserInfo
                      key={user.id}
                      data={user}
                      padding='0rem .5rem 1rem 1rem'
                      fontSize='.9rem'
                      disableComponentClick={false}
                    />
                  })}
                </Box>
              </>
            )}
          </Paper>
        </Box>
      )}
    </Box>
  </Box>
}
