import "./index.css"
import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React from "react";
import {getUserThumbnailOriginalOrDefaultImage} from "@core/utils";


export default function UserInfo(
  {
    data,
    avatar = <img src={getUserThumbnailOriginalOrDefaultImage(data, 'image_data')} className='post-image-width'/>,
    title = data && data.name,
    nextToTitleContent = null,
    alignNextToTitleContent = 'left',
    subtitle = data && (data.id_number ? `#${data.id_number}` : `#N/A`),
    onClick = null,
    disableComponentClick = true,
    disableClick = false,
    rightContent = null,
    underSubtitleContent = null,
    mainContent = null,
    bottomContent = null,
    padding = '.5rem 2rem',
    fontSize = '1rem',
    disableHover = false,
    hoverColor = '#f1efef',
    titleColor = 'black',
    subtitleColor = '#4f9ec1',
    sx={},
  }) {
  const navigate = useNavigate()

  function clickHandler(e) {
    if (onClick) {
      onClick(e)
    } else {
      navigate(`/user/${data.id}`)
    }
  }

  return <>
    <Box
      className={`user-info ${disableHover ? '' : 'user-info-hoverable'}`}
      onClick={disableComponentClick ? null : clickHandler}
      sx={disableHover ? {...sx} : {':hover': {backgroundColor: hoverColor}, ...sx}}>
      <Box
        className={`user-info-header-wrapper`}
        sx={{padding: padding}}
      >
        <Box className='user-info-header'
             onClick={disableClick ? null : clickHandler}>
          {avatar}
        </Box>
        <Box className='user-info-body'>
          <Box className='user-body-header'>
            <Box sx={{width: '100%'}}>
              <Box sx={{display: 'flex', justifyContent: alignNextToTitleContent === "right" ? "space-between" : null}}>
                <Typography
                  className={'user-info-title ' + (!disableClick && 'user-info-title-hoverable')}
                  onClick={disableClick ? null : clickHandler}
                  sx={{fontSize: fontSize, color: titleColor}}>
                  {title}
                </Typography>
                {nextToTitleContent}
              </Box>
              <Typography
                className='user-info-subtitle'
                variant='subtitle2'
                sx={{my: 0, color: subtitleColor}}>
                {subtitle}
              </Typography>
            </Box>
            <Box sx={{mt: 1}}>
              {rightContent}
            </Box>
          </Box>
          {underSubtitleContent && (
            <Box className='user-body-content'>
              {underSubtitleContent}
            </Box>
          )}
        </Box>
      </Box>
      {mainContent && (
        <Box className='user-info-content-wrapper' sx={{padding: padding, paddingY: 1}}>
          {mainContent}
        </Box>
      )}
      {bottomContent && (
        <Box className='user-info-bottom-content-wrapper' sx={{padding: padding, paddingY: 0}}>
          {bottomContent}
        </Box>
      )}
    </Box>
  </>
}