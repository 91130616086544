import "./index.css"
import {Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {Fragment, useEffect, useState} from "react";
import userService from "../../../User.service";
import {getQueryParamFromUrl, showMessage} from "@core/utils";
import Loader from "components/Loader";
import UserInfo from "components/user-info";
import FollowButton from "components/follow-button";
import GlobalInfiniteScroll from "components/global-infinite-scroll";
import {DEFAULT_PAGE_SIZE} from "../../../../../constants";

const FOLLOWING_PER_PAGE = DEFAULT_PAGE_SIZE;

export default function Following({user, is_hidden}) {
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchUsers(cursor)
  }, [])

  const fetchUsers = cursor => {
    if (loading) return;
    setLoading(true)
    userService.getFollowing(user.id, cursor, FOLLOWING_PER_PAGE).then(({ok, result, errors}) => {
      if (!ok) {
        showMessage(false, i18n.t("Something went wrong. Please, refresh the page"))
      } else {
        const {results, next} = result
        setFollowing(prev => [...prev, ...results])
        if (next) {
          const cursor = getQueryParamFromUrl(next, 'cursor');
          setCursor(cursor);
        } else {
          setHasMore(false);
        }
      }
      setLoading(false)
    })
  }

  return (
    <div className="following-tab">
      <Fragment>
        {(!loading && following.length === 0) ? (
          <Typography sx={{textAlign: 'center', color: 'grey', mt: 2}}>
            {i18n.t("There are no users")}
          </Typography>
        ) : (
          <Fragment>
            <GlobalInfiniteScroll
              hasMore={hasMore}
              onScrollToBottom={() => fetchUsers(cursor)}
            >
              {following.map(follow =>
                <UserInfo
                  key={follow.id}
                  data={follow}
                  rightContent={<FollowButton following={true} userId={follow.id}/>}
                  underSubtitleContent={<Typography>{follow.about}</Typography>}
                />
              )}
            </GlobalInfiniteScroll>
            {loading && (
              <Loader size='3rem' mt='1rem'/>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}