import axios from 'axios';
import i18n from "./@core/configs/i18n";
import {normalizeErrorResponseData} from "./@core/utils";

let config = {
  baseURL: process.env.REACT_APP_API_URL
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  if (accessToken) {
    config.headers.Authorization = `Token ${accessToken}`;
  }
  // Handle token here ...
  return config;
})

axiosClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // error
  if (error.response.status === 401) {
    if (error.response.data?.detail === "document_verification_failed") {
      window.location.href = "#/register?document_verification_failed=1";
    } else if (error.response.data?.detail === "has_to_upload_documents") {
      window.location.href = "#/register?has_to_upload_documents=1";
    } else {
      const hash = window.location.hash;
      if (!(hash.includes('#/login') || hash.includes('#/register'))) {
        // TODO find a better idea to check if we are at login page
        window.location.href = `#/login?logout_user=1`;
      }
    }
  }
  throw error

})

async function runRequest(method, ...args) {
  if (["POST", "PUT"].includes(method)) {
    // in this case, the arguments go like this: url, data, config
    const [url, payload, config = {}] = args
    if (!config.headers) {
      config.headers = {}
    }
    if (payload instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    args = [url, payload, config]
  }
  try {
    const {data} = await axiosClient[String(method).toLowerCase()](...args);
    if (typeof data === "object" && data.ok !== undefined && (data.result !== undefined || data.errors !== undefined)) {
      const {ok, result, errors} = data
      return {ok, result, errors}
    } else {
      return {ok: true, result: data, errors: null}
    }
  } catch (errorResponse) {
    const response = typeof errorResponse === "object" && errorResponse.response;
    if (
      typeof response === "object"
      && response.status === 400
      && typeof response.data === "object"
    ) {
      return {
        ok: false,
        result: null,
        errors: normalizeErrorResponseData(response.data)
      }
    }
    return {
      ok: false,
      result: null,
      errors: {
        non_field_errors: [i18n.t("Something went wrong. Please, try again")]
      }
    }
  }
}

export const request = {
  get: async (...args) => await runRequest("GET", ...args),
  post: async (...args) => await runRequest("POST", ...args),
  put: async (...args) => await runRequest("PUT", ...args),
  delete: async (...args) => await runRequest("DELETE", ...args),
  patch: async (...args) => await runRequest("PATCH", ...args),
}

export default axiosClient;