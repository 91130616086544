import React, {useEffect, useState} from "react";

import SharedProductEditScreen from "./SharedProductEditScreen";
import {getFirstErrorString, showMessage} from "@core/utils";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import Loader from "components/Loader";
import userService from "../user/User.service";

function ProductEditScreen() {
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [globalError, setGlobalError] = useState(null)
  const [product, setProduct] = useState(null)

  async function getProductDetails(id) {
    setLoading(true);
    const {ok, result, errors} = await userService.getProduct(id)
    if (ok) {
      setProduct(result)
    } else {
      showMessage(false, getFirstErrorString(errors))
      setGlobalError(getFirstErrorString(errors))
    }
    setLoading(false);
  }

  useEffect(() => {
    getProductDetails(id)
  }, [id]);

  /* HANDLERS */

  const submitHandler = (productData) => {
    const formData = new FormData();
    formData.append('id', id)
    for (const [key, value] of Object.entries(productData)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(key, item)
        }
      } else {
        formData.append(key, value)
      }
    }
    return userService.updateProduct(formData)
  };

  return (
    loading ? (
      <Box sx={{mt: 4}}>
        <Loader/>
      </Box>
    ) : (
      <SharedProductEditScreen
        product={product}
        errorMessage={globalError}
        submitHandler={submitHandler}
      />
    )
  )
}

export default ProductEditScreen;
