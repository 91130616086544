const listeners = {};
export default {
  on(event, callback) {
    const listener = e => callback(e.detail);
    document.addEventListener(event, listener);

    const callbacks = listeners[event] || [];
    callbacks.push(listener);
    listeners[event] = callbacks;

    return listener
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, {detail: data}));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
  removeAll(event) {
    const callbacks = listeners[event] || [];
    callbacks.forEach((callback) => {
      document.removeEventListener(event, callback);
    });
    listeners[event] = [];
  }
};
