import React, {useEffect, useState} from "react";
import {getFirstErrorString, showMessage} from "@core/utils";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import ProductCard from "components/ProductCard";
import Loader from "components/Loader";
import ProductsFilterLeftPanel from "components/ProductsFilterLeftPanel";
import productService from "../product/Product.service";
import Autocomplete from "components/Autocomplete";
import {useNavigate, useParams} from "react-router-dom";
import {period as periodData} from "components/data";
import Pagination from "@mui/material/Pagination";

const PRODUCTS_PER_PAGE = 8;

export default function ProductScreen() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchingProducts, setSearchingProducts] = useState(false);

  const [formFilter, setFormFilter] = useState({
    buyer: '',
    buyer_rating: '',
    provider: '',
    transiter: '',
    provider_rating: '',
    product: '',
    category: '',
    year_from: '',
    year_to: '',
    price_from: '',
    price_to: '',
    location: '',
    address: '',
    announcement_code: '',
    period: '',
    customs_clearance: '',
    search: '',
    /** @type {Array<{code: string, name: string}>} */
    product_codes: [],
    currency_code: null,
  });

  const handleFilterChange = (key, value) => {
    setFormFilter(prev => ({
      ...prev,
      [key]: value
    }))
  }

  useEffect(() => {
    handleSearch()
  }, [])

  function visitProduct(id) {
    window.open(`#/product/${id}`, '_blank')
  }

  if (loading) {
    return <>
      <Box sx={{mt: 4}}>
        <Loader/>
      </Box>
    </>
  }

  const handleSearch = async (page) => {
    setSearchingProducts(true);
    const filter = {
      ...formFilter,
      product_codes: formFilter.product_codes.map(item => item.code).join(','),
      user: id || 0
    }
    const {ok, result, errors} = await productService.getProducts(filter, page, PRODUCTS_PER_PAGE);
    if (ok) {
      const {results, count} = result;
      setProducts(results);
      setCount(count);
    } else {
      showMessage(false, getFirstErrorString(errors));
    }
    setSearchingProducts(false);
  }

  return <>
    <Container sx={{mt: 4}}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={12} md={11} lg={11}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={3} className=''>
              <ProductsFilterLeftPanel
                onSearch={e => handleSearch(page)}
                formFilter={formFilter}
                onUpdate={(field, value) => handleFilterChange(field, value)}
              />
            </Grid>
            <Grid item sm={12} md={9} sx={{pt: {xs: 2, md: 0}}}>
              <Box>
                <Box className='products-header' justifyContent='space-between' sx={{display: 'flex'}}>
                  <Typography sx={{mt: 0}}>
                    {products.length} {i18n.t("advertisements")}
                  </Typography>
                  <Box>
                    <form onSubmit={e => {
                      e.preventDefault();
                      handleSearch(page);
                    }}>
                      <Autocomplete
                        label={i18n.t("Period")}
                        value={formFilter.period}
                        onChange={value => handleFilterChange('period', value)}
                        options={periodData}
                        size='small'
                        sx={{width: '150px !important', background: 'transparent !important'}}
                      />
                      <Button sx={{display: 'none'}}></Button>
                    </form>
                  </Box>
                </Box>
                <Box className='products-body' sx={{mt: 1.5}}>
                  {searchingProducts ? (
                    <Loader/>
                  ) : (
                    <>
                      {products.map(product => (
                        <Box key={product.id} sx={{my: 1}}>
                          <ProductCard product={product} onClick={e => visitProduct(product.id)}/>
                        </Box>
                      ))}
                      {products.length === 0 && (
                        <Box sx={{textAlign: "center"}}>
                          <Typography variant='body2'>
                            {i18n.t("There are no products")}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{my: '1rem', width: '100%', textAlign: 'center'}}>
                  {count > PRODUCTS_PER_PAGE && !searchingProducts && (
                    <Pagination
                      count={Math.ceil(count / PRODUCTS_PER_PAGE)}
                      page={page}
                      onChange={(_, page) => {
                        setPage(page);
                        handleSearch(page);
                      }}
                      color="primary"
                    />
                  )}

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </>
}