import {Box, Button, CircularProgress, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import React, {useEffect, useState} from "react";
import userService from "../../user/User.service";
import {DEFAULT_PAGE_SIZE} from "../../../constants";
import {showMessage} from "../../../@core/utils";
import Pagination from "@mui/material/Pagination";
import UserInfo from "../../../components/user-info";
import RestoreIcon from "@mui/icons-material/Restore";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletIcon from '@mui/icons-material/Tablet';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import DevicesIcon from '@mui/icons-material/Devices';
import moment from "moment";
import {useAuth} from "../../../hooks/useAuth";


const PER_PAGE = DEFAULT_PAGE_SIZE;

const DEVICE_TYPE_MOBILE = 'mobile';
const DEVICE_TYPE_TABLET = 'tablet';
const DEVICE_TYPE_PC = 'pc';

const TYPE_TO_ICON_MAPPER = {
  [DEVICE_TYPE_MOBILE]: <PhoneAndroidIcon />,
  [DEVICE_TYPE_TABLET]: <TabletIcon />,
  [DEVICE_TYPE_PC]: <LaptopChromebookIcon />,
}

export default function AccountLoginHistory() {
  const [loading, setLoading] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [logins, setLogins] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const auth = useAuth();

  useEffect(() => {
    fetchLogins(page);
  }, [])

  const fetchLogins = async page => {
    setLoading(true);
    const {ok, result, errors} = await userService.getLoginHistory(page, PER_PAGE);
    if (ok) {
      const {count, results} = result;
      setCount(count);
      setLogins(results);
    } else {
      showMessage(false, i18n.t('Something went wrong. Please, try again'))
    }
    setLoading(false);
  }

  const logoutOfOtherSessionsHandler = async () => {
    setLoggingOut(true);
    const {ok, result, errors} = await userService.logoutOfOtherSessions();
    if (ok) {
      const {access_token} = result
      auth.setLoggedInUserInfo({
        user_data: {...auth.user},
        access_token,
      });
      showMessage(true, i18n.t('Successfully logged out of other sessions'));
    } else {
      showMessage(false, i18n.t('Something went wrong. Please, try again'))
    }
    setLoggingOut(false);
  }

  return (
    <Box className='account-deactivate-page' sx={{p: 3}}>
      <Box>
        <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
          {i18n.t("Log out of other sessions")}
        </Typography>
        <Typography variant='body2' sx={{my: 2}}>
          {i18n.t("All other devices logged in with this account will be automatically logged out from the system and they'll have to login again")}
        </Typography>
        <Button
          color='error'
          fullWidth
          sx={{textTransform: 'none'}}
          disabled={loggingOut}
          onClick={logoutOfOtherSessionsHandler}>
          {loggingOut ? <CircularProgress color='secondary' size={17} sx={{mx: 1}} /> : null}
          {i18n.t("Log out of all other sessions")}
        </Button>
      </Box>
      <Box sx={{my: '1rem'}}>
        <Typography variant='h6' sx={{fontWeight: 'bolder'}}>
          {i18n.t("Your login history")}
        </Typography>
        {loading ? (
          <Box sx={{textAlign: 'center'}}>
            <CircularProgress sx={{my: '1rem'}}/>
          </Box>
        ) : (
          <>
            {logins.length === 0 && <Typography variant='caption' sx={{my: 2, textAlign: 'center'}}>
              {i18n.t("You haven't logged in from any other device yet")}
            </Typography>}
            {logins.map(login => (
              <Box key={login.id} sx={{my: 2}}>
                <UserInfo
                  avatar={TYPE_TO_ICON_MAPPER[login.device] || <DevicesIcon />}
                  title={login.device}
                  rightContent={<Typography color='secondary' variant='caption'>
                    {moment(login.created_at).format('L, LT')}
                  </Typography>}
                  alignNextToTitleContent='right'
                  subtitle={<Typography variant='body2'>
                    <span style={{color:'green'}}>{login.os} </span>
                    (<span style={{color: 'black'}}>{login.browser}</span>)
                  </Typography>}
                  subtitleColor='rgb(83, 100, 113)'
                  onClick={() => null}
                  disableComponentClick={true}
                  disableClick={true}
                />
              </Box>
            ))}
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {count > PER_PAGE && (
                <Pagination
                  count={Math.ceil(count / PER_PAGE)}
                  page={page}
                  onChange={(_, page) => {
                    setPage(page);
                    fetchLogins(page);
                  }}
                  color="primary"
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}