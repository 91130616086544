import {UserMessageTypes} from "../../screens/chat/types";
import {getDatesDifferenceInHours} from "../../@core/utils";

export function groupMessages(messages) {
  const newMessages = []
  let prevMessage = null;
  let prevTime = null;
  for (const message of messages) {
    if (prevTime === null) {
      prevTime = message.date;
    } else {
      if (getDatesDifferenceInHours(message.date, prevTime) > 1) {
        prevTime = message.date;
        newMessages.push(prevMessage)
        prevMessage = {
          position: message.position,
          messages: [message]
        }
        continue
      }
    }
    prevTime = message.date;

    if (prevMessage && prevMessage.position === message.position) {
      prevMessage.messages.push(message)
    } else {
      if (prevMessage) {
        newMessages.push(prevMessage)
      }
      prevMessage = {
        position: message.position,
        messages: [message]
      }
    }
  }
  if (prevMessage) {
    newMessages.push({...prevMessage})
  }
  return newMessages
}

export function getLastMessageIdForGroupedMessages(messages) {
  if (messages.length) {
    const lastAuthorMessages = messages[messages.length - 1]
    const message = lastAuthorMessages.messages[lastAuthorMessages.messages.length - 1]
    return message.data.message_id
  }
  return null;
}

export function getLastMessageIdForUngroupedMessages(messages) {
  if (messages.length) {
    const message = messages[messages.length - 1]
    return message.data.message_id
  }
  return null;
}

const watchableDialogFields = [
  'unread',
  'typing',
  'online',
  'loading',
  'cursor',
  'hasFetchedMessages',
]

export function chatHeaderIsSame(oldDialog, newDialog) {
  for (const field of watchableDialogFields) {
    if (oldDialog[field] !== newDialog[field]) {
      return false;
    }
  }
  return true;
}

const watchableDialogMessagesFields = [
  "status",
  "is_deleted",
  "emoji_id",
]


export function chatMessageIsSame(oldMessage, newMessage) {
  for (const field of watchableDialogMessagesFields) {
    if (oldMessage[field] !== newMessage[field]) {
      return false;
    }
  }
  return true;
}

export function chatMessagesAreSame(oldMessages, newMessages) {
  if (oldMessages.length !== newMessages.length) {
    return false;
  }
  for (let i = 0; i < oldMessages.length; i++) {
    if (!chatMessageIsSame(oldMessages[i], newMessages[i])) {
      return false;
    }
  }
  return true;
}

export function dialogMessagesAreSame(oldDialog, newDialog) {
  return chatMessagesAreSame(oldDialog.messages, newDialog.messages)
}


export function messagesGroupIsSame(oldGroup, newGroup) {
  if (oldGroup.messages.length !== newGroup.messages.length || oldGroup.position !== newGroup.position) {
    return false;
  }
  return chatMessagesAreSame(oldGroup.messages, newGroup.messages)
}

export function groupedMessagesAreSame(oldGroupedMessages, newGroupedMessages) {
  if (oldGroupedMessages.length !== newGroupedMessages.length) {
    return false;
  }
  for (let i = 0; i < oldGroupedMessages.length; i++) {
    const oldGroup = oldGroupedMessages[i];
    const newGroup = newGroupedMessages[i];
    if (!messagesGroupIsSame(oldGroup, newGroup)) {
      return false;
    }
  }
  return true;
}

export function isTextMessage(message) {
  return message.type === UserMessageTypes.Text
}

export function isReplyMessage(message) {
  return message.type === UserMessageTypes.Reply
}

export function isCallMessage(message) {
  return message.type === UserMessageTypes.Call
}