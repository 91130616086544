import React from "react";

/* REACT ROUTER */
import {Link} from "react-router-dom";

/* COMPONENTS */
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {
  getMessageSubstring,
  getFirstThumbnailOriginalOrDefaultImageFromImages,
} from "@core/utils";
import Badge from "./badge";
import Currency from "./currency";

function Product({product, onClick}) {
  return (
    <Card sx={{cursor: 'pointer', width: '100%', maxWidth: {xs: '100%', sm: '300px'}, margin: 'auto'}}>
      <CardMedia sx={{objectFit: 'cover'}} onClick={onClick}>
        <Box sx={{display: {xs: 'none', sm: 'block'}}}>
          <Box sx={{width: '100%', height: '200px', overflow: 'hidden'}}>
            <img
              src={getFirstThumbnailOriginalOrDefaultImageFromImages(product.images)}
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          </Box>
        </Box>
        <Box sx={{display: {xs: 'block', sm: 'none'}}}>
          <Box sx={{width: '100%', height: '100%', overflow: 'hidden'}}>
            <img
              src={getFirstThumbnailOriginalOrDefaultImageFromImages(product.images)}
              style={{width: '100%', minHeight: '200px', maxHeight: '400px', objectFit: 'cover'}}
            />
          </Box>
        </Box>
      </CardMedia>
      <CardContent>
        <Box sx={{height: '20px', transform: 'translateY(-10px)'}}>
          {product.year && <Typography variant='caption' color='#acafbc' sx={{mr: 2, mt: 0}}>
            {product.year}
          </Typography>}
          <Typography variant='caption' color='#acafbc'>
            {product.location_data?.name}
          </Typography>
        </Box>
        <Link to={`/product/${product.id}`} style={{textDecoration: 'none'}}>
          <p style={{
            fontFamily: '"Helvetica" !important',
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: '0',
            marginBottom: '.5rem',
            height: '2rem',
          }}>
            {getMessageSubstring(product.name, 35)}
          </p>
        </Link>
        {/*{product.category && <Badge sx={{mb: 0, height: '22px'}}> {product.category} </Badge>}*/}
        <Box sx={{transform: 'translateY(20px)'}}>
          <Typography variant="h5" style={{mt: 3, fontSize: '1.2rem'}}>
            <p style={{fontWeight: 'bolder'}}>
              {product.price}
              <Currency code={product.currency_code}/>
            </p>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Product;
