import {Box, Button, Typography} from "@mui/material";
import i18n from "@core/configs/i18n";
import {Add} from "@mui/icons-material";
import Modal from "../modal";
import {useState} from "react";
import Editor from "../editor";
import userService from "../../screens/user/User.service";
import {showMessage} from "@core/utils";
import eventBus from "../../@core/event-bus";

export default function CreatePost({sx = {}}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [editorState, setEditorState] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);

  const createPost = async () => {
    if (!String(editorState).trim().length) {
      return;
    }
    try {
      setModalLoading(true);
      await userService.createPost(
        editorState,
        uploadedImages.map(image => image.id)
      );
      setModalOpen(false);
      setEditorState('');
      eventBus.dispatch('post-created');
      showMessage(true, i18n.t("Post created successfully"))
    } catch (e) {
      showMessage(false, i18n.t("Can't create a post. Please, try again"))
    }
    setModalLoading(false);
  }

  return <>
    <Button variant='contained'
            color='primary'
            className='create-post-button'
            onClick={e => {
              setModalOpen(true)
              setUploadedImages([])
            }}
            size='small'
            sx={{borderRadius: '1rem', py: '.25rem', px: 2, textTransform: 'none', ...sx}}>
      <Add fontSize='small' sx={{mr: 0}}/>
      <Typography variant='body'>
        {i18n.t("Create Post")}
      </Typography>
    </Button>
    <Modal
      open={modalOpen}
      onSubmit={createPost}
      onClose={e => setModalOpen(false)}
      loading={modalLoading}
      title={i18n.t("Write a post")}>
      <Editor
        editorState={editorState}
        setEditorState={setEditorState}
        imageUploadUrl='/posts/images/'
        onImageUpload={image => {
          setUploadedImages([...uploadedImages, image])
        }}
      />
    </Modal>
  </>
}