import {MessageTypes, msgTypeEncoder, UserMessageTypes} from "./types";
import {getRandomId} from "./utils";
import {createMessageBoxFromOutgoingMessage} from "./create";

export function sendOutgoingCallMessage(sock, text, to_pk, from_user) {
  const randomId = getRandomId();
  const data = {
    "text": String(text),
    "user_pk": String(to_pk),
    "random_id": String(randomId),
  }
  sock.send(msgTypeEncoder(MessageTypes.CallMessage, data));
  return createMessageBoxFromOutgoingMessage(
    {text, type: UserMessageTypes.Call},
    to_pk,
    from_user.pk,
    from_user.name,
    randomId,
  )
}

export function sendOutgoingTextMessage(sock, text, to_pk, from_user) {
  const randomId = getRandomId();
  const data = {
    "text": String(text),
    "user_pk": String(to_pk),
    "random_id": String(randomId),
  }
  sock.send(msgTypeEncoder(MessageTypes.TextMessage, data));
  return createMessageBoxFromOutgoingMessage(
    {text, type: UserMessageTypes.Text},
    to_pk,
    from_user.pk,
    from_user.name,
    randomId,
  )
}


export function sendOutgoingReplyToMessage(sock, text, replyTo, to_pk, from_user) {
  const {id: replyToId, text: replyToText} = replyTo;
  const randomId = getRandomId();
  const data = {
    "text": String(text),
    "message_id": String(replyToId),
    "user_pk": String(to_pk),
    "random_id": String(randomId),
  }
  sock.send(msgTypeEncoder(MessageTypes.ReplyToMessage, data));
  return createMessageBoxFromOutgoingMessage(
    {
      text,
      type: UserMessageTypes.Reply,
      reply_to: {id: replyToId, text: replyToText}
    },
    to_pk,
    from_user.pk,
    from_user.name,
    randomId,
  )
}


export function sendIsTypingMessage(sock, user_pk, typing) {
  sock.send(msgTypeEncoder(MessageTypes.IsTyping, {user_pk, typing}));
}

export function markAllMessagesReadWIthUser(sock, user_pk) {
  const data = {
    "user_pk": String(user_pk),
  }
  sock.send(msgTypeEncoder(MessageTypes.MarkAllMessagesRead, data));
}

export function sendFindUserOnlineStatus(sock, user_pk) {
  const data = {
    "user_pk": String(user_pk),
  }
  sock.send(msgTypeEncoder(MessageTypes.UserOnlineStatus, data));
}

export function sendDeleteMessage(sock, message_id, user_pk) {
  const data = {
    "message_id": String(message_id),
    "user_pk": String(user_pk),
  }
  sock.send(msgTypeEncoder(MessageTypes.DeleteMessage, data));
}

export function sendMessageReaction(sock, emoji_id, message_id, user_pk) {
  const data = {
    "emoji_id": String(emoji_id),
    "message_id": String(message_id),
    "user_pk": String(user_pk),
  }
  sock.send(msgTypeEncoder(MessageTypes.MessageReaction, data));
}

export function sendMessageReactionRemove(sock, message_id, user_pk) {
  const data = {
    "message_id": String(message_id),
    "user_pk": String(user_pk),
  }
  sock.send(msgTypeEncoder(MessageTypes.MessageReactionRemove, data));
}

export const MESSAGES_ENDPOINT = "chat/messages/"
export const DIALOGS_ENDPOINT = "chat/dialogs/"
export const WS_ENDPOINT = "chat_ws/"
export const MESSAGES_PER_PAGE = 35

export const create_ws_endpoint = (accessToken) => {
  const domain = process.env.REACT_APP_WS_URL;
  return domain + WS_ENDPOINT + '?access_token=' + accessToken
}
