import Modal from "../modal";
import {useImages} from "hooks/useImages";
import ImagesCarousel from "../images-carousel";
import {Box, ClickAwayListener} from "@mui/material";
import {useEffect} from "react";


export default function BackgroundImagesModal() {
  const images = useImages();

  useEffect(() => {
    if (!images.showImages) {
      return
    }

    const listener = e => {
      images.closeImages();
    }

    window.addEventListener('popstate', listener)

    return () => {
      window.removeEventListener('popstate', listener)
    }
  }, [images.showImages])

  return <Modal
    open={images.showImages}
    title={false}
    showDialogActions={false}
    noPadding={true}
    onClose={images.closeImages}
    removeBackgroundAndShadow={true}
  >
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
      <ClickAwayListener onClickAway={images.closeImages}>
        <Box sx={{height: 'auto'}}>
          <ImagesCarousel
            images={images.images}
            activeIndex={images.activeIndex}
            getImageFn={images.getImageFn}
            height='100%'
            showIndicators={false}
            onImageClick={() => null}
          />
        </Box>
      </ClickAwayListener>
    </Box>
  </Modal>

}