import {request} from "../../axios";
import {DEFAULT_PAGE_SIZE} from "../../constants";

export default {
  async getFeed(id) {
    return await request.get(`feed/${id}/`);
  },
  async writeComment(feed_id, content) {
    return await request.post(`feed/comments/`, {feed_id, content});
  },
  async getFeedComments(id, cursor = null, page_size = DEFAULT_PAGE_SIZE) {
    return await request.get(`feed/${id}/comments/`, {params: {cursor, page_size}});
  },
  async shareFeed(feed_id) {
    return await request.post(`feed/shares/`, {feed_id});
  },
  async deleteFeed(feed_id) {
    return await request.delete(`feed/${feed_id}/`);
  },
  async deleteComment(comment_id) {
    return await request.delete(`feed/comments/${comment_id}/`);
  },
  async like(feed_id) {
    return await request.post(`feed/${feed_id}/like/`);
  },
  async unlike(feed_id) {
    return await request.post(`feed/${feed_id}/unlike/`);
  }
}