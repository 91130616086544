import {Box, Button, Grid, Typography} from "@mui/material";
import React, {Fragment, useEffect, useRef, useState} from "react";
import userService from "../User.service";
import {
  getFirstErrorString, getUserOriginalOrDefaultImage,
} from "@core/utils";
import Loader from "components/Loader";

import "./index.css"
import eventBus from "@core/event-bus";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import FollowButton from "components/follow-button";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import i18n from "@core/configs/i18n";
import HOME_TABS from "./tabs-data";
import EditScreen from "./edit-screen";
import CreatePost from "components/create-post";
import TabPanel from "components/tab";
import General from "./tabs/general";
import Following from "./tabs/following";
import Followers from "./tabs/followers";
import {styled} from "@mui/material/styles";
import {useImages} from "hooks/useImages";
import Feed from "./tabs/feed";
import {globalScrollableElement} from "shared/refs";

const TabTypography = styled(
  props => {
    return <Typography
      {...props}
      variant={props.variant || 'caption'}
      className={props.className || 'my-font-family'}
      color={props.color || 'secondary'}
      sx={{
        cursor: 'pointer',
        ":hover": {textDecoration: 'underline'},
        textDecoration: props.active ? 'underline' : 'none',
        ...(props.sx || {})
      }}
    />
  }
)(({theme}) => ({}))


export function ProfilePage({user_id, tab, is_hidden}) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [user, setUser] = useState(null)

  const [activeTab, setActiveTab] = useState(null)
  const [following, setFollowing] = useState(false)
  const [editScreenOpen, setEditScreenOpen] = useState(false)

  const auth = useAuth()
  const images = useImages();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFollowing(user.following)
    }
  }, [user])

  useEffect(() => {
    if (user_id) {
      fetchUserInfo()
    }
  }, [user_id])

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    } else {
      setActiveTab(HOME_TABS[0].tab)
    }
  }, [tab])

  function fetchUserInfo() {
    setLoading(true)
    userService.getUserInfo(user_id).then(({ok, result, errors}) => {
      if (!ok) {
        setError(getFirstErrorString(errors))
      } else {
        setUser(result)
      }
      setLoading(false)
    })
  }

  function activateTab(tab) {
    navigate(`/user/${user_id}/${tab}`)
  }

  if (loading) {
    return <>
      <Box sx={{m: 4}}>
        <Loader/>
      </Box>
    </>
  }

  return <Box>
    {error ? (
      <Typography variant='h6' sx={{m: 4, textAlign: 'center'}}>
        {error}
      </Typography>
    ) : (
      <Box>
        <Box
          className='user-profile-image'
          sx={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}>
          <img
            src={getUserOriginalOrDefaultImage(user, 'image_data')}
            onClick={e => {
              images.openImages(
                [getUserOriginalOrDefaultImage(user, 'image_data')],
                0,
              )
            }}
            style={{width: '100%', cursor: 'pointer',}}
          />
        </Box>
        <Box className='profile-image-section' sx={{mx: 1}}>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} sm={6} md={6}>
              <Box className='profile-image-with-buttons' sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box className='profile-image-section-buttons' sx={{my: 1}}>
                  {(auth.user && auth.user.id !== user.id) ? (
                    <>
                      <FollowButton
                        following={following}
                        userId={user.id}
                        onUpdate={value => setFollowing(value)}
                      />
                      <Button size='small'
                              variant='contained'
                              onClick={e => eventBus.dispatch('open-user', user)}
                              sx={{ml: 1, borderRadius: '2rem', textTransform: 'capitalize', px: 2}}>
                        <ChatBubbleOutlineIcon fontSize='small' sx={{mr: 1}}/>
                        <Typography variant='body'> {i18n.t("Message")} </Typography>
                      </Button>
                    </>
                  ) : (
                    (auth.user && auth.user.id === user.id) && (
                      <Fragment>
                        <Button
                          className='edit-profile-button'
                          size='small'
                          variant='contained'
                          color='primary'
                          onClick={e => setEditScreenOpen(true)}>
                          <Typography
                            variant='body'
                            onClick={e => 1}
                            sx={{fontWeight: 'bold'}}>
                            {i18n.t("Edit profile")}
                          </Typography>
                        </Button>
                        <CreatePost sx={{ml: 1}}/>
                      </Fragment>
                    )
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className='profile-image-info' sx={{m: 2, lineHeight: 0}}>
                <Typography variant='h6' sx={{fontWeight: 'bolder', mt: -1, mb: 0, lineHeight: 1.5}}>
                  {user.name}
                </Typography>
                <Typography variant='subtitle2' sx={{my: 0, color: '#4f9ec1'}}>
                  {user.id_number ? `#${user.id_number}` : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box className='profile-image-info' sx={{my: 1, mt: 0, mx: 1.5}}>
            <Box sx={{my: 1, textAlign: 'left'}}>
              <Typography variant='body'>
                {user.about}
              </Typography>
            </Box>
            <Box sx={{my: 1, display: 'flex', justifyContent: "end"}}>
              <Box sx={{mx: 1}} onClick={e => activateTab('general')}>
                <TabTypography active={activeTab === "general"}>{i18n.t("General")}</TabTypography>
              </Box>
              <Box sx={{mx: 1}} onClick={e => activateTab('posts')}>
                <TabTypography active={activeTab === "posts"}>{i18n.t("Posts")}</TabTypography>
              </Box>
              <Box sx={{mx: 1}} onClick={e => activateTab('following')}>
                <TabTypography active={activeTab === "following"}>{i18n.t("Following")}</TabTypography>
              </Box>
              <Box sx={{mx: 1}} onClick={e => activateTab('followers')}>
                <TabTypography active={activeTab === "followers"}>{i18n.t("Followers")}</TabTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='user-tab-contents'>
          <Box>
            <TabPanel value={activeTab} tabValue="general">
              <General user={user} is_hidden={is_hidden}/>
            </TabPanel>
            <TabPanel value={activeTab} tabValue="posts">
              <Feed user={user} is_hidden={is_hidden}/>
            </TabPanel>
            <TabPanel value={activeTab} tabValue="following">
              <Following user={user} is_hidden={is_hidden}/>
            </TabPanel>
            <TabPanel value={activeTab} tabValue="followers">
              <Followers user={user} is_hidden={is_hidden}/>
            </TabPanel>
          </Box>
        </Box>
        {auth.user && String(auth.user.id) === String(user.id) && (
          <EditScreen
            open={editScreenOpen}
            onUpdate={(newData) => setUser({...user, ...newData})}
            onClose={() => setEditScreenOpen(false)}
          />
        )}
      </Box>
    )}
  </Box>
}

export default function CachedProfilePage() {
  const [showProfilePage, setShowProfilePage] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [currentTab, setCurrentTab] = useState(null)
  const {user_id, tab} = useParams();
  const scrollPositionRef = useRef(0);
  const showProfilePageRef = useRef(false);

  useEffect(() => {
    if (user_id !== null && user_id !== undefined) {
      setCurrentUserId(user_id)
      setCurrentTab(tab)
      setShowProfilePage(true)
      showProfilePageRef.current = true;
    } else {
      setShowProfilePage(false)
      showProfilePageRef.current = false;
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id !== null && user_id !== undefined) {
      setCurrentTab(tab)
    }
  }, [tab])

  useEffect(() => {
    let listener = null;
    if (showProfilePageRef.current) {
      if (scrollPositionRef.current) {
        // If we are returning to profile page, scroll to the last position
        setTimeout(() => {
          globalScrollableElement.current.scrollTop = scrollPositionRef.current;
        }, 50)
      }
      listener = e => {
        if (showProfilePageRef.current) {
          scrollPositionRef.current = e.target.scrollTop;
        }
      }
      globalScrollableElement.current.addEventListener('scroll', listener);
    }
    return () => {
      if (listener && globalScrollableElement.current) {
        globalScrollableElement.current.removeEventListener('scroll', listener)
      }
    }
  }, [showProfilePageRef.current])

  return <>
    <Box sx={{display: (showProfilePage ? 'block' : 'none'), m: 0, p: 0}}>
      <ProfilePage user_id={currentUserId} tab={currentTab} is_hidden={!showProfilePage}/>
    </Box>
    {!showProfilePage && (
      <Outlet/>
    )}
  </>
}