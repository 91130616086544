import {Box} from "@mui/material";

export default function TabPanel(props) {
  const { children, value, tabValue, ...other } = props;

  return (
    <Box
      role="tab-panel"
      hidden={value !== tabValue}
      id={`simple-panel-${tabValue}`}
      aria-labelledby={`simple-tab-${tabValue}`}
      {...other}>
      {value === tabValue ? children : null}
    </Box>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tab-panel-${index}`,
  };
}
