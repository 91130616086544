import {Box, styled} from "@mui/material";


const Dot = styled(Box)(({theme, size, color, margin, padding}) => ({
  margin,
  padding,
  width: size,
  height: size,
  background: color,
  borderRadius: '50%'
}))

export default Dot;